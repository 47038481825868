import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';

type RecaptchaMessageType = {
  className?: string;
};

const RecaptchaMessage: React.FC<RecaptchaMessageType> = ({ className }) => {
  const classNames = clsx(styles.recaptchaMessage, className);
  return (
    <small className={classNames}>
      This site is protected by reCAPTCHA and the Google&nbsp;
      <a
        href="https://policies.google.com/privacy"
        onMouseDown={e => e.preventDefault()}
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      &nbsp;and&nbsp;
      <a
        href="https://policies.google.com/terms"
        onMouseDown={e => e.preventDefault()}
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>
      &nbsp;apply.
    </small>
  );
};

export default RecaptchaMessage;
