import React from 'react';
import clsx from 'clsx';

import accordionItemStyles from '../AccordionItem/styles.module.scss';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(
    accordionItemStyles.accordionItem,
    accordionItemStyles.closed,
  );
  return (
    <div className={styles.skeleton}>
      <div className={classNames}>
        <div>
          <div>
            <p></p>
            <span></span>
          </div>
        </div>
      </div>
      <div className={classNames}>
        <div>
          <div>
            <p></p>
            <span></span>
          </div>
        </div>
      </div>
      <div className={classNames}>
        <div>
          <div>
            <p></p>
            <span></span>
          </div>
        </div>
      </div>
      <div className={classNames}>
        <div>
          <div>
            <p></p>
            <span></span>
          </div>
        </div>
      </div>
      <div className={classNames}>
        <div>
          <div>
            <p></p>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
