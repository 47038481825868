import React from 'react';
import styles from './styles.module.scss';
import { ReactComponent as Chevron } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { NavLink } from 'react-router-dom';
import { getLink } from 'modules/utils/utils';

export type TextLinkProps = {
  content_type: any;
  label: string;
  onClick?: () => void;
  slug: string;
};

const TextLink: React.FunctionComponent<TextLinkProps> = ({
  content_type,
  label,
  onClick,
  slug,
}) => {
  return (
    <NavLink
      className={styles.textLink}
      onClick={onClick}
      to={getLink(content_type, slug)}
    >
      {label}
      <Chevron />
    </NavLink>
  );
};

export default TextLink;
