import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { TriggerGTMEvent } from 'modules/gtm';
import { useErrorModal } from 'modules/modals/hooks/useErrorModal';

type usePlanLocationProps = {
  plan: string | null;
  subscriptionState: SubscriptionStateEnum;
};

export const usePlan = () => {
  const [displayModal] = useErrorModal();
  const [plan, setPlan] = useState('');
  const { state: params } = useLocation<usePlanLocationProps>();
  const planParam = params?.plan;
  const subscriptionState = params?.subscriptionState;

  useEffect(() => {
    if (!planParam || !subscriptionState) {
      displayModal('You do not have permission to view this page');
    }

    planParam && setPlan(planParam.replace(/-/g, ' '));
  }, [displayModal, planParam, subscriptionState]);

  useEffect(() => {
    if (subscriptionState === SubscriptionStateEnum.Subscribed) {
      TriggerGTMEvent({
        action: EventsActions.USER_UPGRADES,
        category: EventsCategories.ACCOUNT_MANAGEMENT,
        label: '',
      });
    }
  }, [planParam, subscriptionState]);

  return { plan, subscriptionState } as const;
};
