import React from 'react';

import { ResourceHubItemType } from 'components/FeaturedResources/types';
import KeyTemplatesItem from './KeyTemplatesItem';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type KeyTemplatesProp = {
  keyTemplates?: ResourceHubItemType[];
  loaded: boolean;
  title?: string;
};

const KeyTemplates: React.FunctionComponent<KeyTemplatesProp> = ({
  keyTemplates,
  loaded,
  title,
}) => {
  return loaded ? (
    <React.Fragment>
      {keyTemplates && keyTemplates.length > 0 && (
        <section className={styles.keyTemplates}>
          <h3>{title || 'KEY TEMPLATES'}</h3>
          <ul>
            {keyTemplates.map(
              (keyTemplate: ResourceHubItemType, index: number) => (
                <KeyTemplatesItem
                  key={`key-template-${index}`}
                  keyTemplate={keyTemplate}
                />
              ),
            )}
          </ul>
        </section>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default KeyTemplates;
