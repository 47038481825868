import React, { useRef } from 'react';

import { AccountLoadedType } from './types';
import {
  AccountSettings,
  ACPs,
  EmailSettings,
  Organisation,
  PaymentHistory,
  PersonalInfo,
  Subscriptions,
  Summary,
} from './components';
import { FormNewsletterTypes } from './components/EmailSettings/EmailPreferenceToggle/types';
import { LayoutSize } from 'components/Layout/constants';
import { LoginTypeEnum } from 'modules/utils/types';
import { PaymentHistoryYearItemProps } from './components/PaymentHistory/PaymentHistoryYear/PaymentHistoryYearItem';
import { SubscriptionsHookTypes } from 'pages/account-management/types';
import { useActionPrompt } from './hooks';
import * as Layout from 'components/Layout';
import Accordion, { AccordionItem } from 'components/Accordion';

import styles from './styles.module.scss';
import { PaymentInfoType } from './components/Subscriptions/PaymentInformation/types';

type TemplateProps = {
  form: FormNewsletterTypes;
  handleAddPaymentMethod: () => void;
  handleEditPaymentMethod: (paymentMethod: PaymentInfoType) => void;
  handleRemovePaymentMethod: (id: string) => void;
  loaded: AccountLoadedType;
  loginTypeContext?: LoginTypeEnum;
  paymentHistoryList?: PaymentHistoryYearItemProps[];
  paymentMethods: PaymentInfoType[];
  paymentYears?: number[];
  subscriptions: SubscriptionsHookTypes;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  form,
  handleAddPaymentMethod,
  handleEditPaymentMethod,
  handleRemovePaymentMethod,
  loaded,
  loginTypeContext,
  paymentHistoryList,
  paymentMethods,
  paymentYears,
  subscriptions,
}) => {
  const subscriptionsRef = useRef<HTMLDivElement>(null);
  const { openSubscriptions } = useActionPrompt(subscriptionsRef?.current);
  const hasLoaded =
    loaded.payments && loaded.subscriptions && loaded.newsletter;
  return (
    <React.Fragment>
      <Layout.Container size={LayoutSize.SMALL}>
        <div className={styles.profile}>
          <div className={styles.profileManagement}>
            <h1>Your Profile</h1>
          </div>
          <section className={styles.accountManagement}>
            <Summary />
            <h2>Account Management</h2>
            <Accordion loaded={hasLoaded}>
              <AccordionItem title="Personal Info">
                <PersonalInfo />
              </AccordionItem>
              {loginTypeContext === LoginTypeEnum.EMPLOYEE && (
                <AccordionItem title="Organisation">
                  <Organisation />
                </AccordionItem>
              )}
              <AccordionItem title="Account Settings">
                <AccountSettings />
              </AccordionItem>
              {loginTypeContext === LoginTypeEnum.INDIVIDUAL && (
                <React.Fragment>
                  <div ref={subscriptionsRef}></div>
                  <AccordionItem
                    closed={!openSubscriptions}
                    title="Subscriptions"
                  >
                    <Subscriptions
                      handleAddPaymentMethod={handleAddPaymentMethod}
                      handleEditPaymentMethod={handleEditPaymentMethod}
                      handleRemovePaymentMethod={handleRemovePaymentMethod}
                      paymentMethods={paymentMethods}
                      subscriptions={subscriptions}
                    />
                  </AccordionItem>
                  <AccordionItem title="Payment History">
                    <PaymentHistory
                      paymentHistoryList={paymentHistoryList}
                      paymentYears={paymentYears}
                    />
                  </AccordionItem>
                </React.Fragment>
              )}
              {loginTypeContext === LoginTypeEnum.CONSULTANT && (
                <AccordionItem title="Membership Information">
                  <ACPs />
                </AccordionItem>
              )}
              {loginTypeContext !== LoginTypeEnum.EMPLOYEE && (
                <AccordionItem title="Email Settings">
                  <EmailSettings form={form} />
                </AccordionItem>
              )}
            </Accordion>
          </section>
        </div>
      </Layout.Container>
    </React.Fragment>
  );
};

export default Template;
