import { EnquiryOptionsItem } from './types';

export const enquiryOptions: EnquiryOptionsItem[] = [
  {
    label: 'Account Management',
    value: 'Account Management',
  },
  {
    label: 'CPD',
    value: 'CPD',
  },
  {
    label: 'Digital Badges',
    value: 'Digital Badges',
  },
  {
    label: 'Payment',
    value: 'Payment',
  },
  {
    label: 'GDPR Request',
    value: 'GDPR Request',
  },
  {
    label: 'Cancel/Downgrade Subscription',
    value: 'Cancel/Downgrade Subscription',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
