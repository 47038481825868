import React from 'react';

import { ContentTypesType } from 'components/ContentTypeList/types';
import NoBookmarksFound from '../NoBookmarksFound';
import * as Button from 'components/Button';
import Skeleton from './Skeleton';

import styles from '../../styles.module.scss';
import BookmarksThreeColumn from '../ThreeColumn';
import BookmarksFullWidth from '../FullWidth';

type BookmarkContainerProps = {
  bookmarks: ContentTypesType[];
  bookmarksDisplayed: number;
  numTotalBookmarks: number;
  loaded: boolean;
  showMore: () => void;
  handleBookmarkClick: (contentTypeUid: string) => void;
};

const BookmarkContainer: React.FunctionComponent<BookmarkContainerProps> = ({
  bookmarks,
  bookmarksDisplayed,
  numTotalBookmarks,
  showMore,
  loaded,
  handleBookmarkClick,
}) => {
  return loaded ? (
    <React.Fragment>
      {numTotalBookmarks > 0 ? (
        <React.Fragment>
          <h1>Your Bookmarks</h1>
          <BookmarksThreeColumn
            bookmarks={bookmarks}
            handleBookmarkClick={handleBookmarkClick}
          />
          <BookmarksFullWidth
            bookmarks={bookmarks}
            handleBookmarkClick={handleBookmarkClick}
          />
          {bookmarksDisplayed < numTotalBookmarks && (
            <Button.Generic
              className={styles.showMoreButton}
              label="Show more"
              onClick={showMore}
            />
          )}
        </React.Fragment>
      ) : (
        <NoBookmarksFound />
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default BookmarkContainer;
