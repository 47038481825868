import React from 'react';
import clsx from 'clsx';

import { ReactComponent as CloseSVG } from 'modules/theme/icons/general/cross.svg';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

type FiltersListProps = {
  addDeleteItems: any;
  containerRef: React.RefObject<HTMLDivElement>;
  itemsSelected: any[];
  listItems: any;
  setIsItemSelected: any;
  setListItems: any;
};

const FilterList: React.FunctionComponent<FiltersListProps> = ({
  addDeleteItems,
  containerRef,
  itemsSelected,
  listItems,
  setIsItemSelected,
  setListItems,
}) => {
  const itemIsSelected = (item: any) => {
    return (
      itemsSelected.findIndex((element: any) => element.title === item.title) >
      -1
    );
  };

  listItems?.data?.sort((a: any, b: any) => {
    if (a.title === b.title) return 0;
    if (a.title === 'All') return -1;
    if (b.title === 'All') return 1;

    return a.title > b.title ? 1 : -1;
  });

  return (
    <div className={styles.filtersList} ref={containerRef}>
      <ul>
        {listItems?.data?.map((item: any, index: number) => {
          const classNames = clsx(
            itemIsSelected(item) && styles['filtered-selected'],
          );
          return (
            <li key={`topic-key-${index}`}>
              <Button.Generic
                className={classNames}
                onClick={() => {
                  setIsItemSelected(false);
                  addDeleteItems(item, setListItems);
                }}
              >
                <span>{item.title}</span>
                {itemIsSelected(item) && <CloseSVG />}
              </Button.Generic>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterList;
