import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { LayoutVerticalPadding } from 'components/Layout/constants';
import { SignUpType } from '../types';
import { TriggerGTMEvent } from 'modules/gtm';
import * as Layout from 'components/Layout';
import Footer from '../Footer';
import PageHeading from '../PageHeading';
import PersonalInfoQuestions from './PersonalInfoQuestions';
import PurchaseSummary from '../PurchaseSummary';
import Skeleton from '../Skeleton';
import useSignUp from './hooks';

import styles from 'pages/sign-up/components/styles.module.scss';

const SignUp: React.FunctionComponent<SignUpType> = ({
  candidateToken,
  countryCode,
  loading,
  pageInfo,
  peopleCertDefaultValues,
  productInfo,
  subscriptionPlan,
  voucherCode,
}) => {
  const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });
  const { handleSubmit, isSubmitting, defaultValues } = useSignUp({
    candidateToken,
    peopleCertDefaultValues,
    subscriptionPlan,
  });

  useEffect(() => {
    TriggerGTMEvent({
      action: EventsActions.SIGN_UP,
      category: EventsCategories.PAGE_VIEWS,
      label: 'No Payment',
    });
  }, []);
  return (
    <Layout.Container className={styles.signUpPage}>
      <Layout.Content
        className={styles.signUpContent}
        verticalPadding={LayoutVerticalPadding.LARGE}
      >
        {!loading ? (
          <React.Fragment>
            <PageHeading {...pageInfo} />
            <PersonalInfoQuestions
              countryCode={countryCode}
              defaultValues={defaultValues}
              handleSubmit={handleSubmit}
              id="SignUp"
              isSubmitting={isSubmitting}
              productInfo={productInfo}
              voucherCode={voucherCode}
            />
          </React.Fragment>
        ) : (
          <Skeleton />
        )}
        <Footer />
      </Layout.Content>
      {!isMobileBreakpoint && (
        <Layout.Sidebar
          className={styles.signUpSidebar}
          verticalPadding={LayoutVerticalPadding.LARGE}
        >
          <PurchaseSummary
            showBackLink={false}
            {...productInfo}
            loading={loading}
          />
        </Layout.Sidebar>
      )}
    </Layout.Container>
  );
};

export default React.memo(SignUp);
