import React from 'react';
import clsx from 'clsx';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { defaultValues, Model, model, schema } from './model';
import * as Button from 'components/Button';
import * as Question from 'components/Question';
import Fieldset from 'components/Fieldset';
import Questions from 'components/Questions';
import RecaptchaMessage from 'components/RecaptchaMessage';

import styles from './styles.module.scss';
import modalStyles from 'modules/modals/styles.module.scss';
import formModalStyles from 'components/Modal/Form/styles.module.scss';

type EmailFormProps = {
  changeEmailText: string;
  isSubmitting?: boolean;
  onCancel: () => void;
  onSubmit: (data: Model) => void;
};

const EmailForm: React.FunctionComponent<EmailFormProps> = ({
  changeEmailText,
  isSubmitting,
  onCancel,
  onSubmit,
}) => {
  const isApiSubmitting = false;
  const buttonClassNames = clsx(modalStyles.buttons, formModalStyles.buttons);
  const defaultChangeEmailText =
    'By proceeding, you will be logged out of the current session. An email will be sent to both the old and new email address detailing the changes. To complete the change follow the steps on the email.';

  const submittingListeners = isSubmitting || isApiSubmitting;

  return (
    <div className={styles.emailForm}>
      <Questions
        defaultValues={defaultValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
        schema={schema}
      >
        {({ control, isFormSubmitting }) => (
          <Fieldset title="Enter your new email address">
            <Question.EmailUserExists
              control={control}
              isSubmitting={submittingListeners || isFormSubmitting}
              label="Email"
              question={model.email}
            />
            <Question.Text
              control={control}
              isSubmitting={submittingListeners || isFormSubmitting}
              label="Confirm Email"
              question={model.confirmEmail}
            />
            <p>{changeEmailText || defaultChangeEmailText}</p>
            <RecaptchaMessage />
            <div className={buttonClassNames}>
              {(submittingListeners || isFormSubmitting) && (
                <div className={styles.spinner}></div>
              )}
              <Button.Generic
                colour={ButtonColour.BRAND_MONO_ONE}
                disabled={submittingListeners || isFormSubmitting}
                label="Cancel"
                onClick={onCancel}
                style={ButtonStyle.SECONDARY}
              />
              <Button.Submit
                colour={ButtonColour.BRAND_BLACK_GRAPE}
                disabled={submittingListeners || isFormSubmitting}
                label="Submit"
                showSpinner={false}
                style={ButtonStyle.SECONDARY}
              />
            </div>
          </Fieldset>
        )}
      </Questions>
    </div>
  );
};

export default EmailForm;
