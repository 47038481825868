import { useCallback, useEffect, useRef, useState } from 'react';

import { api, API } from 'modules/api';
import { MaturityModelsGetResponse } from 'modules/api/endpoints/maturity-models';
import logger from 'modules/logger';

export const useMaturityModelsAccess = () => {
  const [gettingMMAccess, setGettingMMAccess] = useState(false);
  const active = useRef(true);
  //TODO done useCallback
  const redirectToMMPlatform = useCallback(async () => {
    try {
      setGettingMMAccess(true);
      const response: MaturityModelsGetResponse = await api(API.GET_MM_URL());
      if (active.current) window.location.href = response.data.entryUrl;
    } catch (error) {
      active.current && logger.debug('Maturity Models Error: ', error);
    } finally {
      active.current && setGettingMMAccess(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return { redirectToMMPlatform, gettingMMAccess } as const;
};
