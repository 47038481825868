import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { ButtonIconProps } from '../types';
import { setAriaLabel, setTabIndex } from '../helpers';

import styles from './styles.module.scss';

const Icon: React.FunctionComponent<ButtonIconProps> = ({
  active,
  ariaLabel,
  children,
  className,
  disabled,
  iconStyle,
  slug,
  target,
  tabIndex,
  title,
}) => {
  const buttonClassName = clsx(
    styles.icon,
    styles[`${iconStyle}`],
    active && styles.active,
    disabled && styles.disabled,
    className,
  );

  const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };

  return (
    <NavLink
      aria-label={setAriaLabel(ariaLabel)}
      className={buttonClassName}
      onMouseDown={handleMouseDown}
      tabIndex={setTabIndex(disabled, tabIndex)}
      target={target}
      to={slug}
      title={title}
    >
      {children}
    </NavLink>
  );
};

export default Icon;
