import { useEffect, useState } from 'react';

import { getBraintree, teardownBraintree } from '../api';
import useBraintreeError from './useBraintreeError';
import { Braintree } from '../types';
import logger from 'modules/logger';

function useBraintreeClient<T>({
  defaultValues,
}: {
  defaultValues: T | undefined;
}): Braintree & { handleBraintreeError: (error?: any) => void } {
  const [braintree, setBraintree] = useState<Braintree | null>(null);
  const handleBraintreeError = useBraintreeError(setBraintree);

  //TODO done useEffect

  useEffect(() => {
    if (braintree === null) {
      let active = true;
      (async function () {
        try {
          logger.debug('Braintree Init');
          const response = await getBraintree({ defaultValues });
          if (active) {
            logger.debug('Braintree Success');
            setBraintree(response);
          }
        } catch (error) {
          if (active) {
            logger.error('Braintree Failure', error);
            handleBraintreeError(error);
          }
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [braintree, defaultValues, handleBraintreeError]);

  useEffect(
    () => () => {
      teardownBraintree();
      setBraintree(null);
    },
    [],
  );

  return {
    handleBraintreeError,
    hostedFields: braintree?.hostedFields || null,
    threeDSecure: braintree?.threeDSecure || null,
  };
}

export default useBraintreeClient;
