import React from 'react';

import {
  BadgeLinksTypes,
  BadgesTypes,
  CertificateTitleTypes,
  CertificateTypes,
} from 'pages/badges/types';
import { BadgesType } from './constants';
import { ProgrammeType } from '../Programme/constants';
import BadgesList from './BadgesList';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';

type CertificatesProps = {
  badgeLinks?: BadgeLinksTypes;
  badges?: BadgesTypes[];
  cancelPolling: () => void;
  certificates: CertificateTypes[];
  loaded: boolean;
  runPolling: (
    badges: BadgesTypes[],
    setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  ) => void;
  setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>;
  type: ProgrammeType;
};

const Certificates: React.FunctionComponent<CertificatesProps> = ({
  badgeLinks,
  badges,
  cancelPolling,
  certificates,
  loaded,
  runPolling,
  setBadges,
  type,
}) => {
  return loaded ? (
    <section className={styles.badges}>
      <h3>Certificates</h3>
      {certificates.map((certificate: CertificateTypes, index: number) => {
        return (
          <React.Fragment key={`certificates-${type}-${index}`}>
            <h4>{certificate.category}</h4>
            <ul>
              {certificate.certificates.map(
                (item: CertificateTitleTypes, certificateIndex: number) => {
                  return (
                    <BadgesList
                      badgeLinks={badgeLinks}
                      badges={badges}
                      badgesType={BadgesType.CERTIFICATE}
                      cancelPolling={cancelPolling}
                      item={item}
                      key={`certificate-${type}-${certificateIndex}`}
                      runPolling={runPolling}
                      setBadges={setBadges}
                      type={type}
                    />
                  );
                },
              )}
            </ul>
          </React.Fragment>
        );
      })}
    </section>
  ) : (
    <Skeleton />
  );
};

export default Certificates;
