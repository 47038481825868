import React from 'react';

import { Model } from './model';
import EmailPreferenceToggle from './EmailPreferenceToggle';

import styles from './styles.module.scss';
import { FormNewsletterTypes } from './EmailPreferenceToggle/types';

type EmailSettingsType = {
  form: FormNewsletterTypes;
};

const EmailSettings: React.FunctionComponent<EmailSettingsType> = ({
  form,
}) => {
  return (
    <form className={styles.emailSettings}>
      <p>We'll send you emails based on your interests</p>
      <EmailPreferenceToggle<Model>
        form={form}
        name="monthlyNewsletters"
        title="Monthly newsletters"
        text="Subscribe to receive exclusive content, news, webinars, events, surveys and offers from Axelos."
      />
    </form>
  );
};

export default EmailSettings;
