import React from 'react';

import { LayoutSize } from 'components/Layout/constants';
import { Page404 } from 'pages';
import { PrivacyTemplateProps } from './types';
import * as Layout from 'components/Layout';
import RichText from 'components/RichText';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

const Template: React.FunctionComponent<PrivacyTemplateProps> = ({
  loaded,
  privacyContent,
}) => {
  return loaded ? (
    <React.Fragment>
      {privacyContent ? (
        <Layout.Container size={LayoutSize.SMALL}>
          <div className={styles.privacyPolicy}>
            <RichText content={privacyContent.content} />
          </div>
        </Layout.Container>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default Template;
