import React from 'react';
import { BodyContentTableSection } from '../../types';
import { htmlSafe } from 'modules/utils/utils';
import styles from './styles.module.scss';

type TableSectionPropType = {
  component: BodyContentTableSection;
};
export const TableSection: React.FunctionComponent<TableSectionPropType> = ({
  component,
}) => {
  return (
    <div className={styles['table-section']}>
      <div>{htmlSafe(component.table)}</div>
    </div>
  );
};
