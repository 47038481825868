import { EnquiryOptionsItem } from './types';

export const enquiryOptionsConsultant: EnquiryOptionsItem[] = [
  {
    label: 'Account Management',
    value: 'Account Management',
  },
  {
    label: 'GDPR Request',
    value: 'GDPR Request',
  },
  {
    label: 'Maturity Models',
    value: 'Maturity Models',
  },
  {
    label: 'Membership Tiers',
    value: 'Membership Tiers',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
