import React, { memo } from 'react';
import clsx from 'clsx';

import { ProgrammeType } from '../../Programme/constants';
import { ReactComponent as BadgeIcon } from 'modules/theme/icons/misc/badge.svg';
import { ReactComponent as TrophyIcon } from 'modules/theme/icons/misc/trophy.svg';
import { useCharCount } from './hooks';

import styles from './styles.module.scss';
import { useMediaQuery } from 'react-responsive';

type QualificationItemsProps = {
  achieved?: boolean;
  category?: string;
  designation?: string;
  isDesignation?: boolean;
  level?: string;
  type: ProgrammeType;
};

const QualificationItems: React.FunctionComponent<QualificationItemsProps> = ({
  achieved,
  category,
  designation,
  isDesignation,
  level,
  type,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 1152px) and (min-width: 768px), (max-width: 576px)',
  });
  const requiresFoundation =
    !achieved && category !== 'MoR' && level === 'Practitioner';
  const { totalChars } = useCharCount(category, level);
  const classNames = clsx(
    styles.qualificationsItem,
    `${styles[type.replace(/\s/g, '-')]}`,
    achieved && styles.achieved,
  );
  const categoryLabel = category === 'MoR' ? 'M_o_R' : category;

  return (
    <li className={classNames}>
      <div>
        <div>{isDesignation ? <TrophyIcon /> : <BadgeIcon />}</div>
      </div>
      <span>
        {isDesignation ? (
          designation
        ) : (
          <React.Fragment>
            {totalChars <= 30 && !isMobile && (
              <React.Fragment>
                <span>{categoryLabel}</span>
                <span>{level}</span>
                {requiresFoundation && (
                  <span className={styles.requiresFoundation}>
                    (Foundation required)
                  </span>
                )}
              </React.Fragment>
            )}
            {(totalChars > 30 || isMobile) && (
              <React.Fragment>
                {`${categoryLabel} ${level} `}
                {requiresFoundation && (
                  <span className={styles.requiresFoundation}>
                    (Foundation required)
                  </span>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </span>
    </li>
  );
};

export default memo(QualificationItems);
