/* eslint-disable */
import { useCallback, useEffect, useRef } from 'react';
import { API, api } from 'modules/api';
import { FormSubmissionPostResponse } from 'modules/api/endpoints/form-submissions';
import { UseFormReset, UnpackNestedValue, DeepPartial } from 'react-hook-form';
import { Model } from '../model';
import { ModalType } from 'modules/modals/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { useWhatChanged } from '@simbathesailor/use-what-changed';
import logger from 'modules/logger';

type useSendFormDataProps = {
  defaultValues: UnpackNestedValue<DeepPartial<Model>>;
  formType: string;
  id: string;
  recaptchaResponse: object | null;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  reset: UseFormReset<Model>;
  values: object;
};

export const useSendFormData = ({
  id,
  defaultValues,
  formType,
  recaptchaResponse,
  reset,
  setIsSubmitting,
  title,
  values,
}: useSendFormDataProps) => {
  const { modalShow, modalHide } = useModalAction();
  const errorModal = useRef<string | null>(null);
  const successModal = useRef<string | null>(null);

  const showSuccessModal = useCallback(() => {
    if (!successModal.current) {
      successModal.current = modalShow({
        onClose: () => {
          !!successModal.current && modalHide(successModal.current);
          successModal.current = null;
        },
        title: `Thank you for your ${title} enquiry`,
        text: 'Your submission has been sent.',
        type: ModalType.INFO,
      });
    }
  }, []);

  const showErrorModal = useCallback(error => {
    if (!errorModal.current) {
      errorModal.current = modalShow({
        onClose: () => {
          !!errorModal.current && modalHide(errorModal.current);
          errorModal.current = null;
        },
        title: `Error: ${error.response?.status}`,
        text: 'There was an error trying to send your message. Please try again later.',
        type: ModalType.INFO,
      });
    }
  }, []);

  useEffect(() => {
    if (recaptchaResponse !== null) {
      let active = true;
      //TODO done useEffect
      (async () => {
        try {
          const params = {
            formType,
            id,
            ...values,
            ...recaptchaResponse,
            consent: true,
          };
          await api(API.POST_FORM_SUBMISSIONS(params));
          active && showSuccessModal();
        } catch (error: any) {
          active && showErrorModal(error);
        } finally {
          if (active) {
            reset(defaultValues);
            setIsSubmitting(false);
          }
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [recaptchaResponse]);
};

export default useSendFormData;
