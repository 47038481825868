import React, { useCallback, useContext } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { RemoveScroll } from 'react-remove-scroll';
import clsx from 'clsx';

import { ReactComponent as CrossSVG } from 'modules/theme/icons/general/cross.svg';
import PlatformContext from 'modules/platform/context';
import { ModalSize } from 'modules/modals/constants';
import Slide from '@material-ui/core/Slide';
import * as Button from 'components/Button';
import ModalOverlay from '../Overlay';

import modalStyles from 'modules/modals/styles.module.scss';
import styles from './styles.module.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type ModalFormTemplateProps = {
  onCancel: () => void;
  preventDefaultClose?: boolean;
  renderContent: (onCancel: () => void) => JSX.Element;
  size?: ModalSize;
  title: string;
};

const ModalForm: React.FC<ModalFormTemplateProps> = ({
  onCancel,
  renderContent,
  preventDefaultClose,
  size = ModalSize.NARROW,
  title,
}) => {
  const classNames = clsx(modalStyles[`${size}`], styles.modalForm);
  return (
    <div className={classNames}>
      <header>
        <h2>{title}</h2>
        {!preventDefaultClose && (
          <Button.Generic
            onClick={onCancel}
            ariaLabel="Close popup"
            title="Close popup"
          >
            <CrossSVG />
          </Button.Generic>
        )}
      </header>
      {renderContent(onCancel)}
    </div>
  );
};

export type ModalFormProps = {
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  id: string;
  onCancel: () => void;
  open: boolean;
  preventDefaultClose?: boolean;
  renderContent: (onCancel: () => void) => JSX.Element;
  title: string;
};

const Form: React.FC<ModalFormProps> = ({
  destroyModal,
  disableRestoreFocus,
  hideModal,
  onCancel,
  open,
  preventDefaultClose,
  renderContent,
  title,
}) => {
  const { isMobile, isMobileOnly } = useContext(PlatformContext);

  const handleCancel = useCallback(() => {
    onCancel ? open && onCancel() : open && hideModal();
  }, [hideModal, onCancel, open]);

  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        disableRestoreFocus={disableRestoreFocus}
        fullScreen={isMobileOnly}
        fullWidth={true}
        onExited={destroyModal}
        onEscapeKeyDown={preventDefaultClose ? undefined : handleCancel}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        <ModalForm
          onCancel={handleCancel}
          preventDefaultClose={preventDefaultClose}
          renderContent={renderContent}
          title={title}
        />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default Form;
