import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { API, api } from 'modules/api';
import { clearCache, getDateFormatted } from 'modules/utils/utils';
import { Header } from '../Header/';
import { MOBILE_SML } from 'modules/utils/constants';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Bookmarked } from 'modules/theme/icons/misc/card-bookmarked.svg';
import { ReactComponent as Like } from 'modules/theme/icons/misc/card-no-like.svg';
import { ReactComponent as Liked } from 'modules/theme/icons/misc/card-like.svg';
import { ReactComponent as NoBookmarked } from 'modules/theme/icons/misc/card-no-bookmarked.svg';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

type TopPanelType = {
  meta: any;
  title: string;
  image: any;
  tags: any;
  likes: number;
  liked: boolean;
  bookmarked: boolean;
  contentTypeUid: string;
};

export const TopPanel: React.FunctionComponent<TopPanelType> = ({
  meta,
  title,
  image,
  tags,
  likes = 0,
  liked,
  contentTypeUid,
  bookmarked,
}) => {
  const {
    time_to_read,
    publication_date,
    author: [
      { title: title_author = '', bio = '', image: authorImage = {} } = {},
    ],
  } = meta;
  const isMobile = useMediaQuery({
    query: `(max-width:${MOBILE_SML})`,
  });
  const className = clsx(
    styles.authorContainer,
    image.use_video && styles.siblingVideo,
  );
  const [pageLiked, setPageLiked] = useState(liked);
  const [pageLikes, setPageLike] = useState(likes);
  const [pageBookmarked, setPageBookmarked] = useState(bookmarked);
  const active = useRef(true);

  //TODO done useCallback
  const postLike = useCallback(
    async (newLikeValue: boolean) => {
      try {
        setPageLiked(newLikeValue);
        if (newLikeValue) {
          setPageLike(pageLikes + 1);
        } else {
          setPageLike(pageLikes - 1);
        }
        await api(
          API.POST_LIKE_CONTENT_TYPE({
            ContentTypeUid: contentTypeUid,
            Liked: newLikeValue,
          }),
        );
        active.current && clearCache();
      } catch (error) {
        if (active.current) {
          setPageLiked(!newLikeValue);
          if (newLikeValue) {
            setPageLike(pageLikes - 1);
          } else {
            setPageLike(pageLikes + 1);
          }
        }
      }
    },
    [contentTypeUid, pageLikes],
  );

  //TODO done useCallback
  const postBookmark = useCallback(
    async (newBookmarkValue: boolean) => {
      try {
        setPageBookmarked(newBookmarkValue);
        await api(
          API.POST_BOOKMARK_CONTENT_TYPE({
            ContentTypeUid: contentTypeUid,
            Liked: newBookmarkValue,
          }),
        );
        active.current && clearCache();
      } catch (error) {
        active.current && setPageBookmarked(!newBookmarkValue);
      }
    },
    [contentTypeUid],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return (
    <header className={styles.topPanel}>
      <div className={styles.publication}>
        <time dateTime={publication_date}>
          {getDateFormatted(publication_date)}
        </time>
        <p>{`${time_to_read} min read`}</p>
      </div>
      <ul aria-label="Article tags" className={styles.tags}>
        {tags?.map((tag: any, index: number) => {
          return <li key={`top-panel-tag-${index}`}>{tag}</li>;
        })}
      </ul>
      <h1>
        <span>{title}</span>
      </h1>
      <div className={styles.social}>
        <div className={styles.likes}>
          <div className={styles.roundel} onClick={() => postLike(!pageLiked)}>
            {pageLiked ? <Liked /> : <Like />}
          </div>
          {pageLikes > 0 && (
            <span>
              {pageLikes > 1 ? `${pageLikes} Likes` : `${pageLikes} Like`}
            </span>
          )}
        </div>
        <div
          className={styles.roundel}
          onClick={() => postBookmark(!pageBookmarked)}
        >
          {pageBookmarked ? (
            <Bookmarked className={styles.bookmarkIcon} />
          ) : (
            <NoBookmarked className={styles.bookmarkIcon} />
          )}
        </div>
      </div>
      {image && <Header image={image} />}
      {title_author && (
        <div className={styles.author}>
          <div className={className}>
            {authorImage?.url && (
              <div
                className={styles.avatar}
                style={{
                  backgroundImage: `url(${authorImage?.url}`,
                }}
              ></div>
            )}
            <div className={styles.authorText}>
              <p>
                Author: <span>{title_author}</span>
              </p>
              {!isMobile && bio && <p>{bio}</p>}
            </div>
          </div>
          {isMobile && bio && <p>{bio}</p>}
        </div>
      )}
    </header>
  );
};

TopPanel.propTypes = {
  meta: PropTypes.object,
};
