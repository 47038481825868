import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import pathStyles from '../styles.module.scss';
import sliderNavigationStyles from '../Slider/Navigation/styles.module.scss';
import sliderSlideStyles from '../Slider/Slide/styles.module.scss';
import qualificationsItemStyles from '../QualificationsItem/styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(styles.skeleton, pathStyles.paths);

  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading path progress
      </span>
      <div className={classNames} aria-hidden="true">
        <div className={clsx(sliderNavigationStyles.sliderNavigation)}>
          <div>
            <span></span>
          </div>
          <p>
            <span></span>
            <span></span>
          </p>
          <div>
            <span></span>
          </div>
        </div>
        <ul>
          <li className={sliderSlideStyles.sliderSlides}>
            <ul>
              <li className={qualificationsItemStyles.qualificationsItem}>
                <div>
                  <div></div>
                </div>
                <span>
                  <span></span>
                  <span></span>
                </span>
              </li>
              <li className={qualificationsItemStyles.qualificationsItem}>
                <div>
                  <div></div>
                </div>
                <span>
                  <span></span>
                  <span></span>
                </span>
              </li>
              <li className={qualificationsItemStyles.qualificationsItem}>
                <div>
                  <div></div>
                </div>
                <span>
                  <span></span>
                  <span></span>
                </span>
              </li>
              <li className={qualificationsItemStyles.qualificationsItem}>
                <div>
                  <div></div>
                </div>
                <span>
                  <span></span>
                  <span></span>
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
