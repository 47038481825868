import { QuestionType } from 'components/Question/Template/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .name()
    .max(255, 'First name must be at most 255 characters.'),
  lastName: yup
    .string()
    .required()
    .name()
    .max(255, 'Last name must be at most 255 characters.'),
  jobTitle: yup.string().max(255, 'Job title must be at most 255 characters.'),
});

export type Model = {
  firstName: string;
  lastName: string;
  jobTitle?: string;
};

const firstName: QuestionType<Model> = {
  name: 'firstName',
};
const lastName: QuestionType<Model> = {
  name: 'lastName',
};
const jobTitle: QuestionType<Model> = {
  name: 'jobTitle',
};

export const model = {
  firstName,
  lastName,
  jobTitle,
};
