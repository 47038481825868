import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { modalActionShow, modalActionHide } from '../actions';
import { Dispatch } from 'modules/redux/store';
import { Modal } from './types';

const useModalAction = () => {
  const dispatch: Dispatch = useDispatch();
  return {
    modalHide: useCallback(
      (id: string) => {
        dispatch(modalActionHide(id));
      },
      [dispatch],
    ),
    modalShow: useCallback(
      (modal: Modal) => {
        return dispatch(modalActionShow(modal));
      },
      [dispatch],
    ),
  };
};

export default useModalAction;
