import React from 'react';
import clsx from 'clsx';

import achievementsItemStyles from '../AchievementsItem/styles.module.scss';
import achievementsStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(styles.skeleton, achievementsStyles.achievements);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading your achievements
      </span>
      <section className={classNames} aria-hidden="true">
        <p></p>
        <ul>
          <li className={achievementsItemStyles.achievementsItem}>
            <span></span>
            <div>
              <div></div>
              <p></p>
            </div>
            <hr />
            <div>
              <span></span>
              <em></em>
              <div></div>
            </div>
          </li>
          <li className={achievementsItemStyles.achievementsItem}>
            <span></span>
            <div>
              <div></div>
              <p></p>
            </div>
            <hr />
            <div>
              <span></span>
              <em></em>
              <div></div>
            </div>
          </li>
          <li className={achievementsItemStyles.achievementsItem}>
            <span></span>
            <div>
              <div></div>
              <p></p>
            </div>
            <hr />
            <div>
              <span></span>
              <em></em>
              <div></div>
            </div>
          </li>
          <li className={achievementsItemStyles.achievementsItem}>
            <span></span>
            <div>
              <div></div>
              <p></p>
            </div>
            <hr />
            <div>
              <span></span>
              <em></em>
              <div></div>
            </div>
          </li>
        </ul>
        <div>
          <span></span>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Skeleton;
