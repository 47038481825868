import React from 'react';
import clsx from 'clsx';

import { ButtonGenericProps } from './types';
import { ButtonStyle } from './constants';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { ReactComponent as LargeChevronSVG } from 'modules/theme/icons/chevrons/large-chevron-down.svg';
import { ReactComponent as SliceSVG } from 'modules/theme/ims/button-slice.svg';
import { setAriaLabel, setTabIndex } from './helpers';
import useHandleClick from './hooks/handleClick';
import useHandleMouseDown from './hooks/handleMouseDown';

import styles from './styles.module.scss';

const Generic: React.FunctionComponent<ButtonGenericProps> = ({
  ariaChecked,
  ariaControls,
  ariaExpanded,
  ariaLabel,
  children,
  className,
  colour,
  disabled,
  forwardedRef,
  id,
  label,
  onClick,
  readOnly,
  role,
  small,
  style,
  tabIndex,
  title,
}) => {
  const buttonClassName = clsx(
    style && styles.button,
    styles[`${style}`],
    styles[`${colour?.replace(/\s/g, '')}`],
    small && styles.small,
    disabled && styles.disabled,
    readOnly && styles.readOnly,
    className,
  );

  const handleClick = useHandleClick({ disabled, readOnly, onClick });
  const handleMouseDown = useHandleMouseDown();

  return (
    <button
      aria-checked={ariaChecked}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-label={setAriaLabel(ariaLabel, label)}
      className={buttonClassName}
      disabled={disabled}
      id={id}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={forwardedRef}
      role={role}
      tabIndex={setTabIndex(disabled, tabIndex)}
      type="button"
      title={title}
    >
      {label && <span>{label}</span>}
      {children}
      {style === ButtonStyle.PRIMARY && (
        <>
          {small ? (
            <ChevronSVG className={styles['chevron']} />
          ) : (
            <LargeChevronSVG className={styles['chevron']} />
          )}
          <SliceSVG className={styles['slice']} />
        </>
      )}
    </button>
  );
};

export default Generic;
