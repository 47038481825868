import React from 'react';
import clsx from 'clsx';

import overviewStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(styles.skeleton, overviewStyles.overview);
  return (
    <div className={classNames} aria-hidden="true">
      <p></p>
      <span></span>
    </div>
  );
};

export default Skeleton;
