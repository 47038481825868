import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';

import { AccountDataContext } from 'modules/context';
import { ContentTypePageType } from '../../../../types';
import { htmlSafe, isFreeTrialUser } from 'modules/utils/utils';
import { RenderBodyWidget } from '../body-content-widgets';
import { SignupReminder } from 'components/SignupReminder';
import { SIGN_UP_REMINDER } from 'modules/utils/constants';
import { TableOfContents } from '../TableOfContents';
import { useSignupReminder } from 'components/SignupReminder/hooks';

import styles from './styles.module.scss';

type ContentPanelType = {
  page: ContentTypePageType;
};
export const ContentPanel: React.FunctionComponent<ContentPanelType> = ({
  page,
}) => {
  const { itemsViewed, increaseItemsViewed } = useSignupReminder();
  const { accountDataContext } = useContext(AccountDataContext);

  const {
    intro_content: { intro_paragraph = '', table_of_contents_title },
    body_content_widgets,
  } = page;
  const className = clsx('container', 'small', styles['intro-paragraph']);

  useEffect(() => {
    if (accountDataContext && isFreeTrialUser(accountDataContext)) {
      increaseItemsViewed(accountDataContext.userId);
    }
  }, [accountDataContext, increaseItemsViewed]);

  return accountDataContext ? (
    <div className={styles['content-panel']}>
      <div className={className}>{htmlSafe(intro_paragraph)}</div>
      <TableOfContents tableOfContentsTitle={table_of_contents_title} />
      <div className="container small">
        <RenderBodyWidget components={body_content_widgets} />
        {isFreeTrialUser(accountDataContext) &&
          itemsViewed >= SIGN_UP_REMINDER && <SignupReminder />}
      </div>
    </div>
  ) : null;
};
