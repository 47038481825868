import React from 'react';

import AccordionItem, { AccordionItemProps } from './AccordionItem';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

export { default as AccordionItem } from './AccordionItem';

export type {
  AccordionContentProps,
  AccordionItemProps,
} from './AccordionItem';

export type AccordionProps = {
  children?: React.ReactNode;
  onClick?: (questionTitle: string) => void;
  items?: AccordionItemProps[];
  loaded?: boolean;
};

const Accordion: React.FunctionComponent<AccordionProps> = ({
  children,
  onClick,
  items,
  loaded = true,
}) => {
  return loaded ? (
    <div className={styles.accordion}>
      {children}
      {items?.map((item: AccordionItemProps, key: any) => (
        <AccordionItem onClick={onClick} item={item.item} key={key} />
      ))}
    </div>
  ) : (
    <Skeleton />
  );
};

export default React.memo(Accordion);
