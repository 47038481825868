import React from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';

import { ButtonColour } from 'components/Button/constants';
import { FeaturedResourcesLayout } from './constants';
import { ResourceHubItemType } from './types';
import { RESOURCE_HUB_URL } from 'modules/utils/constants';
import CtaButton from 'components/Cta/Button';
import FeaturedResourcesItem from './FeaturedResourcesItem';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

export type featuredResourcesProps = {
  data?: ResourceHubItemType[];
  layout?: FeaturedResourcesLayout;
  loaded: boolean;
  showCta?: boolean;
  title?: string;
};

const FeaturedResources: React.FunctionComponent<featuredResourcesProps> = ({
  data,
  layout = FeaturedResourcesLayout.COLUMN,
  loaded,
  showCta = true,
  title,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 896px) and (min-width: 768px), (max-width: 576px)',
  });

  const classNames = clsx(
    styles.featuredResources,
    layout === FeaturedResourcesLayout.ROW && styles.rows,
    layout === FeaturedResourcesLayout.ROW &&
      data &&
      data.length > 3 &&
      styles.grid,
  );

  return loaded ? (
    <React.Fragment>
      {data && data.length > 0 && (
        <div className={classNames}>
          <div>
            <h2>{`${title ? title : 'Featured Resources'}`}</h2>
            {!isMobile && showCta && (
              <div>
                <CtaButton
                  defaultColour={ButtonColour.BRAND_BERRY}
                  label={'Browse all resources'}
                  custom_url={RESOURCE_HUB_URL}
                  small
                />
              </div>
            )}
          </div>
          <ul>
            <li>
              <FeaturedResourcesItem primary={true} resource={data[0]} />
            </li>
            <li>
              {data
                .slice(1)
                .map((resource: ResourceHubItemType, index: number) => (
                  <FeaturedResourcesItem
                    key={`featured-resources-${index}`}
                    resource={resource}
                  />
                ))}
            </li>
          </ul>
          {isMobile && showCta && (
            <div>
              <CtaButton
                defaultColour={ButtonColour.BRAND_BERRY}
                label={'Browse all resources'}
                page_link={RESOURCE_HUB_URL}
                small
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  ) : (
    <Skeleton layout={layout} showCta={showCta} />
  );
};

export default FeaturedResources;
