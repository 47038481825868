import React from 'react';

import { LEGAL_PAGE_URL } from 'modules/utils/constants';
import { NavLink } from 'react-router-dom';
import { NavMenuItem } from 'components/Header/Menu';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type MenuProps = {
  loaded?: boolean;
  navMenu?: NavMenuItem[];
};

const Menu: React.FC<MenuProps> = ({ loaded, navMenu }) => {
  return loaded ? (
    <nav aria-label="Footer" className={styles.menu}>
      <ul>
        {navMenu?.map((item: NavMenuItem, index: number) => (
          <li key={`nav-menu-item-${index}`}>
            <NavLink exact onMouseDown={e => e.preventDefault()} to={item.link}>
              {item.label}
            </NavLink>
          </li>
        ))}
        <li>
          <NavLink to={LEGAL_PAGE_URL}>Legal</NavLink>
        </li>
      </ul>
    </nav>
  ) : (
    <Skeleton />
  );
};

export default Menu;
