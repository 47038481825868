import { useLoginToken } from 'modules/hooks';
import { Page404 } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';

export const ProtectedRoutePage404: React.FunctionComponent = () => {
  const { isLoggedIn } = useLoginToken();

  return <Route>{isLoggedIn() && <Page404 />}</Route>;
};
