import Congratulations from 'pages/congratulations';
import React from 'react';
import { Route } from 'react-router-dom';
import { ProtectedRoutePropsType } from './types';

export const ProtectedRouteCongrats: React.FunctionComponent<ProtectedRoutePropsType> =
  ({ path }) => {
    return (
      <Route path={path}>
        <Congratulations />
      </Route>
    );
  };
