import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });

const appInsightsPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderDomains: [
      'api-myaxe-dev-ukw.azurewebsites.net',
      'api-myaxe-uat-ukw-001.azurewebsites.net',
      'api-myaxe-prod-ukw.azurewebsites.net',
    ],
    extensions: [appInsightsPlugin],
    extensionConfig: {
      [appInsightsPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();

export { appInsights, SeverityLevel };
