import React from 'react';
import { ImageWCaption } from './types';
import clsx from 'clsx';
import styles from './styles.module.scss';

type ImageWithCaptionPropType = {
  component: ImageWCaption;
  title?: string;
};

export const ImageWithCaption: React.FunctionComponent<ImageWithCaptionPropType> =
  ({ component, title }) => {
    const className = clsx(
      styles.imageWithCaption,
      component.fill_row && styles.fillRow,
      component.minimum_width && styles['has-min-width'],
    );
    const minWidth = `${component.minimum_width}px`;
    return (
      <div className={className}>
        {title && <h2 className={styles.imagewcaptionTitle}>{title}</h2>}
        <figure style={{ minWidth: minWidth }}>
          <img
            src={`${component.image.url}?format=jpg&auto=webp?quality=60`}
            alt={component.image_alt_text ? component.image_alt_text : ''}
          />
          {component.image_caption && (
            <figcaption>{component.image_caption}</figcaption>
          )}
        </figure>
      </div>
    );
  };
