import React from 'react';
import clsx from 'clsx';

import { QuestionProps } from '../Template/types';
import Checkbox from 'components/Inputs/Checkbox';
import * as Question from 'components/Question';

import styles from './styles.module.scss';

type QuestionCheckboxProps<T> = QuestionProps<T>;

function Component<T>({
  className,
  control,
  disabled,
  help,
  isSubmitting,
  label,
  question,
}: QuestionCheckboxProps<T>) {
  const classNames = clsx(styles.checkbox, className);
  return (
    <Question.Template
      className={classNames}
      control={control}
      help={help}
      label={label}
      question={question}
    >
      {({ invalid, name, onBlur, onChange, ref, value }) => (
        <Checkbox
          checked={value === true ? true : false}
          forwardedRef={ref}
          invalid={invalid}
          label={label}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          readOnly={isSubmitting}
          tabIndex={disabled ? -1 : 0}
          value={value}
        />
      )}
    </Question.Template>
  );
}

export default React.memo(Component) as typeof Component;
