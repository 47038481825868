import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { AutocompleteItemType } from 'modules/api/endpoints/autocomplete';
import { useEffect, useRef } from 'react';
import { useFetchAutocompleteData } from './useFetchAutocompleteData';

export const useMuiAutocomplete = (
  autocompleteEntries: AutocompleteItemType[] | null,
  setAutocompleteEntries: (
    value: React.SetStateAction<AutocompleteItemType[] | null>,
  ) => void,
) => {
  /* MUI - Autocomplete Hook */
  const {
    getClearProps,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    autoSelect: true,
    id: 'resourceHubSearch',
    freeSolo: true,
    options: autocompleteEntries || [],
    getOptionLabel: (option: any) => {
      return option.text ? option.text : option;
    },
    getOptionSelected: (option: any, valueSelected: any) =>
      option.text ? option.text === valueSelected.text : (null as any),
  });
  const active = useRef(true);
  /* MUI - Autocomplete Search & Ref */
  const { value, ref }: any = getInputProps();

  /* MUI - Input Filters */
  const search = value;
  const searchRef = ref;

  /* Load Autocomplete Data */
  useEffect(() => {
    /* eslint-disable-next-line react-hooks/rules-of-hooks */
    useFetchAutocompleteData({
      params: {
        search: search ? search : '?',
      },
      setAutocompleteEntries,
      active,
    });
  }, [search, setAutocompleteEntries]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    getClearProps,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    search,
    searchRef,
  };
};
