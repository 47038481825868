import React from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  ButtonColour,
  ButtonStyle,
  ButtonTarget,
} from 'components/Button/constants';
import { CandidateIdTypes } from './types';
import { defaultValues, Model, model, schema } from './model';
import { InputSize } from 'components/Inputs/Input/constants';
import { UseFormReset } from 'react-hook-form';
import * as Button from 'components/Button';
import * as Hyperlink from 'components/Hyperlink';
import * as Question from 'components/Question';
import Questions from 'components/Questions';

import styles from './styles.module.scss';

type CandidateIdFormProps = {
  allCandidateIds?: CandidateIdTypes[];
  candidateId: string;
  candidateErrorState: boolean;
  canUseBadgesForm: boolean;
  handleSubmit: (data: Model, reset: UseFormReset<Model>) => void;
  isApiSubmitting: boolean;
};

const CandidateIdForm: React.FunctionComponent<CandidateIdFormProps> = ({
  candidateErrorState,
  handleSubmit,
  isApiSubmitting,
}) => {
  const placeholderBreakpoint = useMediaQuery({
    query: '(max-width: 1700px) and (min-width: 768px), (max-width: 484px)',
  });

  const placeholder = placeholderBreakpoint
    ? 'Candidate Number'
    : 'Enter Your Candidate Number';

  return (
    <div className={styles.candidateIdForm}>
      <Questions
        defaultValues={defaultValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        schema={schema}
      >
        {({ control, isFormSubmitting }) => (
          <React.Fragment>
            <Question.Text
              control={control}
              help="This is the long number that can usually be found on your exam certificate or in the personal details section of your PeopleCert account."
              isSubmitting={isApiSubmitting || isFormSubmitting}
              label={placeholder}
              question={model.candidateId}
              size={InputSize.EXTRA_SMALL}
            />
            <Button.Submit
              ariaLabel="Submit"
              className={styles.button}
              colour={ButtonColour.BRAND_BERRY}
              disabled={isApiSubmitting || isFormSubmitting}
              isSubmitting={isApiSubmitting || isFormSubmitting}
              style={ButtonStyle.PRIMARY}
              small
            />
          </React.Fragment>
        )}
      </Questions>
      {candidateErrorState && (
        <p className={styles.errorResponse}>
          Sorry, we don't recognise this candidate number. Please try again or
          visit our{' '}
          <Hyperlink.PageLink
            rel="noreferrer"
            slug="/help"
            target={ButtonTarget.BLANK}
          >
            Help page
          </Hyperlink.PageLink>
        </p>
      )}
    </div>
  );
};

export default CandidateIdForm;
