import React, { memo } from 'react';

import { BadgesCandidateIdTypes, BadgesTypes } from '../../types';
import { useSortBadges } from '../../hooks/useSortBadges';
import EarnedBadgesItem from './EarnedBadgesItem';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';

type EarnedBadgesProps = {
  badges?: BadgesTypes[];
  badgesEarned: boolean | number;
  candidateId?: BadgesCandidateIdTypes[];
  loaded: boolean;
};

const EarnedBadges: React.FunctionComponent<EarnedBadgesProps> = ({
  badges,
  badgesEarned,
  loaded,
}) => {
  const { sortBadges } = useSortBadges();
  const badgesSorted = sortBadges(badges);

  return loaded ? (
    <React.Fragment>
      {badgesEarned > 0 && (
        <ul className={styles.earnedBadges}>
          {badgesSorted &&
            badgesSorted.map((badge: BadgesTypes, index: number) => {
              return (
                <EarnedBadgesItem
                  badge={badge}
                  key={`earned-badges-${index}`}
                />
              );
            })}
        </ul>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default memo(EarnedBadges);
