import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import FileSaver from 'file-saver';

import { BodyContentDocumentDownload } from '../../types';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { ReactComponent as Download } from 'modules/theme/icons/misc/download.svg';
import { ReactComponent as Pdf } from 'modules/theme/icons/misc/pdf.svg';
import { ReactComponent as DownloadSml } from 'modules/theme/icons/misc/download-sml.svg';
import { TriggerGTMEvent } from 'modules/gtm';
import DownloadBG from 'modules/theme/ims/download-bg.svg';

import styles from './styles.module.scss';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import { EngagementSummaryPageType } from 'modules/engagementSummary';

type DocumentDownloadPropType = {
  component: BodyContentDocumentDownload;
};
export const DocumentDownload: React.FunctionComponent<DocumentDownloadPropType> =
  ({ component }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { loginTypeContext } = useContext(LoginTypeContext);
    const className = clsx(
      styles.downloadIcon,
      isHovered ? styles.hover : null,
    );
    return (
      <div className={styles['documents-download']}>
        <div>
          <h3>{component.title}</h3>
          <span
            aria-hidden="true"
            className={styles['download-icon']}
            style={{
              backgroundImage: `url(${DownloadBG}`,
            }}
          >
            <Download className={className} />
          </span>
        </div>
        <ul>
          {component.file?.map((componentElement: any) => {
            let action = '';

            if (loginTypeContext === LoginTypeEnum.CONSULTANT) {
              action = EventsActions.DOWNLOAD_FILES_CONSULTANTS;
            } else if (loginTypeContext === LoginTypeEnum.INDIVIDUAL) {
              action = EventsActions.DOWNLOAD_FILES_INDIVIDUALS;
            }

            const saveFile = () => {
              TriggerGTMEvent({
                action: action,
                category: EventsCategories.CONTENT,
                label: componentElement?.title,
              });

              // Engagement Summary Dashboard
              EngagementSummaryPageType({
                id: 0,
                pageURL: window.location.href,
                contentTitle: '',
                contentType: '',
                pageviews: 0,
                downloads: 1,
                indivisualUser: 0,
                consultantUser: 0,
              });

              FileSaver.saveAs(componentElement?.url, componentElement?.title);
            };
            return (
              <li
                key={componentElement.uid}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={saveFile}
              >
                <div>
                  <Pdf aria-hidden="true" />
                  <span>{componentElement?.title}</span>
                </div>
                <DownloadSml
                  aria-hidden="true"
                  className={styles['download-sml-icon']}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
