import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { api, API } from 'modules/api';
import { BOOKMARKS_TO_SHOW } from 'modules/utils/constants';
import { BookmarksGetResponse } from 'modules/api/endpoints/bookmarks';
import { ContentTypesType } from 'components/ContentTypeList/types';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';

export const useBookmarks = (
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [isFetching, setIsFetching] = useState(true);
  const [bookmarks, setBookmarks] = useState<ContentTypesType[]>([]);
  const [bookmarksDisplayed, setBookmarksDisplayed] = useState(0);
  const [numTotalBookmarks, setNumTotalBookmarks] = useState(0);
  const active = useRef(true);

  //TODO done useCallback
  const fetchBookmarks = useCallback(
    async (loginTypeContext: LoginTypeEnum) => {
      try {
        const response: BookmarksGetResponse = await api(
          API.GET_ALL_BOOKMARKS({
            loginType: loginTypeContext,
            skip: bookmarksDisplayed,
            limit: BOOKMARKS_TO_SHOW,
          }),
        );
        if (active.current) {
          if (response && response.data.entries) {
            setBookmarks([...bookmarks, ...response.data.entries]);
            setBookmarksDisplayed(
              bookmarksDisplayed + response.data.entries.length,
            );
          }
          setNumTotalBookmarks(response.data.count);
        }
      } catch (error) {
        active.current && logger.error('Error getting all bookmarks', error);
      } finally {
        active.current && setLoaded(true);
      }
    },
    [bookmarks, bookmarksDisplayed, setLoaded],
  );

  const handleBookmarkClick = useCallback(
    (contentTypeUid: string) => {
      if (bookmarks) {
        const index = bookmarks.findIndex(
          (bookmark: ContentTypesType) => bookmark.uid === contentTypeUid,
        );
        let newArray: ContentTypesType[] = [];
        newArray = newArray.concat(bookmarks);
        newArray.splice(index, 1);
        setBookmarks(newArray);
        setNumTotalBookmarks(newArray.length);
      }
    },
    [bookmarks],
  );

  useEffect(() => {
    if (loginTypeContext && isFetching) {
      setIsFetching(false);
      fetchBookmarks(loginTypeContext);
    }
  }, [fetchBookmarks, isFetching, loginTypeContext]);

  //TODO done useCallback
  const showMore = useCallback(async () => {
    if (loginTypeContext) {
      try {
        setLoaded(false);
        await fetchBookmarks(loginTypeContext);
      } catch (error) {
        active.current && logger.debug('Show More Error: ', error);
      } finally {
        active.current && setLoaded(true);
      }
    }
  }, [fetchBookmarks, loginTypeContext, setLoaded]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    bookmarks: {
      bookmarks,
      bookmarksDisplayed,
      numTotalBookmarks,
      handleBookmarkClick,
    },
    showMore,
  } as const;
};
