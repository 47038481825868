import React from 'react';
import clsx from 'clsx';

import { ArrowSpinnerColour } from 'components/Spinner/constants';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import * as Button from 'components/Button';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

export type FormControlProps = {
  handleBack?: () => void;
  isLoading?: boolean;
  isSubmitting: boolean;
  isUserFromPeopleCert?: boolean;
};

const FormControl: React.FC<FormControlProps> = ({
  isLoading,
  isSubmitting,
}) => {
  const classNames = clsx(
    styles.formControl,
    isSubmitting && styles.submitting,
    styles.default,
  );
  return (
    <div className={classNames}>
      <Button.Submit
        className={styles.default}
        colour={ButtonColour.BRAND_BLACK_GRAPE}
        disabled={isLoading || isSubmitting}
        isSubmitting={isSubmitting}
        label="Submit"
        showSpinner={false}
        style={ButtonStyle.SECONDARY}
      >
        {isSubmitting && (
          <Spinner
            background={false}
            className={styles.spinner}
            colour={ArrowSpinnerColour.WHITE}
          />
        )}
      </Button.Submit>
    </div>
  );
};

export default React.memo(FormControl);
