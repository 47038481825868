import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useChangeEmailVerify } from './useChangeEmailVerify';
import * as Loading from 'components/Loading';

export const ChangeEmailVerify = () => {
  const { search } = useLocation();
  const verificationCode = new URLSearchParams(search).get('verificationCode');
  const { postVerificationCode, verifyingAccount } = useChangeEmailVerify();

  useEffect(() => {
    if (verificationCode) {
      postVerificationCode(verificationCode);
    }
  }, [postVerificationCode, verificationCode]);

  return <div>{verifyingAccount && <Loading.Modal />}</div>;
};
