import React, { useRef } from 'react';
import clsx from 'clsx';

import { AccountTypes } from '../hooks/useAccount';
import { LoginTypeEnum } from 'modules/utils/types';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/animate-chevron.svg';
import { ReactComponent as SwitchAccountSVG } from 'modules/theme/ims/switch_account.svg';
import { useAccordion } from 'modules/hooks';
import { useOutsideAlerter } from 'modules/utils/utils';
import * as Button from 'components/Button';
import SwitchAccountItem from './SwitchAccountItem';

import styles from './styles.module.scss';

type SwitchAccountProps = {
  accountTypes: AccountTypes[];
  closed?: boolean;
  handleChangeLoginType: (loginType: LoginTypeEnum) => void;
  loginTypeContext?: LoginTypeEnum;
};

const SwitchAccount: React.FunctionComponent<SwitchAccountProps> = ({
  accountTypes,
  closed = true,
  handleChangeLoginType,
  loginTypeContext,
}) => {
  const buttonRef = useRef<any>(null);
  const { animating, handleClick, open, ref, setOpen } = useAccordion(closed);

  useOutsideAlerter({
    buttonRef: buttonRef,
    containerRef: ref,
    setState: setOpen,
  });

  const classNames = clsx(
    styles.switchAccount,
    animating && styles.animating,
    !open && styles.closed,
  );

  return (
    <React.Fragment>
      <Button.Generic
        ariaExpanded={open}
        className={classNames}
        forwardedRef={buttonRef}
        label="Switch Accounts"
        onClick={handleClick}
      >
        <SwitchAccountSVG />
        <ChevronSVG />
      </Button.Generic>
      <ul ref={ref}>
        {accountTypes.map((i: AccountTypes, index: number) => {
          return (
            <SwitchAccountItem
              handleChangeLoginType={handleChangeLoginType}
              label={i.label}
              loginTypeContext={loginTypeContext}
              key={`account-type-${index}`}
              value={i.value}
            />
          );
        })}
      </ul>
    </React.Fragment>
  );
};

export default SwitchAccount;
