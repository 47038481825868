import React from 'react';

import { InputAutocomplete, InputDateFormat, InputType } from '../constants';
import { InputDateProps } from '../types';
import InputPrimitive from '../Primitive';

const InputDate: React.FunctionComponent<InputDateProps> = ({
  autoFocus,
  disabled,
  format = InputDateFormat.DATE,
  forwardedRef,
  id,
  invalid,
  label,
  max,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  readOnly,
  tabIndex,
  value,
  className,
}) => {
  return (
    <InputPrimitive
      autoComplete={InputAutocomplete.OFF}
      autoFocus={!disabled && autoFocus}
      forwardedRef={forwardedRef}
      disabled={disabled}
      format={format}
      id={id}
      invalid={invalid}
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onInput={onInput}
      onKeyDown={onKeyDown}
      readOnly={readOnly}
      tabIndex={disabled ? -1 : tabIndex ? tabIndex : 0}
      value={value || ''}
      className={className}
      type={InputType.DATE}
    />
  );
};

export default React.memo(InputDate);
