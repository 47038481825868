import { useCallback, useMemo, useState } from 'react';
import { BadgesCertificatesPathsTypes } from '../types';

export const usePathSliderControl = (
  badgesCertificatePaths?: BadgesCertificatesPathsTypes[],
) => {
  const [proPathSlidePosition, setProPathSlidePosition] = useState(0);
  const [itilSlidePosition, setItilSlidePosition] = useState(0);
  const proPathLength = useMemo(
    () =>
      badgesCertificatePaths
        ? badgesCertificatePaths?.filter(p => p.family === 'ProPath').length
        : 0,
    [badgesCertificatePaths],
  );
  const itilLength = useMemo(
    () =>
      badgesCertificatePaths
        ? badgesCertificatePaths?.filter(p => p.family === 'ITIL 4').length
        : 0,
    [badgesCertificatePaths],
  );

  // ProPath Slider
  const handleNextPropPath = useCallback(() => {
    if (proPathSlidePosition + 1 < proPathLength) {
      setProPathSlidePosition(proPathSlidePosition + 1);
    }
  }, [proPathLength, proPathSlidePosition]);

  const handlePrevPropPath = useCallback(() => {
    if (proPathSlidePosition > 0) {
      setProPathSlidePosition(proPathSlidePosition - 1);
    }
  }, [proPathSlidePosition]);

  // Itil Slider
  const handleNextItil = useCallback(() => {
    if (itilSlidePosition + 1 < itilLength) {
      setItilSlidePosition(itilSlidePosition + 1);
    }
  }, [itilLength, itilSlidePosition]);

  const handlePrevItil = useCallback(() => {
    if (itilSlidePosition > 0) {
      setItilSlidePosition(itilSlidePosition - 1);
    }
  }, [itilSlidePosition]);

  return [
    proPathSlidePosition,
    itilSlidePosition,
    handleNextPropPath,
    handlePrevPropPath,
    handleNextItil,
    handlePrevItil,
  ] as const;
};
