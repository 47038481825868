import { useCallback, useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { DashboardLoadedType, DashboardType, useEventsType } from '../types';
import { EventType } from '../components/Events/types';
import { LoginTypeContext } from 'modules/context';
import logger from 'modules/logger';

export const useEvents = (
  setLoaded: React.Dispatch<React.SetStateAction<DashboardLoadedType>>,
  dashboard?: DashboardType,
) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [entries, setEntries] = useState<EventType[] | undefined>();
  const [featured, setFeatured] = useState<EventType>();

  const handleEvents = useCallback(
    (eventsResponse, featuredEventResponse) => {
      if (dashboard?.events.show_events) {
        if (featuredEventResponse.data?.entries.length > 0) {
          setFeatured(featuredEventResponse.data?.entries[0]);
          const featuredEventIndex = eventsResponse.data.entries.findIndex(
            (event: EventType) =>
              event.uid === featuredEventResponse.data?.entries[0].uid,
          );
          if (featuredEventIndex > -1) {
            eventsResponse.data.entries.splice(featuredEventIndex, 1);
          }
          setEntries(eventsResponse.data.entries.slice(0, 3));
        } else {
          setFeatured(eventsResponse.data.entries[0]);
          setEntries(eventsResponse.data.entries.slice(1, 4));
        }
      }
    },
    [dashboard],
  );

  useEffect(() => {
    if (dashboard && loginTypeContext) {
      //TODO done useEffect
      let active = true;
      (async () => {
        const eventsResponse = api(
          API.GET_DASHBOARD_EVENTS({
            loginTypeContext,
            featuredEvent: false,
          }),
        );
        const featuredEventResponse = api(
          API.GET_DASHBOARD_EVENTS({
            loginTypeContext,
            featuredEvent: true,
          }),
        );
        Promise.all([eventsResponse, featuredEventResponse])
          .then(res => {
            active && handleEvents(res[0], res[1]);
          })
          .catch(error => {
            active && logger.error('Get Events Error', error);
          })
          .finally(() => {
            active &&
              setLoaded(prevState => ({
                ...prevState,
                events: true,
              }));
          });
      })();
      return () => {
        active = false;
      };
    }
  }, [dashboard, handleEvents, loginTypeContext, setLoaded]);

  const events: useEventsType = {
    entries,
    featured,
  };

  return { events };
};
