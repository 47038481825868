import React, { memo } from 'react';

import { ReactComponent as SliderChevron } from 'modules/theme/icons/chevrons/chevron-right.svg';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

type SliderNavigationProps = {
  children: React.ReactNode;
  handleNext: () => void;
  handlePrev: () => void;
  slidePosition?: number;
  totalSildes?: number;
};

const SliderNavigation: React.FunctionComponent<SliderNavigationProps> = ({
  children,
  handleNext,
  handlePrev,
  slidePosition = 0,
  totalSildes = 0,
}) => {
  return (
    <div className={styles.sliderNavigation}>
      <Button.Generic
        ariaLabel="Previous Slide"
        disabled={slidePosition === 0}
        onClick={handlePrev}
      >
        <SliderChevron />
      </Button.Generic>
      {children}
      <Button.Generic
        ariaLabel="Next Slide"
        disabled={slidePosition + 1 === totalSildes ? true : false}
        onClick={handleNext}
      >
        <SliderChevron />
      </Button.Generic>
    </div>
  );
};

export default memo(SliderNavigation);
