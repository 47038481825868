import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ContentPanel } from './ContentPanel';
import { ContentTypePageType } from '../../../types';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TopPanel } from './TopPanel/TopPanel';
import { TriggerGTMEvent } from 'modules/gtm';

type BodyProps = {
  page: ContentTypePageType;
};

type TagsArrayElementType = {
  title: string;
};
export const Body: React.FunctionComponent<BodyProps> = ({ page }) => {
  const {
    meta,
    title,
    images,

    categorisation: { topics, best_practices },
  } = page;
  const [scrollDownPercentage, setScrollDownPercentage] = useState(0.4);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [gtmTrigger40Sumbitted, setGtmTrigger40Sumbitted] = useState(false);
  const [gtmTrigger60Sumbitted, setGtmTrigger60Sumbitted] = useState(false);
  const [gtmTrigger80Sumbitted, setGtmTrigger80Sumbitted] = useState(false);

  const getTags = useMemo(() => {
    const retArray: string[] = [];
    best_practices.forEach((best_practice: TagsArrayElementType) => {
      retArray.push(best_practice.title);
    });
    topics.forEach((topic: TagsArrayElementType) => {
      retArray.push(topic.title);
    });

    return retArray;
  }, [best_practices, topics]);

  const handleNavigation = useCallback(() => {
    if (wrapperRef.current) {
      const scrollDown40 = wrapperRef.current.clientHeight * 0.4;
      const scrollDown60 = wrapperRef.current.clientHeight * 0.6;
      const scrollDown80 = wrapperRef.current.clientHeight * 0.8;
      const currentScrollDown =
        wrapperRef.current.offsetTop + Math.trunc(window.scrollY);

      if (
        currentScrollDown >
        wrapperRef.current.clientHeight * scrollDownPercentage
      ) {
        if (currentScrollDown > scrollDown40 && !gtmTrigger40Sumbitted) {
          TriggerGTMEvent({
            action: EventsActions.SCROLL_DOWN_40,
            category: EventsCategories.CONTENT,
            label: page.slug,
          });
          setGtmTrigger40Sumbitted(true);
          setScrollDownPercentage(0.6);
        } else if (currentScrollDown > scrollDown60 && !gtmTrigger60Sumbitted) {
          TriggerGTMEvent({
            action: EventsActions.SCROLL_DOWN_60,
            category: EventsCategories.CONTENT,
            label: page.slug,
          });
          setGtmTrigger60Sumbitted(true);
          setScrollDownPercentage(0.8);
        } else if (currentScrollDown > scrollDown80 && !gtmTrigger80Sumbitted) {
          TriggerGTMEvent({
            action: EventsActions.SCROLL_DOWN_80,
            category: EventsCategories.CONTENT,
            label: page.slug,
          });
          setGtmTrigger80Sumbitted(true);
          setScrollDownPercentage(1);
        }
      }
    }
  }, [
    gtmTrigger40Sumbitted,
    gtmTrigger60Sumbitted,
    gtmTrigger80Sumbitted,
    page.slug,
    scrollDownPercentage,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation);
    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <article ref={wrapperRef} className="body-section">
      <div className="container">
        <TopPanel
          meta={meta}
          title={title}
          image={images}
          tags={getTags}
          likes={page.likes}
          liked={page.liked}
          bookmarked={page.bookmarked}
          contentTypeUid={page.uid}
        />
      </div>
      <ContentPanel page={page} />
    </article>
  );
};
