import React from 'react';
import { BodyContentKeyMessage } from '../../types';
import { htmlSafe } from 'modules/utils/utils';
import { useMediaQuery } from 'react-responsive';
import styles from './styles.module.scss';

import BackgroundDesktop from 'modules/theme/ims/keyMessage.svg';
import BackgroundMobile from 'modules/theme/ims/keyMessageMobile.svg';

type KeyMessageProp = {
  component: BodyContentKeyMessage;
};
export const KeyMessage: React.FunctionComponent<KeyMessageProp> = ({
  component,
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width:580px)`,
  });
  const background = !isMobile ? BackgroundDesktop : BackgroundMobile;
  return (
    <div
      className={styles['key-message']}
      style={{
        backgroundImage: `url(${background}`,
      }}
    >
      <h2 className={styles['tag']}>{component.title}</h2>
      {htmlSafe(component.content)}
    </div>
  );
};
