import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalType } from 'modules/modals/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';

export const useModal = () => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const history = useHistory();

  useEffect(() => {
    history.listen((_, action) => {
      if (action === 'POP') {
        !!modal.current && modalHide(modal.current);
        modal.current = null;
      }
    });
  }, [history, history.action, modalHide]);

  const displayModal = useCallback(
    (title: string, message?: string) => {
      modal.current = modalShow({
        onClose: () => {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        },
        title: title,
        text: message,
        type: ModalType.INFO,
      });
    },
    [modalHide, modalShow],
  );

  return { displayModal } as const;
};
