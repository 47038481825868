export const getAcpProducts = (userRoles?: string[]) => {
  const products: string[] = [
    'Product_AgileShift_Consultant',
    'Product_ITIL_Consultant',
    'Product_MoP_Consultant',
    'Product_MoV_Consultant',
    'Product_MoR_Consultant',
    'Product_MSP_Consultant',
    'Product_PRINCE2_Agile_Consultant',
    'Product_PRINCE2_Consultant',
    'Product_P3M3_Consultant',
    'Product_P3O_Consultant',
    'Product_RESILIA_Consultant',
  ];
  let userProducts = userRoles?.filter((role: string) =>
    products.includes(role),
  );
  userProducts = userProducts?.map(i =>
    i
      .replace(/Product_/, '')
      .replace(/_Consultant/, '')
      .replace(/_/g, ' '),
  );

  return { userProducts } as const;
};

export const getAcpMaturityModels = (userRoles?: string[]) => {
  const maturityModels: string[] = ['Consultant_ITIL_MM', 'Consultant_P3M3_MM'];
  let userMaturityModels = userRoles?.filter((role: string) =>
    maturityModels.includes(role),
  );
  userMaturityModels = userMaturityModels?.map(i => {
    if (i === 'Consultant_P3M3_MM') {
      return i.replace(/Consultant_/, '').replace(/_MM/, '');
    }
    return i.replace(/Consultant_/, '').replace(/_MM/, ' MM');
  });

  return { userMaturityModels } as const;
};

export const getAcpTiers = (userRoles?: string[]) => {
  const tiers: string[] = [
    'Consultancy_Tier_Elite',
    'Consultancy_Tier_Essential',
    'Consultancy_Tier_Premium',
  ];
  let userTiers = userRoles?.filter((role: string) => tiers.includes(role));
  userTiers = userTiers?.map(i => i.replace(/Consultancy_Tier_/, ''));

  return { userTiers } as const;
};
