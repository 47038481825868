import { ChargebeeSubscriptionPlanId } from 'modules/chargebee/constants';
import { Model } from './QuestionSet/model';
import { PaymentInfoType } from 'pages/account-management/components/Subscriptions/PaymentInformation/types';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { UserSubscriptionPlanType } from 'modules/api/endpoints/user-subscription-plan';

export type CountryCodeProps = {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
};

export enum PeopleCertSplitOptions {
  Payment = 0,
  NoPayment = 1,
}

export type PageInfoProps = {
  pageDescription: string | null;
  pageTitle: string | null;
};

export type PeopleCertDefaultValuesProps = {
  candidateNumber: string | null;
  email: string | null;
  givenName: string | null;
  surname: string | null;
};

export type ProductInfoProps = {
  discountAmount: string | null;
  creditAmount: string | null;
  paymentAmount: string | null;
  planAmount: string | null;
  planDescription: string | null;
  planName: string | null;
  salesTaxAmount: string | null;
  salesTaxPercentage: string | null;
};

export type SignUpType = {
  candidateToken: string;
  countryCode: CountryCodeProps;
  loading: boolean;
  pageInfo: PageInfoProps;
  peopleCertDefaultValues: Model;
  productInfo: ProductInfoProps;
  subscriptionPlan: UserSubscriptionPlanType | null;
  voucherCode: VoucherCodeProps;
};

export type SignUpWithPaymentType = {
  candidateToken: string;
  countryCode: CountryCodeProps;
  loading: boolean;
  pageInfo: PageInfoProps;
  peopleCertDefaultValues: Model;
  productInfo: ProductInfoProps;
  subscriptionPlan: UserSubscriptionPlanType | null;
  subscriptionState: SubscriptionStateEnum;
  voucherCode: VoucherCodeProps;
};

export type SubscribeType = {
  countryCode: CountryCodeProps;
  loading: boolean;
  pageInfo: PageInfoProps;
  productInfo: ProductInfoProps;
  requiresCountry: boolean;
  subscriptionPlan: UserSubscriptionPlanType | null;
  subscriptionState: SubscriptionStateEnum;
  voucherCode: VoucherCodeProps;
};

export type SubscribeWithPaymentMethodType = {
  countryCode: CountryCodeProps;
  loading: boolean;
  pageInfo: PageInfoProps;
  paymentMethods: PaymentInfoType[];
  productInfo: ProductInfoProps;
  requiresCountry: boolean;
  subscriptionPlan: UserSubscriptionPlanType | null;
  subscriptionState: SubscriptionStateEnum;
  voucherCode: VoucherCodeProps;
};

export type UserSubscriptionProps = {
  couponCode: string;
  requirePayment: string;
  subscriptionPlanId?: ChargebeeSubscriptionPlanId;
};

export type VoucherCodeProps = {
  couponCodeApplied: boolean;
  couponCodeMessage: string;
  couponCodePreviouslyApplied: boolean;
  couponCodeSubmitting: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => false | void;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
};
