import React from 'react';
import { RenderWidgetProps } from '../types';
import styles from './styles.module.scss';
import {
  ContentsHeading,
  TextWithSubheading,
  ImageWithCaption,
  VideoWithCaption,
  TableSection,
  DocumentDownload,
  RelatedContent,
  KeyMessage,
} from './export_components';

export const RenderBodyWidget: React.FC<RenderWidgetProps> = ({
  components,
}) => {
  let counter = 1;

  return (
    <section className={styles['body-widgets-section']}>
      <div>
        {components?.map((component: any, index: number) => {
          const key = `intro-widget-${index}`;

          if (component['contents_heading']) {
            return (
              <ContentsHeading
                component={component.contents_heading}
                id={counter++}
                key={index}
              />
            );
          }

          if (component['text_with_subheading']) {
            return (
              <TextWithSubheading
                key={key}
                component={component.text_with_subheading}
              />
            );
          }

          if (component['image_with_caption']) {
            return (
              <ImageWithCaption
                key={key}
                component={component.image_with_caption}
              />
            );
          }

          if (component['video_with_caption']) {
            return (
              <VideoWithCaption
                key={key}
                component={component.video_with_caption}
              />
            );
          }

          if (component['table_section']) {
            return (
              <div key={key}>
                <TableSection key={key} component={component.table_section} />
              </div>
            );
          }

          if (component['documents_download']) {
            return (
              <DocumentDownload
                key={key}
                component={component.documents_download}
              />
            );
          }

          if (component['related_content']) {
            return (
              <RelatedContent key={key} component={component.related_content} />
            );
          }

          if (component['key_message']) {
            return <KeyMessage key={key} component={component.key_message} />;
          }

          return null;
        })}
      </div>
    </section>
  );
};
