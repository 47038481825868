const styles = {
  input: {
    appearance: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    '-webkit-font-smoothing': 'antialiased',
    'font-family': 'helvetica, arial, sans-serif',
    'font-size': '16px',
    'font-weight': 'normal',
    'line-height': 'normal',
    'letter-spacing': 'normal',
    color: '#282d3d',
    padding: '0 1rem',
  },
  select: {
    appearance: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    '-webkit-font-smoothing': 'antialiased',
    'font-family': 'helvetica, arial, sans-serif',
    'font-size': '16px',
    'font-weight': 'normal',
    'line-height': 'normal',
    'letter-spacing': 'normal',
    color: '#282d3d',
    padding: '0 1rem',
  },
  '::-webkit-caps-lock-indicator': {
    opacity: '0',
  },
  '::-webkit-credit-card-auto-fill-button': {
    opacity: '0',
  },
  '::-webkit-credentials-auto-fill-button': {
    opacity: '0',
  },
  ':focus': {
    outline: 'none',
  },
};

export default styles;
