import React from 'react';
import clsx from 'clsx';

import { BadgesCertificatesPathsTypes, BadgeQualifications } from '../../types';
import { ProgrammeType } from '../Programme/constants';
import { useCurrentPath } from './hooks';
import * as Slider from './Slider';
import QualificationsItem from './QualificationsItem';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';

type PathProps = {
  displayPaths: boolean;
  paths?: BadgesCertificatesPathsTypes[];
  slidePosition?: number;
  handleNext: () => void;
  handlePrev: () => void;
  loaded: boolean;
  type: ProgrammeType;
};

const Paths: React.FunctionComponent<PathProps> = ({
  displayPaths,
  paths,
  slidePosition,
  handleNext,
  handlePrev,
  loaded,
  type,
}) => {
  const { designation, progress } = useCurrentPath(paths, slidePosition);

  const classNames = clsx(styles.paths, `${styles[type.replace(/\s/g, '-')]}`);

  return loaded ? (
    <React.Fragment>
      {displayPaths && (
        <div className={classNames}>
          <Slider.Navigation
            handleNext={handleNext}
            handlePrev={handlePrev}
            slidePosition={slidePosition}
            totalSildes={paths?.length}
          >
            <p>
              <span>{progress}</span> <span>{designation}</span>
            </p>
          </Slider.Navigation>
          <ul>
            {paths?.map((path: BadgesCertificatesPathsTypes, index: number) => (
              <Slider.Slide
                key={`slide-${path.family}-${index}`}
                index={index}
                slidePosition={slidePosition}
                totalSildes={paths.length}
              >
                {path.qualifications.map(
                  (qualification: BadgeQualifications, i: number) => (
                    <QualificationsItem
                      key={`qualification-${i}`}
                      type={type}
                      {...qualification}
                    />
                  ),
                )}
                <QualificationsItem
                  designation={path.designation}
                  type={type}
                  isDesignation={true}
                />
              </Slider.Slide>
            ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default Paths;
