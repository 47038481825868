import React from 'react';
import clsx from 'clsx';
import { useId } from 'react-id-generator';

import PaymentHistoryYearItem, {
  PaymentHistoryYearItemProps,
} from './PaymentHistoryYearItem';
import { ReactComponent as ArrowSVG } from './arrow.svg';
import { useAccordion } from 'modules/hooks';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

type PaymentHistoryYearProps = {
  closed?: boolean;
  year: string;
  paymentHistoryList: PaymentHistoryYearItemProps[];
};

const PaymentHistoryYear: React.FunctionComponent<PaymentHistoryYearProps> = ({
  closed,
  year,
  paymentHistoryList,
}) => {
  const { animating, handleClick, open, ref } = useAccordion(closed);
  const [id] = useId(1, 'payment-history-year-');
  const className = clsx(
    styles.paymentHistoryYear,
    animating && styles.animating,
    !open && styles.closed,
  );
  return (
    <div className={className}>
      <div>
        <h3>
          <span>Year </span>
          {year}
        </h3>
        <Button.Generic
          ariaControls={id}
          ariaExpanded={open}
          ariaLabel={`${year} Payment History`}
          onClick={handleClick}
        >
          <ArrowSVG />
        </Button.Generic>
      </div>
      <div aria-hidden={!open} id={id} ref={ref}>
        {paymentHistoryList.map(
          (paymentHistoryItem: PaymentHistoryYearItemProps, index: number) => {
            return (
              <PaymentHistoryYearItem
                key={`payment-history-${year}-${index}`}
                paymentDate={paymentHistoryItem.paymentDate}
                paymentDescription={paymentHistoryItem.paymentDescription}
                paymentAmountNet={paymentHistoryItem.paymentAmountNet}
                paymentAmount={paymentHistoryItem.paymentAmount}
                salesTaxPerc={paymentHistoryItem.salesTaxPerc}
                invoiceLink={paymentHistoryItem.invoiceLink}
                salesTaxAmount={paymentHistoryItem.salesTaxAmount}
              />
            );
          },
        )}
      </div>
    </div>
  );
};

export default React.memo(PaymentHistoryYear);
