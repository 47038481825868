import React from 'react';

import {
  ButtonColour,
  ButtonStyle,
  IconStyle,
} from 'components/Button/constants';
import { DEBUG_URL } from 'modules/utils/constants';
import { ReactComponent as PrimaryBookmarkSVG } from 'modules/theme/icons/general/bookmark-primary.svg';
import { ReactComponent as SecondaryBookmarkSVG } from 'modules/theme/icons/general/bookmark-secondary.svg';
import * as Button from 'components/Button';
import Fieldset from 'components/Fieldset';

import styles from './styles.module.scss';

const Buttons: React.FunctionComponent = () => {
  const handleClick = () => alert('button clicked');

  return (
    <Fieldset className={styles.buttons} title="Buttons">
      <p>Without style prop</p>
      <Button.Generic label="Search" onClick={handleClick} />
      <p>Primary Large</p>
      <div>
        <Button.Generic
          colour={ButtonColour.BRAND_AQUA}
          label="Search"
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BERRY}
          label="Browse All Resources"
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          label="Sign in to MyAxelos"
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.WHITE}
          label="Sign in to MyAxelos"
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_MONO_ONE}
          label="Back"
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BERRY}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.WHITE}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_MONO_ONE}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BERRY}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.WHITE}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_MONO_ONE}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
      </div>
      <p>Primary Small</p>
      <div>
        <Button.Generic
          colour={ButtonColour.BRAND_AQUA}
          label="Search"
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BERRY}
          label="Browse All Resources"
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          label="Sign in to MyAxelos"
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.WHITE}
          label="Sign in to MyAxelos"
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_MONO_ONE}
          label="Back"
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BERRY}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.WHITE}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_MONO_ONE}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BERRY}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.WHITE}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_MONO_ONE}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
      </div>
      <p>Secondary Large</p>
      <div>
        <Button.Generic
          colour={ButtonColour.BRAND_AQUA}
          label="Search"
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BERRY}
          label="Browse All Resources"
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          label="Sign in to MyAxelos"
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.WHITE}
          label="Sign in to MyAxelos"
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_MONO_ONE}
          label="Back"
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BERRY}
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.WHITE}
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_MONO_ONE}
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
      </div>
      <p>Secondary Small</p>
      <div>
        <Button.Generic
          colour={ButtonColour.BRAND_AQUA}
          label="Search"
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BERRY}
          label="Browse All Resources"
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          label="Sign in to MyAxelos"
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.WHITE}
          label="Sign in to MyAxelos"
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_MONO_ONE}
          label="Back"
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BERRY}
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.WHITE}
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_MONO_ONE}
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
      </div>
      <p>Primary Icon</p>
      <div>
        <Button.Icon
          ariaLabel="Search"
          iconStyle={IconStyle.PRIMARY}
          slug={DEBUG_URL}
          title="Search"
        >
          <PrimaryBookmarkSVG />
        </Button.Icon>
        <Button.Icon
          active
          ariaLabel="Search"
          iconStyle={IconStyle.PRIMARY}
          slug={DEBUG_URL}
          title="Browse All Resources"
        >
          <PrimaryBookmarkSVG />
        </Button.Icon>
        <Button.Icon
          ariaLabel="Search"
          iconStyle={IconStyle.PRIMARY}
          slug={DEBUG_URL}
        >
          <PrimaryBookmarkSVG />
        </Button.Icon>
        <Button.Icon
          ariaLabel="Search"
          active
          iconStyle={IconStyle.PRIMARY}
          slug={DEBUG_URL}
        >
          <PrimaryBookmarkSVG />
        </Button.Icon>
      </div>
      <p>Secondary Icon</p>
      <div>
        <Button.Icon
          ariaLabel="Search"
          iconStyle={IconStyle.SECONDARY}
          slug={DEBUG_URL}
          title="Search"
        >
          <SecondaryBookmarkSVG />
        </Button.Icon>
        <Button.Icon
          active
          ariaLabel="Search"
          iconStyle={IconStyle.SECONDARY}
          slug={DEBUG_URL}
          title="Browse All Resources"
        >
          <SecondaryBookmarkSVG />
        </Button.Icon>
        <Button.Icon
          ariaLabel="Search"
          iconStyle={IconStyle.SECONDARY}
          slug={DEBUG_URL}
        >
          <SecondaryBookmarkSVG />
        </Button.Icon>
        <Button.Icon
          ariaLabel="Search"
          active
          iconStyle={IconStyle.SECONDARY}
          slug={DEBUG_URL}
        >
          <SecondaryBookmarkSVG />
        </Button.Icon>
      </div>
      <p>Minimum Width CSS From External Source</p>
      <div>
        <Button.Generic
          colour={ButtonColour.BRAND_AQUA}
          label="Search"
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BERRY}
          label="Search"
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        />
      </div>
      <p>Disabled</p>
      <div>
        <Button.Generic
          colour={ButtonColour.BRAND_MONO_ONE}
          disabled={true}
          label="Back"
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          disabled={true}
          onClick={handleClick}
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_MONO_ONE}
          disabled={true}
          label="Back"
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          disabled={true}
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_MONO_ONE}
          disabled={true}
          label="Back"
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          disabled={true}
          onClick={handleClick}
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Generic
          colour={ButtonColour.BRAND_MONO_ONE}
          disabled={true}
          label="Back"
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          ariaLabel="Search"
          colour={ButtonColour.BRAND_AQUA}
          disabled={true}
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        >
          <PrimaryBookmarkSVG />
        </Button.Generic>
        <Button.Icon
          disabled={true}
          iconStyle={IconStyle.PRIMARY}
          slug={DEBUG_URL}
          title="Back"
        >
          <PrimaryBookmarkSVG />
        </Button.Icon>
        <Button.Icon
          ariaLabel="Search"
          disabled={true}
          iconStyle={IconStyle.SECONDARY}
          slug={DEBUG_URL}
        >
          <SecondaryBookmarkSVG />
        </Button.Icon>
        <Button.Generic
          colour={ButtonColour.BRAND_AQUA}
          disabled={true}
          label="Search"
          onClick={handleClick}
          small
          style={ButtonStyle.PRIMARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BERRY}
          disabled={true}
          label="Search"
          onClick={handleClick}
          small
          style={ButtonStyle.SECONDARY}
        />
      </div>
    </Fieldset>
  );
};

export default Buttons;
