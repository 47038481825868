import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AccountDataContext } from 'modules/context';
import { MaturityModelCtaType, MaturityModelsType } from './types';
import ITIL from './ITIL';
import P3M3 from './P3M3';
import Skeleton from 'components/MaturityModels/Skeleton';

import styles from './styles.module.scss';

type MaturityModelsProps = {
  loaded: boolean;
  maturityModelsUrl: MaturityModelCtaType;
  mm_section?: {
    itil?: MaturityModelsType;
    p3m3?: MaturityModelsType;
    itil_product_consultant?: MaturityModelsType;
    p3m3_product_consultant?: MaturityModelsType;
  };
};

const MaturityModels: React.FunctionComponent<MaturityModelsProps> = ({
  loaded,
  maturityModelsUrl,
  mm_section,
}) => {
  const { accountDataContext } = useContext(AccountDataContext);
  const { pathname } = useLocation();

  return loaded ? (
    <React.Fragment>
      {accountDataContext &&
        (mm_section?.p3m3?.display_card || mm_section?.itil?.display_card) && (
          <div className={styles.maturityModels}>
            {mm_section?.p3m3?.display_card && maturityModelsUrl.p3m3.link && (
              <P3M3
                CTALabel={maturityModelsUrl?.p3m3?.label}
                CTALink={maturityModelsUrl?.p3m3?.link}
                description={maturityModelsUrl?.p3m3?.description}
                isDashboard={pathname === '/'}
                title={maturityModelsUrl?.p3m3?.title}
              />
            )}
            {mm_section?.itil?.display_card && maturityModelsUrl.itil.link && (
              <ITIL
                CTALabel={maturityModelsUrl?.itil?.label}
                CTALink={maturityModelsUrl?.itil?.link}
                description={maturityModelsUrl?.itil?.description}
                isDashboard={pathname === '/'}
                title={maturityModelsUrl?.itil?.title}
              />
            )}
          </div>
        )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default MaturityModels;
