import { API, api } from 'modules/api';
import { AutocompleteItemType } from 'modules/api/endpoints/autocomplete';

export type ResultsProps = {
  search: string | null;
};

type useFetchAutocompleteDataProps = {
  params: ResultsProps;
  setAutocompleteEntries: (
    value: React.SetStateAction<AutocompleteItemType[] | null>,
  ) => void;
  active: React.MutableRefObject<boolean>;
};
//TODO done useCallback
export const useFetchAutocompleteData = async ({
  params,
  setAutocompleteEntries,
  active,
}: useFetchAutocompleteDataProps) => {
  try {
    if (params.search !== '?') {
      const autocompleteResponse = await api(
        API.GET_RESOURCE_HUB_AUTOCOMPLETE(params),
      );

      if (active.current) {
        if (autocompleteResponse.data.results.length === 0) {
          setAutocompleteEntries(null);
          return;
        }

        setAutocompleteEntries(autocompleteResponse.data.results);
      }
    } else {
      active.current && setAutocompleteEntries(null);
    }
  } catch (error) {
    active.current && setAutocompleteEntries(null);
  }
};
