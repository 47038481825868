import React from 'react';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading events
      </span>
      <div className={styles.skeleton} aria-hidden="true">
        <span></span>
        <ul>
          <li>
            <div className={`${styles.primary} ${styles.skeletonItem}`}>
              <span></span>
              <div>
                <div>
                  <span className={styles.headingThree}></span>
                  <p className={styles.hideMob}></p>
                </div>
                <div className={styles.eventDateTime}>
                  <span className={styles.headingFour}></span>
                  <span>
                    <span></span>
                    <span></span>
                  </span>
                </div>
                <div className={styles.cta}>
                  <span></span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className={styles.skeletonItem}>
              <span></span>
              <div>
                <div>
                  <span className={styles.headingThree}></span>
                  <p className={styles.hideMob}></p>
                </div>
                <div className={styles.eventDateTime}>
                  <span className={styles.headingFour}></span>
                  <span>
                    <span></span>
                    <span></span>
                  </span>
                </div>
                <div className={styles.cta}>
                  <span className={styles.hyperlink}></span>
                </div>
              </div>
            </div>
            <div className={styles.skeletonItem}>
              <span></span>
              <div>
                <div>
                  <span className={styles.headingThree}></span>
                  <p className={styles.hideMob}></p>
                </div>
                <div className={styles.eventDateTime}>
                  <span className={styles.headingFour}></span>
                  <span>
                    <span></span>
                    <span></span>
                  </span>
                </div>
                <div className={styles.cta}>
                  <span className={styles.hyperlink}></span>
                </div>
              </div>
            </div>
            <div className={styles.skeletonItem}>
              <span></span>
              <div>
                <div>
                  <span className={styles.headingThree}></span>
                  <p className={styles.hideMob}></p>
                </div>
                <div className={styles.eventDateTime}>
                  <span className={styles.headingFour}></span>
                  <span>
                    <span></span>
                    <span></span>
                  </span>
                </div>
                <div className={styles.cta}>
                  <span className={styles.hyperlink}></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
