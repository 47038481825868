import React from 'react';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading social icons
      </span>
      <div className={styles.skeleton} aria-hidden="true">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
