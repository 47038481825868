import yup from 'modules/validation';

export const braintreeSchema = yup.object().shape({
  cardholderName: yup
    .boolean()
    .oneOf([true], 'Please enter the card holder name.'),
  number: yup.boolean().oneOf([true], 'Please enter a valid card number.'),
  cvv: yup.boolean().oneOf([true], 'Please enter a valid cvv.'),
  expirationMonth: yup
    .boolean()
    .oneOf([true], 'Please enter a valid expiration month.'),
  expirationYear: yup
    .boolean()
    .oneOf([true], 'Please enter a valid expiration year.'),

  billingFirstName: yup
    .string()
    .required()
    .name()
    .max(255, 'First name must be at most 255 characters.'),
  billingLastName: yup
    .string()
    .required()
    .name()
    .max(255, 'Last name must be at most 255 characters.'),
  billingStreetAddress: yup
    .string()
    .required()
    .max(255, 'Address line one must be at most 255 characters.'),
  billingExtendedAddress: yup
    .string()
    .max(255, 'Address line two must be at most 255 characters.'),
  billingLocality: yup
    .string()
    .required()
    .max(255, 'City must be at most 255 characters.'),
  billingPostalCode: yup
    .string()
    .required()
    .max(255, 'Zip or Postcode must be at most 255 characters.'),
  billingCountryCodeAlpha2: yup.string().required(),
});
