import { useEffect, useRef } from 'react';

import { useCloseOnEscapeKey } from 'modules/utils/utils';

export const useMobileNavigation = (
  handleCloseMenu: () => void,
  setMenuAnimating: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const navRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const element = navRef.current;
    element?.addEventListener('transitionend', () => {
      setMenuAnimating(false);
    });
    return () =>
      element?.removeEventListener('transitionend', () => {
        setMenuAnimating(false);
      });
  }, [navRef, setMenuAnimating]);

  useCloseOnEscapeKey({ handler: handleCloseMenu });

  return {
    navRef,
  } as const;
};
