import { useCallback, useState } from 'react';
import { BadgeQualifications, BadgesCertificatesPathsTypes } from '../types';

export const usePathSlider = () => {
  const [sliderPaths, setSliderPaths] = useState<
    BadgesCertificatesPathsTypes[]
  >([]);

  const filterPaths = useCallback((paths: BadgesCertificatesPathsTypes[]) => {
    const sliderPathsAux: BadgesCertificatesPathsTypes[] = [];

    paths?.forEach((path: BadgesCertificatesPathsTypes) => {
      const qulificationIndex = path?.qualifications?.findIndex(
        (q: BadgeQualifications) => q?.achieved === false,
      );
      if (qulificationIndex > -1 && !path.isCertificate) {
        sliderPathsAux.push(path);
      }
    });
    setSliderPaths(sliderPathsAux);
  }, []);

  return { filterPaths, sliderPaths } as const;
};
