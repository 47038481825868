import { QuestionType } from 'components/Question/Template/types';
import { BraintreeDefaultValues } from 'modules/braintree/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  makeDefault: yup.boolean(),
});

export type Model = BraintreeDefaultValues & {
  makeDefault: boolean;
};

export const defaultValues: Model = {
  cardholderName: '',
  cvv: '',
  expirationMonth: '',
  expirationYear: '',
  number: '',
  //
  billingFirstName: '',
  billingLastName: '',
  billingStreetAddress: '',
  billingExtendedAddress: '',
  billingLocality: '',
  billingPostalCode: '',
  billingCountryCodeAlpha2: '',
  //
  makeDefault: true,
};

const makeDefault: QuestionType<Model> = {
  name: 'makeDefault',
};

export const model = {
  makeDefault,
};
