import React from 'react';
import clsx from 'clsx';

import { AutocompleteProps } from 'components/Inputs/Autocomplete/types';
import { AutocompleteItemType } from 'modules/api/endpoints/autocomplete';
import InputIcon from 'components/Inputs/Input/Primitive/icons';
import { InputFormat } from '../Input/constants';
import { ReactComponent as CrossSvg } from 'modules/theme/icons/general/cross.svg';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-down.svg';

import styles from './styles.module.scss';

const AutocompleteComp: React.FunctionComponent<AutocompleteProps> = ({
  getClearProps,
  getRootProps,
  getInputProps,
  getListboxProps,
  getOptionProps,
  groupedOptions,
  onKeyDown,
  placeholder,
}) => {
  const { value }: any = getInputProps();
  const { onClick }: any = getClearProps();

  const className = clsx(
    styles.autocomplete,
    groupedOptions.length > 0 && styles.showList,
    value !== '' && styles.hasValue,
  );

  return (
    <div className={className}>
      <div className={clsx(styles.input)} {...getRootProps()}>
        <InputIcon format={InputFormat.SEARCH} />
        <input
          onKeyDown={onKeyDown}
          placeholder={
            value !== null && groupedOptions.length === 0
              ? placeholder
              : undefined
          }
          {...getInputProps()}
        />
        <span></span>
        {value && (
          <>
            <CrossSvg className={styles.clear} onClick={() => onClick()} />
            <ChevronSVG className={styles.chevron} />
          </>
        )}
      </div>
      {groupedOptions.length > 0 ? (
        <ul {...getListboxProps()}>
          {groupedOptions.map((option: AutocompleteItemType, index: number) => (
            <li {...getOptionProps({ option, index })}>{option.text}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export const Autocomplete = React.memo(AutocompleteComp);
