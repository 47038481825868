import React from 'react';
import clsx from 'clsx';

import { useMediaQuery } from 'react-responsive';
import styles from './styles.module.scss';
import summaryStyles from '../styles.module.scss';

const PurchaseSkeleton: React.FunctionComponent = () => {
  const isDesktopBreakpoint = useMediaQuery({ query: '(min-width: 768px)' });
  const classNames = clsx(styles.skeleton, summaryStyles.purchaseSummary);
  const classTotal = clsx(summaryStyles.total, styles.total);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading Purchase Summary
      </span>
      <div className={classNames} aria-hidden="true">
        {isDesktopBreakpoint && <span className={styles.back}></span>}
        <h3>
          <span></span>
        </h3>
        <span className={styles.productName}></span>
        <span className={styles.bundle}></span>
        <span className={styles.tax}></span>
        <span className={classTotal}></span>
      </div>
    </React.Fragment>
  );
};

export default React.memo(PurchaseSkeleton);
