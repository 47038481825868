import React from 'react';

import { ButtonStyle } from 'components/Button/constants';
import { CtaProps } from './types';
import * as Button from 'components/Button';

const CtaButton: React.FC<CtaProps> = ({
  ariaLabel,
  children,
  className,
  colour,
  custom_url,
  defaultColour,
  disabled,
  label,
  onClick,
  page_link,
  target,
  small,
  style = ButtonStyle.PRIMARY,
}) => {
  if (page_link) {
    return (
      <Button.PageLink
        ariaLabel={ariaLabel}
        className={className}
        colour={colour ? colour : defaultColour}
        disabled={disabled}
        label={label}
        onClick={onClick}
        slug={page_link}
        target={target}
        small={small}
        style={style}
      >
        {children}
      </Button.PageLink>
    );
  }
  if (custom_url) {
    return (
      <Button.CustomLink
        ariaLabel={ariaLabel}
        className={className}
        colour={colour ? colour : defaultColour}
        disabled={disabled}
        href={custom_url}
        label={label}
        onClick={onClick}
        target={target}
        small={small}
        style={style}
      >
        {children}
      </Button.CustomLink>
    );
  }
  return null;
};
export default CtaButton;
