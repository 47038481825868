import { QuestionType } from 'components/Question/Template/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  fullName: yup
    .string()
    .required()
    .name()
    .max(255, 'Name must be at most 255 characters.'),
  email: yup
    .string()
    .required()
    .email()
    .max(255, 'Email must be at most 255 characters.'),
  telephoneNumber: yup
    .string()
    .telephone()
    .max(20, 'Telephone number must be at most 20 characters.'),
  jobTitle: yup.string().max(255, 'Job title must be at most 255 characters.'),
  organizationName: yup
    .string()
    .max(255, 'Organization must be at most 255 characters.'),
  reasonForEnquiry: yup.string().required(),
  descriptionOfEnquiry: yup
    .string()
    .required()
    .max(500, 'Description of enquiry must be at most 500 characters.'),
});

export type Model = {
  fullName: string;
  email: string;
  telephoneNumber: string;
  jobTitle: string;
  organizationName: string;
  candidateNumber: string;
  reasonForEnquiry: string;
  descriptionOfEnquiry: string;
};

export const defaultValues: Model = {
  fullName: '',
  email: '',
  telephoneNumber: '',
  jobTitle: '',
  organizationName: '',
  candidateNumber: '',
  reasonForEnquiry: '',
  descriptionOfEnquiry: '',
};

const fullName: QuestionType<Model> = {
  name: 'fullName',
};
const email: QuestionType<Model> = {
  name: 'email',
};
const telephoneNumber: QuestionType<Model> = {
  name: 'telephoneNumber',
};
const jobTitle: QuestionType<Model> = {
  name: 'jobTitle',
};
const organizationName: QuestionType<Model> = {
  name: 'organizationName',
};
const candidateNumber: QuestionType<Model> = {
  name: 'candidateNumber',
};
const reasonForEnquiry: QuestionType<Model> = {
  name: 'reasonForEnquiry',
};
const descriptionOfEnquiry: QuestionType<Model> = {
  name: 'descriptionOfEnquiry',
};

export const model = {
  fullName,
  email,
  telephoneNumber,
  jobTitle,
  organizationName,
  candidateNumber,
  reasonForEnquiry,
  descriptionOfEnquiry,
};
