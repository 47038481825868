import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  Control,
  DeepPartial,
  useForm,
  UnpackNestedValue,
  UseFormReset,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'modules/validation';
import logger from 'modules/logger';

import styles from './styles.module.scss';

const onError = (errors: any) => logger.debug('Form Validation Errors', errors);

type QuestionsProps<T> = {
  children?: (props: {
    control: Control<T>;
    isValid: boolean;
    reset: UseFormReset<T>;
    values: UnpackNestedValue<T>;
    isFormSubmitting: boolean;
  }) => React.ReactNode;
  className?: string;
  defaultValues: UnpackNestedValue<DeepPartial<T>>;
  enableReinitialize?: boolean;
  id?: string;
  onSubmit?: (
    data: UnpackNestedValue<T>,
    reset: UseFormReset<T>,
    event?: React.BaseSyntheticEvent,
  ) => any | Promise<any>;
  schema?: yup.ObjectSchema;
};

function Questions<T>({
  children,
  className,
  defaultValues,
  enableReinitialize = false,
  id,
  onSubmit,
  schema,
}: QuestionsProps<T>) {
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
    watch,
  } = useForm<T>({
    criteriaMode: 'firstError',
    defaultValues: defaultValues,
    mode: 'onTouched',
    resolver: schema ? yupResolver(schema) : undefined,
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  useEffect(() => {
    !!enableReinitialize && reset(defaultValues);
  }, [defaultValues, enableReinitialize, reset]);
  const values = watch();
  const classNames = clsx(styles.questions, className);

  return onSubmit ? (
    <form
      className={classNames}
      id={id}
      onSubmit={handleSubmit(
        (data, event) => onSubmit(data, reset, event),
        onError,
      )}
    >
      {children &&
        children({
          control,
          isValid,
          reset,
          values,
          isFormSubmitting: isSubmitting,
        })}
    </form>
  ) : null;
}

export default React.memo(Questions) as typeof Questions;
