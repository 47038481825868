import React from 'react';
import clsx from 'clsx';

import { TextAreaProps } from './types';
import Label from 'components/Label';

import styles from './styles.module.scss';

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  autoFocus,
  disabled,
  forwardedRef,
  id,
  invalid,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  readOnly,
  rows,
  tabIndex,
  value,
}) => {
  const className = clsx(
    styles.textarea,
    disabled && styles.disabled,
    readOnly && styles.readOnly,
    !disabled && invalid && styles.invalid,
  );
  const getTabIndex = tabIndex ? tabIndex : 0;

  return (
    <div className={className}>
      <textarea
        autoFocus={!disabled && autoFocus}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onInput={onInput}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={forwardedRef}
        rows={rows ? rows : 6}
        tabIndex={disabled ? -1 : getTabIndex}
        value={value || ''}
      />
      {label && (
        <Label
          id={id}
          hasStickyLabel={true}
          isSticky={value !== ''}
          label={label}
        />
      )}
    </div>
  );
};

export default React.memo(TextArea);
