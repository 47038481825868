export enum BadgesType {
  CERTIFICATE = 'certificate',
  DESIGNATION = 'designation',
}

export enum BadgeStatus {
  BADGE_PENDING = 1,
  BADGE_CLAIMABLE = 2,
  BADGE_CLAIMED = 4,
}

export enum BadgeSession {
  DASHBOARD_CLAIMING_TITLE = 'Claiming:dashboard',
  DASHBOARD_CLAIMING_VALUE = 'claiming',
}
