export enum ContentTypesSlugs {
  BLOG = 'blog',
  CASE_STUDY = 'case-study',
  GLOSSARY = 'glossary',
  PRACTICE = 'practice',
  REPORT = 'report',
  SAMPLE_PAPER = 'sample-paper',
  SYLLABUS = 'syllabus',
  TEMPLATE = 'template',
  TRAINING_BUSINESS_CASE = 'training-business-case',
  TUTORIAL = 'tutorial',
  WEBINAR = 'webinar',
  WHITE_PAPER = 'white-paper',
}
