import React from 'react';
import clsx from 'clsx';

import { ArrowSpinnerColour } from './constants';
import { ReactComponent as SpinnerSVG } from 'modules/theme/icons/spinners/arrow.svg';

import styles from './styles.module.scss';

type SpinnerProps = {
  background?: boolean;
  colour?: ArrowSpinnerColour;
  className?: string;
};

const Spinner: React.FunctionComponent<SpinnerProps> = ({
  background = true,
  colour = ArrowSpinnerColour.BRAND_AQUA,
  className,
}) => {
  const classNames = clsx(
    styles.spinner,
    `${styles[colour.replace(/\s/g, '')]}`,
    !background && styles.noBackground,
    className,
  );
  return (
    <div className={classNames}>
      <SpinnerSVG />
    </div>
  );
};

export default Spinner;
