import { useCallback, useEffect, useMemo, useRef } from 'react';
import { DeepPartial, UnpackNestedValue, UseFormReset } from 'react-hook-form';
import yup from 'modules/validation';

import useBraintreeHostedFields from './useBraintreeHostedFields';
import useBraintreeThreeDSecure from './useBraintreeThreeDSecure';
import { mergeDefaultValues, sanitizeData } from '../utils';
import useBraintreeCardType from './useBraintreeCardType';
import useBraintreeClient from './useBraintreeClient';
import { BraintreeHostedFields } from '../types';
import { braintreeSchema } from '../schema';
import logger from 'modules/logger';

function useBraintree<T>({
  defaultValues,
  onSubmit,
  schema,
}: {
  defaultValues: T | undefined;
  onSubmit: (
    data: UnpackNestedValue<T>,
    reset: UseFormReset<T>,
    event?: React.BaseSyntheticEvent,
  ) => any | Promise<any>;
  schema: yup.ObjectSchema | undefined;
}): {
  cardType: string | null;
  handleSubmit: (
    data: UnpackNestedValue<T>,
    reset: UseFormReset<T>,
    event?: React.BaseSyntheticEvent,
  ) => Promise<void>;
  hostedFields: BraintreeHostedFields | null;
  isBraintreeSubmitting: boolean;
  isBraintreeLoading: boolean;
  mergedDefaultValues: UnpackNestedValue<DeepPartial<T>>;
  mergedSchema: yup.ObjectSchema;
} {
  const active = useRef(true);
  const { handleBraintreeError, hostedFields, threeDSecure } =
    useBraintreeClient<T>({
      defaultValues,
    });

  const { handleHostedFieldsSubmit, isHostedFieldsSubmitting } =
    useBraintreeHostedFields<T>(hostedFields);

  const { handleThreeDSecureSubmit, isThreeDSecureSubmitting } =
    useBraintreeThreeDSecure(threeDSecure);

  const cardType = useBraintreeCardType(hostedFields);

  const mergedDefaultValues = useMemo(
    () => mergeDefaultValues<T>(defaultValues),
    [defaultValues],
  );

  const mergedSchema = useMemo(
    () =>
      schema instanceof yup.object
        ? schema.concat(braintreeSchema as yup.ObjectSchema<any>)
        : braintreeSchema,
    [schema],
  );

  const handleSubmit = useCallback(
    async (data, reset, event) => {
      try {
        const hostedFieldsPayload = await handleHostedFieldsSubmit(data);
        active.current &&
          logger.debug('Braintree Hosted Fields Payload', hostedFieldsPayload);
        const threeDSecurePayload = await handleThreeDSecureSubmit(
          hostedFieldsPayload,
        );
        active.current &&
          logger.debug('Braintree 3D Secure Payload', threeDSecurePayload);
        await onSubmit(sanitizeData(data, threeDSecurePayload), reset, event);
      } catch (error) {
        active.current && handleBraintreeError(error);
      }
    },
    [
      handleBraintreeError,
      handleHostedFieldsSubmit,
      handleThreeDSecureSubmit,
      onSubmit,
    ],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    cardType,
    handleSubmit,
    hostedFields,
    isBraintreeSubmitting: isHostedFieldsSubmitting || isThreeDSecureSubmitting,
    isBraintreeLoading: !hostedFields,
    mergedDefaultValues,
    mergedSchema,
  };
}

export default useBraintree;
