import { useEffect, useRef } from 'react';

import { AccountLoadedType } from 'pages/account-management/types';
import useModalAction from 'modules/modals/hooks/useModalAction';
import useRemovePaymentMethod from './removePaymentMethod';
import useEditPaymentMethod from './editPaymentMethod';
import useGetPaymentMethods from './getPaymentMethods';
import useAddPaymentMethod from './addPaymentMethod';

function usePaymentInformation(
  setSkeletonLoaded: React.Dispatch<React.SetStateAction<AccountLoadedType>>,
) {
  //const isSubmitting = useRef<boolean>(false);
  const modal = useRef<string | null>(null);
  const { modalHide } = useModalAction();

  const { paymentMethods, setLoading, setPaymentMethods } =
    useGetPaymentMethods(setSkeletonLoaded);

  const handleRemovePaymentMethod = useRemovePaymentMethod({
    modal,
    setLoading,
    setPaymentMethods,
  });

  const handleEditPaymentMethod = useEditPaymentMethod({
    modal,
    setLoading,
    setPaymentMethods,
  });

  const handleAddPaymentMethod = useAddPaymentMethod({
    modal,
    setLoading,
    setPaymentMethods,
  }); //isSubmitting

  useEffect(() => {
    return () => {
      !!modal.current && modalHide(modal.current);
      modal.current = null;
    };
  }, [modalHide]);

  return {
    handleAddPaymentMethod,
    handleEditPaymentMethod,
    handleRemovePaymentMethod,
    paymentMethods,
  };
}

export default usePaymentInformation;
