import React from 'react';

import { PRIVACY_PAGE_URL, TS_PAGE_URL } from 'modules/utils/constants';
import { SignUpQuestionSetType } from '../types';
import * as Question from 'components/Question';
import RecaptchaMessage from 'components/RecaptchaMessage';

import styles from './styles.module.scss';

const Consent: React.FC<SignUpQuestionSetType> = ({
  control,
  isSubmitting,
  model,
}) => {
  return (
    <div className={styles.consent}>
      <Question.Checkbox
        control={control}
        isSubmitting={isSubmitting}
        label={`I agree to the <a href="${TS_PAGE_URL}" target="_blank">Terms and Conditions of Website Use</a> and <a href="${PRIVACY_PAGE_URL}" target="_blank">Privacy Policy</a>`}
        question={model.consent}
      />
      <RecaptchaMessage />
    </div>
  );
};

export default React.memo(Consent);
