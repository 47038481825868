import { useEffect, useState } from 'react';

import { CuratedPageType } from 'pages/curated-pages/types';
import { CuratedPostsResponse } from 'modules/api/endpoints/curated-posts';
import { LoginTypeEnum } from 'modules/utils/types';
import { ResourceHubLoadedType } from '../types';
import logger from 'modules/logger';
import { API, api } from 'modules/api';

export const useCuratedPosts = (
  setLoaded: React.Dispatch<React.SetStateAction<ResourceHubLoadedType>>,
  loginTypeContext?: LoginTypeEnum,
) => {
  const [curatedPages, setCuratedPages] = useState<
    undefined | CuratedPageType[]
  >();

  useEffect(() => {
    //TODO done useEffect
    if (loginTypeContext) {
      let active = true;
      (async () => {
        try {
          const response: CuratedPostsResponse = await api(
            API.GET_CURATED_PAGE({
              loginTypeContext,
              pageSlug: 'all-pages-slug',
            }),
          );
          active && setCuratedPages(response?.data.entries);
        } catch (error) {
          active && logger.error('Get Badges Error', error);
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              curated: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [loginTypeContext, setLoaded]);

  return { curatedPages, setCuratedPages };
};
