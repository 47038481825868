import React from 'react';

import BookmarkContainer from './components/BookmarkContainer';
import { LayoutSize } from 'components/Layout/constants';
import { useBookmarksType } from './types';
import * as Layout from 'components/Layout';

import styles from './styles.module.scss';

type TemplateProps = {
  bookmarks: useBookmarksType;
  loaded: boolean;
  showMore: () => void;
};
export const Template: React.FunctionComponent<TemplateProps> = ({
  bookmarks,
  loaded,
  showMore,
}) => {
  return (
    <Layout.Container size={LayoutSize.SMALL}>
      <div className={styles.bookmarks}>
        <BookmarkContainer loaded={loaded} showMore={showMore} {...bookmarks} />
      </div>
    </Layout.Container>
  );
};
