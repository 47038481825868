import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import { LOGIN_URL, REDIRECT_URL_COOKIE } from 'modules/utils/constants';

export const useRedirectToSignUp = () => {
  const { pathname, search } = useLocation();
  const [, setCookieRedirectURL] = useCookies<string>([REDIRECT_URL_COOKIE]);
  const setRedirectSignUpCookie = () => {
    const today = new Date();
    today.setMinutes(today.getMinutes() + 20);
    setCookieRedirectURL(REDIRECT_URL_COOKIE, pathname + search, {
      path: '/',
      expires: today,
    });

    window.location.href = LOGIN_URL;
  };

  return { setRedirectSignUpCookie } as const;
};
