import React from 'react';

import { CuratedLoadedType, CuratedPageType } from './types';
import { FeaturedResourcesLayout } from 'components/FeaturedResources/constants';
import { MaturityModelCtaType } from 'components/MaturityModels/types';
import { RichTextFont } from 'components/RichText/constants';
import { SignupReminder } from 'components/SignupReminder';
import * as Layout from 'components/Layout';
import ContentTypesList from 'components/ContentTypeList';
import FeaturedResources from 'components/FeaturedResources';
import KeyTemplates from 'components/KeyTemplates';
import MaturityModels from 'components/MaturityModels';
import Publications from './components/Publications';
import RichText from 'components/RichText';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';

type TemplateProps = {
  curatedPage?: CuratedPageType;
  displaySignupReminder: boolean;
  loaded: CuratedLoadedType;
  mmCta: MaturityModelCtaType;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  curatedPage,
  displaySignupReminder,
  loaded,
  mmCta,
}) => {
  return (
    <Layout.Container className={styles.curatedPage}>
      <Layout.Content>
        {loaded.curatedPage ? (
          <div>
            <h1>{curatedPage?.page_title}</h1>
            {curatedPage && curatedPage.page_description && (
              <p className={styles.description}>
                {curatedPage.page_description}
              </p>
            )}
          </div>
        ) : (
          <Skeleton />
        )}
        <FeaturedResources
          data={curatedPage?.featured_resources.curated_featured_resources}
          layout={FeaturedResourcesLayout.ROW}
          loaded={loaded.curatedPage}
          showCta={false}
          title={curatedPage?.featured_resources.label}
        />
        <ContentTypesList
          data={curatedPage?.content_types.curated_content_types}
          loaded={loaded.curatedPage}
          title={curatedPage?.content_types.label}
        />
        <RichText
          className={styles.richText}
          content={curatedPage?.rich_text}
          font={RichTextFont.SECONDARY}
          loaded={loaded.curatedPage}
        />
        {displaySignupReminder && <SignupReminder />}
      </Layout.Content>
      <Layout.Sidebar>
        <MaturityModels
          loaded={loaded.curatedPage}
          maturityModelsUrl={mmCta}
          mm_section={curatedPage?.mm_section}
        />
        <KeyTemplates
          keyTemplates={curatedPage?.curate_key_templates.key_templates_list}
          loaded={loaded.curatedPage}
          title={curatedPage?.curate_key_templates?.label}
        />
        {curatedPage && (
          <Publications
            loaded={loaded.curatedPage}
            publications={curatedPage?.publications.publications_links}
            title={curatedPage.publications.label}
          />
        )}
      </Layout.Sidebar>
    </Layout.Container>
  );
};

export default Template;
