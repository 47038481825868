import { useCallback, useRef } from 'react';

import useModalAction from 'modules/modals/hooks/useModalAction';
import { ModalType } from 'modules/modals/constants';
import { BraintreeErrorAction } from '../constants';
import { getBraintreeError } from '../utils';
import { teardownBraintree } from '../api';
import { Braintree } from '../types';

function useBraintreeError(
  setBraintree: React.Dispatch<React.SetStateAction<Braintree | null>>,
) {
  const { modalShow, modalHide } = useModalAction();
  const errorModal = useRef<string | null>(null);

  const handleBraintreeError = useCallback(
    (error?: any) => {
      if (!errorModal.current) {
        const result = getBraintreeError(error);
        if (result.action === BraintreeErrorAction.RETRY) {
          teardownBraintree();
          setBraintree(braintree => {
            !!braintree && (braintree.hostedFields = null);
            !!braintree && (braintree.threeDSecure = null);
            return braintree;
          });
        }
        errorModal.current = modalShow({
          onClose: () => {
            if (result.action === BraintreeErrorAction.RELOAD) {
              window.location.reload();
            } else {
              if (result.action === BraintreeErrorAction.RETRY) {
                setBraintree(null);
              }
              !!errorModal.current && modalHide(errorModal.current);
              errorModal.current = null;
            }
          },
          title: 'Payment Error',
          text: result.message,
          type: ModalType.INFO,
        });
      }
    },
    [modalHide, modalShow, setBraintree],
  );

  return handleBraintreeError;
}

export default useBraintreeError;
