import { useCallback, useContext, useEffect, useState } from 'react';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { DashboardLoadedType, DashboardType } from '../types';
import {
  isITIL,
  isP3M3,
  isProductConsultant,
  isProductP3M3Consultant,
} from 'modules/utils/utils';
import { LoginTypeEnum } from 'modules/utils/types';
import { MaturityModelCtaType } from 'components/MaturityModels/types';
import logger from 'modules/logger';

export const useMaturityModelsHomePage = (
  setLoaded: React.Dispatch<React.SetStateAction<DashboardLoadedType>>,
  dashboard?: DashboardType,
) => {
  const { accountDataContext } = useContext(AccountDataContext);
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [maturityModelsUrl, setMaturityModelsUrl] =
    useState<MaturityModelCtaType>({
      itil: {
        label: '',
        link: '',
        title: '',
        description: '',
      },
      p3m3: {
        label: '',
        link: '',
        title: '',
        description: '',
      },
    });

  const p3m3 = dashboard?.mm_section?.p3m3;
  const p3m3_consultant = dashboard?.mm_section?.p3m3_consultant;
  const itil = dashboard?.mm_section?.itil;
  const itil_consultant = dashboard?.mm_section?.itil_consultant;

  const handleUrl = useCallback(
    (url: string) => {
      if (!isP3M3(accountDataContext)) {
        setMaturityModelsUrl(prevState => ({
          ...prevState,
          p3m3: {
            link: url,
            label: p3m3_consultant?.cta_label[0]?.title ?? 'Access the tool',
            title: p3m3_consultant?.title,
            description: p3m3_consultant?.description,
          },
        }));
      } else if (isProductP3M3Consultant(accountDataContext)) {
        setMaturityModelsUrl(prevState => ({
          ...prevState,
          p3m3: {
            label:
              dashboard?.mm_section?.p3m3_product_consultant?.cta_label[0]
                ?.title || 'Read more',
            link: dashboard?.mm_section?.p3m3_product_consultant?.custom_link,
            title: dashboard?.mm_section?.p3m3_product_consultant?.title,
            description:
              dashboard?.mm_section?.p3m3_product_consultant?.description,
          },
        }));
      } else {
        setMaturityModelsUrl(prevState => ({
          ...prevState,
          p3m3: {
            label: p3m3?.cta_label[0]?.title || 'Read more',
            link: p3m3?.custom_link,
            title: p3m3?.title,
            description: p3m3?.description,
          },
        }));
      }

      if (!isITIL(accountDataContext)) {
        setMaturityModelsUrl(prevState => ({
          ...prevState,
          itil: {
            link: url,
            label: itil_consultant?.cta_label[0]?.title ?? 'Access the tool',
            title: itil_consultant?.title,
            description: itil_consultant?.description,
          },
        }));
      } else if (isProductConsultant(accountDataContext)) {
        setMaturityModelsUrl(prevState => ({
          ...prevState,
          itil: {
            label:
              dashboard?.mm_section?.itil_product_consultant?.cta_label[0]
                ?.title || 'Read more',
            link: dashboard?.mm_section?.itil_product_consultant?.custom_link,
            title: dashboard?.mm_section?.itil_product_consultant?.title,
            description:
              dashboard?.mm_section?.itil_product_consultant?.description,
          },
        }));
      } else {
        setMaturityModelsUrl(prevState => ({
          ...prevState,
          itil: {
            label: itil?.cta_label[0]?.title || 'Read more',
            link: itil?.custom_link,
            title: itil?.title,
            description: itil?.description,
          },
        }));
      }
    },
    [
      accountDataContext,
      dashboard,
      itil,
      itil_consultant,
      p3m3,
      p3m3_consultant,
    ],
  );

  useEffect(() => {
    if (
      dashboard &&
      loginTypeContext &&
      loginTypeContext === LoginTypeEnum.CONSULTANT
    ) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          const url = ' ';
          active && handleUrl(url);
        } catch (error) {
          active && logger.error('Get Maturity Models URL Error', error);
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              maturityModels: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [dashboard, handleUrl, loginTypeContext, setLoaded]);

  return { maturityModelsUrl, isP3M3, isITIL } as const;
};
