import React from 'react';

import { EventType } from './types';
import EventsItem from './EventsItem';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

export type EventsProps = {
  featuredEvent?: EventType;
  events?: EventType[];
  isEmployee: boolean;
  loaded: boolean;
  title?: string;
};

const Events: React.FunctionComponent<EventsProps> = ({
  events,
  featuredEvent,
  isEmployee,
  loaded,
  title,
}) => {
  return loaded ? (
    <React.Fragment>
      {!isEmployee && events && featuredEvent && (
        <div className={styles.events}>
          <h2>{`${title ? title : 'Events'}`}</h2>
          <ul>
            <li>
              {(featuredEvent || events.length === 1) && (
                <EventsItem event={featuredEvent || events[0]} primary={true} />
              )}
            </li>
            <li>
              {events.map((event: any, index: number) => (
                <EventsItem event={event} key={`events-${index}`} />
              ))}
            </li>
          </ul>
        </div>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default Events;
