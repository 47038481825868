import React from 'react';

type PageHeadingType = {
  pageTitle: string | null;
  pageDescription: string | null;
};

const PageHeading: React.FunctionComponent<PageHeadingType> = ({
  pageTitle,
  pageDescription,
}) => {
  return (
    <React.Fragment>
      {pageTitle && <h1>{pageTitle}</h1>}
      {pageDescription && <p>{pageDescription}</p>}
    </React.Fragment>
  );
};

export default React.memo(PageHeading);
