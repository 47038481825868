import { QuestionType } from 'components/Question/Template/types';

export type BraintreeModel = {
  cardholderName: boolean;
  number: boolean;
  cvv: boolean;
  expirationMonth: boolean;
  expirationYear: boolean;

  billingFirstName: string;
  billingLastName: string;
  billingStreetAddress: string;
  billingExtendedAddress: string;
  billingLocality: string;
  billingPostalCode: string;
  billingCountryCodeAlpha2: string;
};

export const braintreeDefaultValues: BraintreeModel = {
  cardholderName: false,
  number: false,
  cvv: false,
  expirationMonth: false,
  expirationYear: false,

  billingFirstName: '',
  billingLastName: '',
  billingStreetAddress: '',
  billingExtendedAddress: '',
  billingLocality: '',
  billingPostalCode: '',
  billingCountryCodeAlpha2: '',
};

const cardholderName: QuestionType<BraintreeModel> = {
  name: 'cardholderName',
};

const number: QuestionType<BraintreeModel> = {
  name: 'number',
};

const cvv: QuestionType<BraintreeModel> = {
  name: 'cvv',
};

const expirationMonth: QuestionType<BraintreeModel> = {
  name: 'expirationMonth',
};

const expirationYear: QuestionType<BraintreeModel> = {
  name: 'expirationYear',
};

const billingFirstName: QuestionType<BraintreeModel> = {
  name: 'billingFirstName',
};

const billingLastName: QuestionType<BraintreeModel> = {
  name: 'billingLastName',
};

const billingStreetAddress: QuestionType<BraintreeModel> = {
  name: 'billingStreetAddress',
};

const billingExtendedAddress: QuestionType<BraintreeModel> = {
  name: 'billingExtendedAddress',
};

const billingLocality: QuestionType<BraintreeModel> = {
  name: 'billingLocality',
};

const billingPostalCode: QuestionType<BraintreeModel> = {
  name: 'billingPostalCode',
};

const billingCountryCodeAlpha2: QuestionType<BraintreeModel> = {
  name: 'billingCountryCodeAlpha2',
};

export const braintreeModel = {
  cardholderName,
  number,
  cvv,
  expirationMonth,
  expirationYear,

  billingFirstName,
  billingLastName,
  billingStreetAddress,
  billingExtendedAddress,
  billingLocality,
  billingPostalCode,
  billingCountryCodeAlpha2,
};
