import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { AccountDataContext } from 'modules/context';
import { API, api } from 'modules/api';
import { NotificationItem } from 'modules/utils/types';
import logger from 'modules/logger';

export const useNotifications = (id: number) => {
  const [isDismissed, setIsDismissed] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { accountDataContext, setAccountDataContext } =
    useContext(AccountDataContext);
  const active = useRef(true);

  const handleTransition = useCallback((element: HTMLDivElement) => {
    const height = `${element.scrollHeight}px`;
    const paddingTop = `${window.getComputedStyle(element).paddingTop}px`;
    const paddingBottom = `${window.getComputedStyle(element).paddingBottom}px`;
    const marginBottom = `${window.getComputedStyle(element).marginBottom}px`;
    element.style.height = height;
    element.style.marginBottom = marginBottom;
    element.style.opacity = '1';
    element.style.paddingBottom = paddingBottom;
    element.style.paddingTop = paddingTop;
    requestAnimationFrame(() => {
      element.style.height = '0px';
      element.style.marginBottom = '0px';
      element.style.opacity = '0';
      element.style.paddingBottom = '0px';
      element.style.paddingTop = '0px';
    });
  }, []);

  const handleTransitionEnd = useCallback(
    (event: TransitionEvent) => {
      if (event.target === ref.current && ref.current?.getAttribute('style')) {
        ref.current?.removeAttribute('style');
        setIsDismissed(true);
      }
    },
    [setIsDismissed],
  );

  //TODO done useCallback
  const dismissNotification = useCallback(
    async (notificationId: number) => {
      if (accountDataContext?.notifications) {
        try {
          !!ref.current && handleTransition(ref.current);
          await api(API.PUT_DISMISS_NOTIFICATION(notificationId));
          if (active.current) {
            const notifIndex = accountDataContext?.notifications?.findIndex(
              (notif: NotificationItem) => notif.id === notificationId,
            );
            if (notifIndex > -1) {
              accountDataContext?.notifications.splice(notifIndex, 1);
              setAccountDataContext(accountDataContext);
            }
          }
        } catch (error) {
          active.current && logger.debug('Dismiss Notification Error: ', error);
        }
      }
    },

    [accountDataContext, handleTransition, setAccountDataContext],
  );

  const handleClose = useCallback(() => {
    dismissNotification(id);
  }, [id, dismissNotification]);

  useEffect(() => {
    const element = ref.current;
    element?.addEventListener('transitionend', handleTransitionEnd);
    return () =>
      element?.removeEventListener('transitionend', handleTransitionEnd);
  }, [handleTransitionEnd]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    handleClose,
    isDismissed,
    ref,
  } as const;
};
