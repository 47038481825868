import { useContext, useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { DashboardType } from '../types';
import { DashboardPageGetResponse } from 'modules/api/endpoints/dashboard-page';
import { LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';

export const useDashboard = () => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [dashboard, setDashboard] = useState<DashboardType | undefined>();
  const [isEmployee, setIsEmployee] = useState(false);

  useEffect(() => {
    if (loginTypeContext) {
      //TODO done useEffect
      let active = true;
      (async () => {
        try {
          const response: DashboardPageGetResponse = await api(
            API.GET_DASHBOARD_PAGE(loginTypeContext),
          );
          if (active) {
            setDashboard(response.data.entries[0]);
            if (loginTypeContext === LoginTypeEnum.EMPLOYEE) {
              setIsEmployee(true);
            }
          }
        } catch (error) {
          active && logger.error('Get Dashboard Page Error', error);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [loginTypeContext]);

  return { dashboard, isEmployee };
};
