import React, { useContext, useEffect } from 'react';

import { AccountDataContext } from 'modules/context';
import { USER_ROLE_INDIVIDUAL } from 'modules/utils/constants';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { useContent } from './hooks/useContent';
import { useContentstack } from './hooks/useContentstack';
import { usePlan } from './hooks/usePlan';
import Template from './Template';

const Congratulations: React.FunctionComponent = () => {
  const { content } = useContentstack();
  const { plan, subscriptionState } = usePlan();
  const planText = useContent(content, subscriptionState);
  const { accountDataContext, setAccountDataContext } =
    useContext(AccountDataContext);

  useEffect(() => {
    if (
      subscriptionState !== SubscriptionStateEnum.New_User &&
      subscriptionState !== SubscriptionStateEnum.PeopleCert_User
    ) {
      accountDataContext?.userRoles.push(USER_ROLE_INDIVIDUAL);
      setAccountDataContext(accountDataContext);
    }
  }, [accountDataContext, setAccountDataContext, subscriptionState]);

  return (
    <Template
      content={content}
      plan={plan}
      planText={planText}
      subscriptionState={subscriptionState}
    />
  );
};

export default Congratulations;
