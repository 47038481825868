import React from 'react';

import { AccountDataType } from 'modules/utils/types';
import { ACCOUNT_MANAGEMENT_URL } from 'modules/utils/constants';
import { ReactComponent as LogoutSVG } from 'modules/theme/ims/logout.svg';
import { ReactComponent as PersonSVG } from 'modules/theme/ims/person.svg';
import { useAccount } from '../hooks/useAccount';
import * as Button from 'components/Button';
import SwitchAccount from '../SwitchAccount';

type AccountMenuItemsProps = {
  accountDataContext?: AccountDataType;
  handleCloseMenu: () => void;
  setToggleAccountMenu?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AccountMenuItems: React.FunctionComponent<AccountMenuItemsProps> = ({
  accountDataContext,
  handleCloseMenu,
  setToggleAccountMenu,
}) => {
  const {
    accountTypes,
    handleCloseAccountMenu,
    handleChangeLoginType,
    handleLogOut,
    loginTypeContext,
  } = useAccount({ accountDataContext, handleCloseMenu, setToggleAccountMenu });

  return (
    <React.Fragment>
      <li>
        <Button.PageLink
          label="Account Profile & Settings"
          onClick={() => handleCloseAccountMenu()}
          slug={ACCOUNT_MANAGEMENT_URL}
          style={null}
        >
          <PersonSVG />
        </Button.PageLink>
      </li>
      {accountTypes.length > 1 && (
        <li>
          <SwitchAccount
            accountTypes={accountTypes}
            handleChangeLoginType={handleChangeLoginType}
            loginTypeContext={loginTypeContext}
          />
        </li>
      )}
      <li>
        <Button.Generic label="Log out" onClick={handleLogOut}>
          <LogoutSVG />
        </Button.Generic>
      </li>
    </React.Fragment>
  );
};

export default AccountMenuItems;
