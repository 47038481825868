import { API, api } from 'modules/api';
import { LoginTypeEnum } from 'modules/utils/types';
import { ModalType } from 'modules/modals/constants';
import { useCallback, useEffect, useRef } from 'react';
import useModalAction from 'modules/modals/hooks/useModalAction';

export const useChangeEmail = () => {
  const successModal = useRef<string | null>(null);
  const errorModal = useRef<string | null>(null);
  const { modalShow, modalHide } = useModalAction();
  const active = useRef(true);
  //TODO done useCallback
  const getChangeEmailText = useCallback(async (loginType: LoginTypeEnum) => {
    let result = '';

    const res = await api(API.GET_CHANGE_EMAIL_TEXT(loginType));
    if (active.current && res?.data?.text) {
      result = res.data.text;
    }

    return result;
  }, []);

  const showSuccessModal = useCallback(() => {
    if (!successModal.current) {
      successModal.current = modalShow({
        title: 'Email Change',
        text: 'Please check your new email inbox to verify your account',
        type: ModalType.EMAIL_CHANGE,
      });
    }
  }, [modalShow]);

  const showErrorModal = useCallback(
    error => {
      if (!errorModal.current) {
        errorModal.current = modalShow({
          onClose: () => {
            !!errorModal.current && modalHide(errorModal.current);
            errorModal.current = null;
          },
          title: `Error: ${error.response?.statusText}`,
          text: 'There was an error trying to change your email. Please try again later',
          type: ModalType.INFO,
        });
      }
    },
    [modalHide, modalShow],
  );

  //TODO done useCallback
  const postChangeEmail = useCallback(
    async (newEmailAddress: string) => {
      try {
        await api(
          API.POST_REQUEST_CHANGE_EMAIL(`newEmailAddress=${newEmailAddress}`),
        );
        active.current && showSuccessModal();
      } catch (error: any) {
        active.current && showErrorModal(error);
      }
    },
    [showErrorModal, showSuccessModal],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return [getChangeEmailText, postChangeEmail] as const;
};
