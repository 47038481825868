import React from 'react';
import clsx from 'clsx';

import { BadgesTypes } from '../../../types';
import { ReactComponent as BadgeIcon } from 'modules/theme/icons/misc/badge.svg';
import { ReactComponent as MyaxelosShort } from 'modules/theme/ims/logos/myaxelos-short.svg';

import styles from './styles.module.scss';

type EarnedBadgesItemProps = {
  badge: BadgesTypes;
};

const EarnedBadgesItem: React.FunctionComponent<EarnedBadgesItemProps> = ({
  badge,
}) => {
  const classNames = clsx(
    badge.family === 'ITIL 4' && styles.itil,
    badge.family === 'ProPath' && styles.propath,
    styles.earnedBadgesItem,
  );
  const title =
    badge.systemName === 'MoR Practitioner'
      ? 'M_o_R Practitioner'
      : badge.systemName;

  return (
    <li className={classNames}>
      <MyaxelosShort />
      <span>{title}</span>
      <BadgeIcon />
    </li>
  );
};

export default EarnedBadgesItem;
