import { useCallback, useEffect, useRef, useState } from 'react';

import { api, API } from 'modules/api';
import { ModalType } from 'modules/modals/constants';
import { useLoginToken } from 'modules/hooks';
import useModalAction from 'modules/modals/hooks/useModalAction';

export const useChangeEmailVerify = () => {
  const successModal = useRef<string | null>(null);
  const errorModal = useRef<string | null>(null);
  const { modalShow, modalHide } = useModalAction();
  const { logOutAndRedirection } = useLoginToken();
  const [verifyingAccount, setVerifyingAccount] = useState<boolean>();
  const active = useRef(true);

  const showSuccessModal = useCallback(() => {
    if (!successModal.current) {
      successModal.current = modalShow({
        onClose: () => {
          !!successModal.current && modalHide(successModal.current);
          successModal.current = null;
          logOutAndRedirection();
        },
        title: 'Email Changed',
        text: 'Your email has been changed succesfully! Please, login using your new email address',
        type: ModalType.INFO,
      });
    }
  }, [logOutAndRedirection, modalHide, modalShow]);

  const showErrorModal = useCallback(
    error => {
      if (!errorModal.current) {
        errorModal.current = modalShow({
          onClose: () => {
            !!errorModal.current && modalHide(errorModal.current);
            errorModal.current = null;
            setVerifyingAccount(false);
          },
          title: `Error: ${error.response?.statusText}`,
          text: 'There was an error trying to change your email. Please try again later',
          type: ModalType.INFO,
        });
      }
    },
    [modalHide, modalShow],
  );

  //TODO done useCallback
  const postVerificationCode = useCallback(
    async (verificationCode: string) => {
      try {
        setVerifyingAccount(true);
        await api(
          //we replace manually %20 (empty space) for %2B (plus characters)
          //some links generators are decoding the link in the email causing this error
          API.POST_VERIFY_CHANGE_EMAIL(
            `verificationCode=${encodeURIComponent(verificationCode).replace(
              /%20/g,
              '%2B',
            )}`,
          ),
        );
        if (active.current) {
          setVerifyingAccount(false);
          showSuccessModal();
        }
      } catch (error: any) {
        active.current && showErrorModal(error);
      }
    },
    [showErrorModal, showSuccessModal],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return { postVerificationCode, verifyingAccount } as const;
};
