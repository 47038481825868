import React from 'react';

import { FooterContentType } from '..';
import { ReactComponent as FacebookLogo } from 'modules/theme/ims/social/facebook.svg';
import { ReactComponent as LinkedinLogo } from 'modules/theme/ims/social/linkedin.svg';
import { ReactComponent as TwitterLogo } from 'modules/theme/ims/social/twitter.svg';
import { ReactComponent as YoutubeLogo } from 'modules/theme/ims/social/youtube.svg';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type SocialIconsProps = {
  loaded?: boolean;
  footerContent?: FooterContentType;
};

const SocialIcons: React.FC<SocialIconsProps> = ({ footerContent, loaded }) => {
  return loaded ? (
    <div className={styles.socialLinks}>
      <a
        aria-label="Youtube"
        href={footerContent?.social?.youtube_url}
        onMouseDown={e => e.preventDefault()}
        rel="noopener noreferrer"
        target="_blank"
      >
        <YoutubeLogo role="img" aria-hidden="true" />
      </a>
      <a
        aria-label="Twitter"
        href={footerContent?.social?.twitter_url}
        onMouseDown={e => e.preventDefault()}
        rel="noopener noreferrer"
        target="_blank"
      >
        <TwitterLogo role="img" aria-hidden="true" />
      </a>
      <a
        aria-label="Linkedin"
        href={footerContent?.social?.linkedin_url}
        onMouseDown={e => e.preventDefault()}
        rel="noopener noreferrer"
        target="_blank"
      >
        <LinkedinLogo role="img" aria-hidden="true" />
      </a>
      <a
        aria-label="Facebook"
        href={footerContent?.social?.facebook_url}
        onMouseDown={e => e.preventDefault()}
        rel="noopener noreferrer"
        target="_blank"
      >
        <FacebookLogo role="img" aria-hidden="true" />
      </a>
    </div>
  ) : (
    <Skeleton />
  );
};

export default SocialIcons;
