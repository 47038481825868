import { useCallback, useEffect, useRef } from 'react';

import { setPaymentMethodDeleted, setPaymentMethodDeleting } from './utils';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { ModalType } from 'modules/modals/constants';
import { PaymentInfoType } from '../types';
import { API, api } from 'modules/api';
import logger from 'modules/logger';

type UseHandleRemoveCardProps = {
  modal: React.MutableRefObject<string | null>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentMethods: React.Dispatch<React.SetStateAction<PaymentInfoType[]>>;
};

function useRemovePaymentMethod({
  modal,
  setLoading,
  setPaymentMethods,
}: UseHandleRemoveCardProps) {
  const { modalShow, modalHide } = useModalAction();
  const active = useRef(true);
  //TODO done useCallback
  const removePaymentMethod = useCallback(
    async (id: string) => {
      try {
        setPaymentMethodDeleting(true, id, setPaymentMethods);
        await api(API.DELETE_PAYMENT_METHOD(id));
        if (active.current) {
          setPaymentMethodDeleted(id, setPaymentMethods);
          setLoading(true);
        }
      } catch (error) {
        if (active.current) {
          setPaymentMethodDeleting(false, id, setPaymentMethods);
          logger.error('Delete Payment Method Error', error);
          modal.current = modalShow({
            onClose: () => {
              !!modal.current && modalHide(modal.current);
              modal.current = null;
            },
            title: "Oh my... There's a problem!",
            text: 'There was an error trying to remove the payment method.',
            type: ModalType.INFO,
          });
        }
      }
    },
    [modal, modalHide, modalShow, setLoading, setPaymentMethods],
  );

  const handleRemovePaymentMethod = useCallback(
    (id: string) => {
      if (!modal.current) {
        modal.current = modalShow({
          onCancel: () => {
            !!modal.current && modalHide(modal.current);
            modal.current = null;
          },
          onConfirm: () => {
            !!modal.current && modalHide(modal.current);
            modal.current = null;
            removePaymentMethod(id);
          },
          title: 'Remove Payment Method',
          text: 'Are you sure you want to remove this payment method?',
          type: ModalType.CONFIRM,
        });
      }
    },
    [modal, modalHide, modalShow, removePaymentMethod],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return handleRemovePaymentMethod;
}

export default useRemovePaymentMethod;
