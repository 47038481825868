import React, { useCallback, useEffect, useRef, useState } from 'react';

import { API, api } from 'modules/api';
import { clearCache } from 'modules/utils/utils';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { ReactComponent as Bookmarked } from 'modules/theme/icons/misc/card-bookmarked.svg';
import { ReactComponent as Like } from 'modules/theme/icons/misc/card-no-like.svg';
import { ReactComponent as Liked } from 'modules/theme/icons/misc/card-like.svg';
import { ReactComponent as NoBookmarked } from 'modules/theme/icons/misc/card-no-bookmarked.svg';
import { TriggerGTMEvent } from 'modules/gtm';

import styles from './styles.module.scss';

type SocialProps = {
  bookmarked: boolean;
  contentTypeUid: string;
  handleBookmarkClick?: (contentTypeUid: string) => void;
  liked: boolean;
  likes: number;
  pageSlug: string;
};

const Social: React.FunctionComponent<SocialProps> = ({
  bookmarked,
  contentTypeUid,
  handleBookmarkClick,
  liked,
  likes = 0,
  pageSlug,
}) => {
  const [cardLiked, setCardLiked] = useState(liked);
  const [cardLikes, setCardLike] = useState(likes);
  const [cardBookmarked, setCardBookmarked] = useState(bookmarked);
  const active = useRef(true);

  useEffect(() => {
    setCardBookmarked(bookmarked);
    setCardLiked(liked);
    setCardLike(likes);
  }, [bookmarked, contentTypeUid, liked, likes]);

  //TODO done useCallback
  const postLike = useCallback(
    async (newLikeValue: boolean) => {
      try {
        setCardLiked(newLikeValue);
        if (newLikeValue) {
          setCardLike(cardLikes + 1);
        } else {
          setCardLike(cardLikes - 1);
        }
        await api(
          API.POST_LIKE_CONTENT_TYPE({
            ContentTypeUid: contentTypeUid,
            Liked: newLikeValue,
          }),
        );
        if (active.current) {
          clearCache();
          if (newLikeValue) {
            TriggerGTMEvent({
              action: EventsActions.LIKE_CONTENT_ITEMS,
              category: EventsCategories.CONTENT,
              label: pageSlug,
            });
          }
        }
      } catch (error) {
        if (active.current) {
          setCardLiked(!newLikeValue);
          if (newLikeValue) {
            setCardLike(cardLikes - 1);
          } else {
            setCardLike(cardLikes + 1);
          }
        }
      }
    },
    [cardLikes, contentTypeUid, pageSlug],
  );

  //TODO done useCallback
  const postBookmark = useCallback(
    async (newBookmarkValue: boolean) => {
      try {
        setCardBookmarked(newBookmarkValue);
        await api(
          API.POST_BOOKMARK_CONTENT_TYPE({
            ContentTypeUid: contentTypeUid,
            Liked: newBookmarkValue,
          }),
        );
        if (active.current) {
          clearCache();
          if (newBookmarkValue) {
            TriggerGTMEvent({
              action: EventsActions.BOOKMARK_CONTENT_ITEMS,
              category: EventsCategories.CONTENT,
              label: pageSlug,
            });
          }
          if (!newBookmarkValue && handleBookmarkClick) {
            handleBookmarkClick(contentTypeUid);
          }
        }
      } catch (error) {
        active.current && setCardBookmarked(!newBookmarkValue);
      }
    },
    [contentTypeUid, handleBookmarkClick, pageSlug],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return (
    <div className={styles.cardSocial}>
      <div className={styles.bookmark}>
        {cardBookmarked ? (
          <Bookmarked
            onClick={() => postBookmark(false)}
            className={styles.bookmarkIcon}
          />
        ) : (
          <NoBookmarked
            onClick={() => postBookmark(true)}
            className={styles.bookmarkIcon}
          />
        )}
      </div>
      <div className={styles.likes}>
        {cardLiked ? (
          <Liked onClick={() => postLike(false)} />
        ) : (
          <Like onClick={() => postLike(true)} />
        )}
        {cardLikes > 0 && (
          <span>
            {cardLikes > 1 ? `${cardLikes} Likes` : `${cardLikes} Like`}
          </span>
        )}
      </div>
    </div>
  );
};

export default Social;
