import React, { PropsWithChildren } from 'react';
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme,
} from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiDialog from '@material-ui/core/Dialog';

const theme = unstable_createMuiStrictModeTheme();

type ModalOverlayProps = PropsWithChildren<{
  disableRestoreFocus?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  onEscapeKeyDown?: () => void;
  onExited?: () => void;
  onClose?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  open: boolean;
  scroll?: 'body' | 'paper';
  TransitionComponent?: React.ForwardRefExoticComponent<
    TransitionProps & React.RefAttributes<unknown>
  >;
}>;

export default function ModalOverlay({ ...props }: ModalOverlayProps) {
  return (
    <ThemeProvider theme={theme}>
      <MuiDialog disablePortal={true} disableEnforceFocus={true} {...props} />
    </ThemeProvider>
  );
}
