import React from 'react';

import { ButtonColour } from 'components/Button/constants';
import { CardType } from 'components/Card/constants';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { getDateFormatted, getLink, truncate } from 'modules/utils/utils';
import { ThreeColResourceType } from './types';
import { TriggerGTMEvent } from 'modules/gtm';
import { useMediaQuery } from 'react-responsive';
import CtaButton from 'components/Cta/Button';
import * as Hyperlink from 'components/Hyperlink';
import Excerpt from '../components/Excerpt';
import Heading from '../components/Heading';
import Item from '../Item';
import Social from '../components/Social';
import TextLink from '../components/TextLink';

import styles from './styles.module.scss';

export type ThreeColumnProps = {
  data: ThreeColResourceType[];
  displayDate?: boolean;
  handleBookmarkClick?: (contentTypeUid: string) => void;
};

const ThreeColumn: React.FunctionComponent<ThreeColumnProps> = ({
  data,
  displayDate = false,
  handleBookmarkClick,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const handleReadNow = (slug: string) => {
    TriggerGTMEvent({
      action: EventsActions.CLICK_READ_NOW,
      category: EventsCategories.CONTENT,
      label: slug,
    });
  };

  return (
    <div className={styles.ThreeColumnCards}>
      {data.slice(0, 3).map((post: ThreeColResourceType, index: number) => {
        const textLinkCType = post.categorisation.content_type;
        const itemDetailLink = getLink(
          data[index].categorisation.content_type,
          data[index].slug,
        );

        return (
          <Item
            key={`event-row-${index}`}
            image={post.images?.featured_image?.url}
            contentFull
            imageSize={CardType.IMAGE_Med}
            itemDetailLink={itemDetailLink}
          >
            {displayDate && (
              <time
                className={styles.publicationDate}
                dateTime={post.meta.publication_date}
              >
                {getDateFormatted(post.meta.publication_date)}
              </time>
            )}

            <Hyperlink.CustomLink href={itemDetailLink}>
              <Heading heading={truncate(post.title, 60)} />
            </Hyperlink.CustomLink>

            {!isMobile && (
              <div className={styles.socialContainer}>
                <Social
                  likes={post.likes}
                  liked={post.liked}
                  bookmarked={post.bookmarked}
                  contentTypeUid={post.uid}
                  handleBookmarkClick={handleBookmarkClick}
                  pageSlug={post.slug}
                />
              </div>
            )}

            <div className={styles.introTextContainer}>
              <Hyperlink.CustomLink href={itemDetailLink}>
                <Excerpt excerpt={post.intro_content.intro_paragraph} safe />
              </Hyperlink.CustomLink>
            </div>

            <div className={styles.cardCta}>
              {isMobile && (
                <div className={styles.socialContainer}>
                  <Social
                    likes={post.likes}
                    liked={post.liked}
                    bookmarked={post.bookmarked}
                    contentTypeUid={post.uid}
                    handleBookmarkClick={handleBookmarkClick}
                    pageSlug={post.slug}
                  />
                </div>
              )}

              {isMobile ? (
                <TextLink
                  content_type={textLinkCType}
                  label="Read now"
                  onClick={() => handleReadNow(post.slug)}
                  slug={post.slug}
                />
              ) : (
                <CtaButton
                  defaultColour={ButtonColour.BRAND_BERRY}
                  label="Read now"
                  onClick={() => handleReadNow(post.slug)}
                  page_link={itemDetailLink}
                  small
                />
              )}
            </div>
          </Item>
        );
      })}
    </div>
  );
};

export default ThreeColumn;
