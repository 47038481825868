import React, { useContext } from 'react';

import { AccountDataContext } from 'modules/context';
import { useACPs } from './hooks';

import styles from './styles.module.scss';

const ACPs: React.FunctionComponent = () => {
  const { accountDataContext } = useContext(AccountDataContext);
  const { maturityModels, products, tierNames } = useACPs(accountDataContext);
  return (
    <div className={styles.acps}>
      {accountDataContext?.company && <h4>{accountDataContext?.company}</h4>}
      <p>
        {tierNames?.map((tierName: string, index: number) => {
          return index + 1 !== tierNames.length ? `${tierName}, ` : tierName;
        })}{' '}
        tier
      </p>
      {(maturityModels || products) && (
        <div>
          {products && (
            <div>
              <p>Products</p>
              <ul>
                {products.map((product: string, key: number) => (
                  <li key={`product-${key}`}>{product}</li>
                ))}
              </ul>
            </div>
          )}
          {maturityModels && (
            <div>
              <p>Maturity model(s)</p>
              <ul>
                {maturityModels.map((maturityModel: string, key: number) => (
                  <li key={`maturity-model-${key}`}>{maturityModel}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ACPs;
