import { SignUp } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { ProtectedRoutePropsType } from './types';

export const ProtectedRouteSignup: React.FunctionComponent<ProtectedRoutePropsType> =
  ({ path }) => {
    return (
      <Route path={path}>
        <SignUp />
      </Route>
    );
  };
