import React from 'react';
import { useId } from 'react-id-generator';
import clsx from 'clsx';

import { useAccordion } from 'modules/hooks';
import * as Button from 'components/Button';
import RichText from 'components/RichText';

import styles from './styles.module.scss';

export type AccordionContentProps = {
  image: {
    image: {
      title: string;
      url: string;
    };
    image_caption?: string;
    image_alt_text: string;
  };
  copy?: {
    copy: string;
  };
};

export type AccordionItemProps = {
  children?: React.ReactNode;
  closed?: boolean;
  item?: {
    title: string;
    content?: AccordionContentProps[];
  };
  onClick?: (questionTitle: string) => void;
  title?: string;
};

const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  closed = true,
  item,
  onClick,
  title,
}) => {
  const { animating, handleClick, open, ref } = useAccordion(closed);
  const [id] = useId(1, 'accordion-');
  const className = clsx(
    styles.accordionItem,
    animating && styles.animating,
    !open && styles.closed,
  );

  return (
    <div className={className}>
      <div>
        <div>
          <h3>{item?.title ?? title}</h3>
          <Button.Generic
            ariaControls={id}
            ariaExpanded={open}
            ariaLabel={`${title} Accordion`}
            onClick={() => handleClick(item?.title, onClick)}
          >
            <span></span>
          </Button.Generic>
        </div>
      </div>
      <div aria-hidden={!open} id={id} ref={ref}>
        <div>
          {children}
          {item?.content?.map((i: AccordionContentProps, key: any) => {
            return i.copy && <RichText content={i.copy.copy} key={key} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AccordionItem);
