import React from 'react';
import clsx from 'clsx';

import { ButtonStyle } from './constants';
import { ButtonSubmitProps } from './types';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { ReactComponent as LargeChevronSVG } from 'modules/theme/icons/chevrons/large-chevron-down.svg';
import { ReactComponent as SliceSVG } from 'modules/theme/ims/button-slice.svg';
import { setAriaLabel, setTabIndex } from './helpers';
import useHandleMouseDown from './hooks/handleMouseDown';

import styles from './styles.module.scss';

const Submit: React.FunctionComponent<ButtonSubmitProps> = ({
  ariaLabel,
  children,
  className,
  colour,
  disabled,
  form,
  id,
  isSubmitting,
  label,
  onClick,
  readOnly,
  style,
  small,
  showSpinner = true,
  tabIndex,
  title,
}) => {
  const buttonClassName = clsx(
    style && styles.button,
    styles[`${style}`],
    styles[`${colour?.replace(/\s/g, '')}`],
    small && styles.small,
    disabled && styles.disabled,
    readOnly && styles.readOnly,
    isSubmitting && styles.submitting,
    className,
  );

  const handleMouseDown = useHandleMouseDown();

  return (
    <>
      {showSpinner && isSubmitting && (
        <span className={clsx(styles.spinner, small && styles.small)}></span>
      )}
      <button
        aria-label={setAriaLabel(ariaLabel, label)}
        className={buttonClassName}
        disabled={disabled}
        form={form}
        id={id}
        onClick={onClick}
        onMouseDown={handleMouseDown}
        tabIndex={setTabIndex(disabled, tabIndex)}
        type="submit"
        title={title}
      >
        {label && <span>{label}</span>}
        {children}
        {style === ButtonStyle.PRIMARY && (
          <>
            {small ? (
              <ChevronSVG className={styles['chevron']} />
            ) : (
              <LargeChevronSVG className={styles['chevron']} />
            )}
            <SliceSVG className={styles['slice']} />
          </>
        )}
      </button>
    </>
  );
};

export default Submit;
