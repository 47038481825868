import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { getPlanDuration } from './helpers';
import { ProductInfoProps } from '../types';
import Back from '../Back';
import PurchaseSkeleton from './PurchaseSkeleton';

import styles from './styles.module.scss';
import { ReactComponent as HelpSVG } from 'modules/theme/icons/form/help.svg';

type PurchaseSummaryType = ProductInfoProps & {
  children?: React.ReactNode;
  discountAmount: string | null;
  loading?: boolean;
  paymentAmount: string | null;
  planAmount: string | null;
  planDescription: string | null;
  planName: string | null;
  salesTaxAmount: string | null;
  salesTaxPercentage: string | null;
  showBackLink: boolean;
};

const PurchaseSummary: React.FC<PurchaseSummaryType> = ({
  children,
  creditAmount,
  discountAmount,
  loading = false,
  paymentAmount,
  planAmount,
  planDescription,
  planName,
  salesTaxAmount,
  salesTaxPercentage,
  showBackLink,
}) => {
  const { search: params } = useLocation();
  const isDesktopBreakpoint = useMediaQuery({ query: '(min-width: 768px)' });
  const isDiscounted = useMemo(
    () => discountAmount && Number(discountAmount) !== 0,
    [discountAmount],
  );
  const isCredited = useMemo(
    () => creditAmount && Number(creditAmount) !== 0,
    [creditAmount],
  );

  const duration = useMemo(() => getPlanDuration(params), [params]);

  const classNames = clsx(
    styles.purchaseSummary,
    isDiscounted && styles.hasDiscount,
  );

  return !loading ? (
    <div className={classNames}>
      {isDesktopBreakpoint && showBackLink && (
        <Back href={process.env.REACT_APP_MARKETING_PAGE!} />
      )}
      <h3>Purchase summary</h3>
      <p className={styles.product}>
        {planName} {duration && `- ${duration}`}
      </p>
      {planDescription && (
        <ul>
          <li>{planDescription}</li>
        </ul>
      )}
      <p>Bundle price: £{planAmount}</p>
      <p>
        Tax/VAT ({salesTaxPercentage}%): £{salesTaxAmount}
      </p>
      {isCredited && (
        <div>
          <p>
            <span className={styles.credit}>Pro rata credit:</span>
            {' £' + creditAmount}
          </p>

          <HelpSVG />

          <div role="tooltip" aria-hidden="true">
            You are receiving this discount because you've already paid up to
            the rest of this month. We don't want to charge you twice, so we're
            giving you back the remainder as a discount.
          </div>
        </div>
      )}
      {isDiscounted && (
        <p>
          <span className={styles.discount}>Discount:</span>{' '}
          {' £' + discountAmount}
        </p>
      )}
      <p className={styles.total}>Total: £{paymentAmount}</p>
      {children}
    </div>
  ) : (
    <PurchaseSkeleton />
  );
};

export default React.memo(PurchaseSummary);
