import React from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { BraintreeCardType } from 'modules/braintree/constants';
import { ReactComponent as ArrowSVG } from './arrow.svg';
import { getCardTitle } from 'modules/braintree/utils';
import BraintreeIcon from 'modules/braintree/icon';
import { useAccordion } from 'modules/hooks';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

type PaymentMethodProps = {
  cardNumber: string;
  cardType: BraintreeCardType;
  closed?: boolean;
  defaultCard: boolean;
  onEdit: () => void;
  onRemove: () => void;
  valid: boolean;
};

const PaymentMethod: React.FunctionComponent<PaymentMethodProps> = ({
  cardNumber,
  cardType,
  closed,
  defaultCard,
  onEdit,
  onRemove,
  valid,
}) => {
  const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 640px)' });
  const { animating, handleClick, open, ref } = useAccordion(closed);
  const className = clsx(
    styles.paymentMethod,
    defaultCard && styles.defaultCard,
    animating && isMobileBreakpoint && styles.animating,
    !open && isMobileBreakpoint && styles.closed,
    !valid && styles.invalid,
  );
  return (
    <div className={className}>
      <div>
        <BraintreeIcon type={cardType} />
        <h4>
          {getCardTitle(cardType)} <strong>{cardNumber}</strong>
          {defaultCard && <span> - Primary</span>}
        </h4>
        {isMobileBreakpoint && (
          <Button.Generic
            ariaExpanded={open}
            ariaLabel={`Card number ${cardNumber}`}
            onClick={handleClick}
          >
            <ArrowSVG />
          </Button.Generic>
        )}
      </div>
      <div aria-hidden={!open} ref={ref}>
        {!defaultCard && (
          <Button.Generic onClick={onRemove}>Remove</Button.Generic>
        )}
        <Button.Generic onClick={onEdit}>Edit</Button.Generic>
      </div>
    </div>
  );
};

export default React.memo(PaymentMethod);
