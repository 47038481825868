import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import { CountryCodeProps, ProductInfoProps, VoucherCodeProps } from '../types';
import { Model, model, schema } from '../QuestionSet/model';
import * as FormControl from '../FormControl';
import * as QuestionSet from '../QuestionSet';
import Fieldset from 'components/Fieldset';
import PurchaseSummary from '../PurchaseSummary';
import QuestionsWithRecaptcha from 'components/QuestionsWithRecaptcha';

import styles from 'pages/sign-up/components/styles.module.scss';

type PersonalInfoQuestionsType = {
  countryCode: CountryCodeProps;
  defaultValues: Model;
  handleSubmit: (data: Model) => Promise<void>;
  id: string;
  isSubmitting: boolean;
  productInfo: ProductInfoProps;
  voucherCode: VoucherCodeProps;
};

const PersonalInfoQuestions: React.FunctionComponent<PersonalInfoQuestionsType> =
  ({
    countryCode,
    defaultValues,
    handleSubmit,
    id,
    isSubmitting,
    productInfo,
  }) => {
    const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });

    const updateCountry = useCallback(
      values => {
        countryCode.setValue(values.countryCode);
      },
      [countryCode],
    );

    return (
      <QuestionsWithRecaptcha
        className={styles.signUpForm}
        defaultValues={defaultValues}
        id={id}
        onSubmit={handleSubmit}
        schema={schema[2]}
      >
        {({ control, isFormSubmitting, values }) => {
          updateCountry(values); //TODO possible problem?
          return (
            <React.Fragment>
              <Fieldset title="Personal info">
                <QuestionSet.PersonalInfo
                  control={control}
                  isPeopleCertUser={true}
                  isSubmitting={isFormSubmitting || isSubmitting}
                  model={model}
                />
              </Fieldset>

              <Fieldset title="User agreement">
                <QuestionSet.Consent
                  control={control}
                  isSubmitting={isFormSubmitting || isSubmitting}
                  model={model}
                />
              </Fieldset>

              {isMobileBreakpoint && (
                <PurchaseSummary showBackLink={true} {...productInfo} />
              )}

              <FormControl.Default
                isSubmitting={isFormSubmitting || isSubmitting}
              />
            </React.Fragment>
          );
        }}
      </QuestionsWithRecaptcha>
    );
  };

export default React.memo(PersonalInfoQuestions);
