import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useHeadingsData, useHeight } from './hooks';
import * as Button from 'components/Button';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/large-chevron-down.svg';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import styles from './styles.module.scss';
import { useMediaQuery } from 'react-responsive';

type TableOfContentsProps = {
  tableOfContentsTitle: string;
};

type HeadingsItemProps = {
  handleHeadingClick: (id: string) => void;
  id: string;
  title: string;
};

type SlicedHeadingsListProps = {
  end: number;
  handleHeadingClick: (id: string) => void;
  items: any;
  start: number;
};

export const HeadingsItem: React.FC<HeadingsItemProps> = ({
  handleHeadingClick,
  id,
  title,
}) => {
  return (
    <li>
      <a href={`#${id}`} onClick={() => handleHeadingClick(id)}>
        {title}
      </a>
    </li>
  );
};

export const SlicedHeadingsList: React.FC<SlicedHeadingsListProps> = ({
  end,
  handleHeadingClick,
  items,
  start,
}) => {
  return (
    <ul>
      {items.slice(start, end).map((item: any, index: any) => (
        <HeadingsItem
          handleHeadingClick={handleHeadingClick}
          id={item.id}
          key={index}
          title={item.title}
        />
      ))}
    </ul>
  );
};

export const TableOfContents: React.FunctionComponent<TableOfContentsProps> = ({
  tableOfContentsTitle,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { headings } = useHeadingsData();
  const { height } = useHeight();
  const [toggleButton, setToggleButton] = useState(
    ref.current && ref.current.getBoundingClientRect().bottom < height,
  );

  const className = clsx(
    styles.tableOfContents,
    'container small',
    toggleButton && styles.showButton,
  );

  const handleHeadingClick = (id: string) => {
    const headingElement = document.querySelector(`[id='${id}']`);
    headingElement !== null &&
      headingElement.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const hideBackToTop = () => {
      if (ref.current && ref.current.getBoundingClientRect().bottom < height) {
        setToggleButton(true);
      } else {
        setToggleButton(false);
      }
    };
    window.addEventListener('scroll', hideBackToTop);
    return () => {
      window.removeEventListener('scroll', hideBackToTop);
    };
  }, [toggleButton, ref, height]);

  const TwoColumnsList = (
    <>
      <SlicedHeadingsList
        start={0}
        end={Math.ceil(headings.length / 2)}
        handleHeadingClick={handleHeadingClick}
        items={headings}
      />
      <SlicedHeadingsList
        start={Math.ceil(headings.length / 2)}
        end={headings.length}
        handleHeadingClick={handleHeadingClick}
        items={headings}
      />
    </>
  );

  const ThreeColumnsList = (
    <>
      <SlicedHeadingsList
        start={0}
        end={Math.ceil(headings.length / 3)}
        handleHeadingClick={handleHeadingClick}
        items={headings}
      />
      <SlicedHeadingsList
        start={Math.ceil(headings.length / 3)}
        end={Math.ceil(headings.length / 1.5)}
        handleHeadingClick={handleHeadingClick}
        items={headings}
      />
      <SlicedHeadingsList
        start={Math.ceil(headings.length / 1.5)}
        end={headings.length}
        handleHeadingClick={handleHeadingClick}
        items={headings}
      />
    </>
  );

  const displayThreeColumns = useMediaQuery({
    query: `(min-width:1024px)`,
  });
  return headings.length > 0 ? (
    <div id="TableOfContents" className={styles['table-of-contents']}>
      <div className={className} ref={ref}>
        <h2>
          <span>
            {tableOfContentsTitle ? tableOfContentsTitle : 'Table of Contents'}
          </span>
        </h2>
        <div className={styles['list']}>
          {displayThreeColumns ? ThreeColumnsList : TwoColumnsList}
        </div>
        <Button.CustomLink
          className={styles.backToTopButton}
          label="Back to Top"
          href="#TableOfContents"
          colour={ButtonColour.BRAND_AQUA}
          style={ButtonStyle.SECONDARY}
        >
          <ChevronSVG />
        </Button.CustomLink>
      </div>
    </div>
  ) : null;
};
