export enum ButtonType {
  BUTTON = 'button',
  CUSTOM_LINK = 'custom-link',
  PAGE_LINK = 'page-link',
  SUBMIT = 'submit',
}

export enum ButtonStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum IconStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ButtonColour {
  BRAND_AQUA = 'Aqua',
  BRAND_BLACK_GRAPE = 'Black Grape',
  BRAND_BERRY = 'Berry',
  WHITE = 'White',
  BRAND_MONO_ONE = 'Mono 1',
}

export enum ButtonTarget {
  BLANK = '_blank',
  SELF = '_self',
}
