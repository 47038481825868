import React from 'react';
import { BodyContentTextWSubheading } from '../../types';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { htmlSafe, wrapTableTags, removeEmptyPTags } from 'modules/utils/utils';

type TextWithSubheadingPropType = {
  component: BodyContentTextWSubheading;
};
export const TextWithSubheading: React.FunctionComponent<TextWithSubheadingPropType> =
  ({ component }) => {
    const textWithSubheadingClass = clsx(
      styles.textWithSubheading,
      component.enable_top_border && styles.topBorder,
      component.enable_bottom_border && styles.bottomBorder,
    );

    return (
      <div className={textWithSubheadingClass}>
        {component.title && (
          <h2 className={styles.title}>
            <span>{component.title}</span>
          </h2>
        )}
        {htmlSafe(wrapTableTags(removeEmptyPTags(component.content)))}
      </div>
    );
  };
