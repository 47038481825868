import { useCallback, useEffect, useRef, useState } from 'react';

import {
  BraintreeThreeDSecure,
  BraintreeThreeDSecureVerifyPayload,
  BraintreeHostedFieldsTokenizePayload,
} from '../types';
import { BraintreeErrorType } from '../constants';
import logger from 'modules/logger';

type UseBraintreeThreeDSecure = (
  threeDSecure: BraintreeThreeDSecure | null,
) => {
  handleThreeDSecureSubmit: (
    hostedFieldsPayload: BraintreeHostedFieldsTokenizePayload,
  ) => Promise<BraintreeThreeDSecureVerifyPayload>;
  isThreeDSecureSubmitting: boolean;
};

const useBraintreeThreeDSecure: UseBraintreeThreeDSecure = threeDSecure => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const active = useRef(true);
  //TODO done useCallback
  const handleSubmit = useCallback(
    async hostedFieldsPayload => {
      try {
        setIsSubmitting(true);
        const result = await threeDSecure?.verifyCard({
          amount: 0.01,
          nonce: hostedFieldsPayload.nonce,
          bin: hostedFieldsPayload.details.bin,
        });
        if (
          active.current &&
          result &&
          result.threeDSecureInfo.liabilityShifted
        ) {
          return result as BraintreeThreeDSecureVerifyPayload;
        } else {
          throw new Error(BraintreeErrorType.THREEDS_FAILED_VERIFICATION);
        }
      } catch (error) {
        active.current &&
          logger.error('Braintree 3D Secure Submit Error', error);
        throw error;
      } finally {
        active.current && setIsSubmitting(false);
      }
    },
    [threeDSecure],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    handleThreeDSecureSubmit: handleSubmit,
    isThreeDSecureSubmitting: isSubmitting,
  };
};

export default useBraintreeThreeDSecure;
