import React from 'react';

import { FooterContentType } from '..';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type LegalTextProps = {
  loaded?: boolean;
  footerContent?: FooterContentType;
};

const LegalText: React.FC<LegalTextProps> = ({ footerContent, loaded }) => {
  return loaded ? (
    <React.Fragment>
      <div className={styles.text}>
        {footerContent?.footer_text && <p>{footerContent?.footer_text}</p>}
        <p>
          <small>{footerContent?.copyright_text}</small>
        </p>
      </div>
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default LegalText;
