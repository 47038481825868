import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import { CountryCodeProps, ProductInfoProps, VoucherCodeProps } from '../types';
import { CountryOfResidenceSchema, model, Model } from '../QuestionSet/model';
import * as FormControl from '../FormControl';
import * as QuestionSet from '../QuestionSet';
import Fieldset from 'components/Fieldset';
import PurchaseSummary from '../PurchaseSummary';
import Questions from 'components/Questions';
import VoucherCode from '../VoucherCode';

import styles from './styles.module.scss';

type CountryQuestionsType = {
  countryCode: CountryCodeProps;
  defaultValues: Model;
  handleSubmit: (data: any) => void;
  id: string;
  isSubmitting: boolean;
  productInfo: ProductInfoProps;
  voucherCode: VoucherCodeProps;
};

const CountryOfResidenceQuestions: React.FunctionComponent<CountryQuestionsType> =
  ({
    countryCode,
    defaultValues,
    handleSubmit,
    id,
    isSubmitting,
    productInfo,
    voucherCode,
  }) => {
    const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });
    const updateCountry = useCallback(
      values => {
        countryCode.setValue(values.countryCode);
      },
      [countryCode],
    );

    return (
      <Questions
        className={styles.signUpForm}
        defaultValues={defaultValues}
        id={id}
        onSubmit={handleSubmit}
        schema={CountryOfResidenceSchema}
      >
        {({ control, isFormSubmitting, values }) => {
          updateCountry(values); //TODO possible problem?
          return (
            <React.Fragment>
              <Fieldset title="Personal Information">
                <QuestionSet.CountryOfResidence
                  control={control}
                  isSubmitting={isFormSubmitting || isSubmitting}
                  model={model}
                />
              </Fieldset>

              {isMobileBreakpoint && (
                <PurchaseSummary showBackLink={false} {...productInfo}>
                  <VoucherCode {...voucherCode} isSubmitting={isSubmitting} />
                </PurchaseSummary>
              )}

              <FormControl.PersonalInfo
                isSubmitting={isFormSubmitting || isSubmitting}
              />
            </React.Fragment>
          );
        }}
      </Questions>
    );
  };

export default React.memo(CountryOfResidenceQuestions);
