import React, { useCallback, useEffect } from 'react';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { useOpenSubscriptions } from 'modules/hooks/useOpenSubscriptions';
import { useSignupReminder } from './hooks';
import {
  SIGNUP_REMINDER_CLIKED_COOKIE,
  SIGNUP_REMINDER_CLIKED_VALUE,
} from 'modules/utils/constants';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

export const SignupReminder = () => {
  const { getSignupReminderMessage, signupReminderMessage } =
    useSignupReminder();

  const { openSubscriptions } = useOpenSubscriptions();

  useEffect(() => {
    getSignupReminderMessage();
  }, [getSignupReminderMessage]);

  const handleClick = useCallback(() => {
    sessionStorage.setItem(
      SIGNUP_REMINDER_CLIKED_COOKIE,
      SIGNUP_REMINDER_CLIKED_VALUE,
    );
    openSubscriptions();
  }, [openSubscriptions]);

  return (
    <React.Fragment>
      {signupReminderMessage && (
        <div className={styles.signupReminder}>
          <div>
            {signupReminderMessage?.length > 0 &&
            signupReminderMessage[0].message
              ? signupReminderMessage[0].message
              : 'Enjoying MyAxelos? Choose a plan'}
          </div>
          <Button.Generic
            onClick={handleClick}
            colour={ButtonColour.BRAND_BERRY}
            small={true}
            style={ButtonStyle.PRIMARY}
            label={
              signupReminderMessage.length > 0 &&
              signupReminderMessage[0].cta_label.length > 0 &&
              signupReminderMessage[0].cta_label[0].title
                ? signupReminderMessage[0].cta_label[0].title
                : 'Sign up'
            }
          />
        </div>
      )}
    </React.Fragment>
  );
};
