import { useCallback, useContext, useMemo, useState } from 'react';

import { AccountDataContext } from 'modules/context';
import { defaultValues, Model } from '../QuestionSet/model';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { UserSubscriptionPlanType } from 'modules/api/endpoints/user-subscription-plan';
import useSubscribeUser from 'pages/sign-up/hooks/useSubscribeUser';

type useSubscribeWithPaymentMethodProps = {
  paymentMethodDefaultValue: string;
  requiresCountry: boolean;
  subscriptionPlan: UserSubscriptionPlanType | null;
  subscriptionState: SubscriptionStateEnum;
};

export enum SubscribeFormSteps {
  Country = 1,
  PaymentMethods = 2,
  NewCard = 3,
}

function useSubscribeWithPaymentMethod({
  paymentMethodDefaultValue,
  requiresCountry,
  subscriptionPlan,
  subscriptionState,
}: useSubscribeWithPaymentMethodProps) {
  const { subscribeExistingUser, isSubmitting } = useSubscribeUser();
  const [activeStep, setActiveStep] = useState(
    requiresCountry
      ? Number(SubscribeFormSteps.Country)
      : Number(SubscribeFormSteps.PaymentMethods),
  );

  const { accountDataContext } = useContext(AccountDataContext);
  const accountDataValues = useMemo(() => {
    return {
      email: accountDataContext?.email ?? '',
      givenName: accountDataContext?.firstName ?? '',
      surname: accountDataContext?.lastName ?? '',
    };
  }, [accountDataContext]);

  const formData: Model = useMemo(() => {
    return {
      ...defaultValues,
      ...accountDataValues,
      paymentMethodId: paymentMethodDefaultValue,
    };
  }, [accountDataValues, paymentMethodDefaultValue]);

  const handleBack = useCallback(() => {
    window.scrollTo(0, 0);
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  const handleNext = useCallback(() => {
    window.scrollTo(0, 0);
    setActiveStep(activeStep + 1);
  }, [activeStep]);
  //TODO done useCallback
  const handleSubmit = useCallback(
    async (data: any) => {
      await subscribeExistingUser(
        {
          candidateToken: subscriptionPlan?.candidateToken,
          countryCode: requiresCountry
            ? subscriptionPlan?.countryCode
            : data.countryCode,
          couponCode: subscriptionPlan?.couponCode,
          paymentMethodId: data?.paymentMethodId,
          subscriptionPlanId: subscriptionPlan?.subscriptionPlanId,
        },
        subscriptionState,
      );
    },
    [
      requiresCountry,
      subscribeExistingUser,
      subscriptionPlan,
      subscriptionState,
    ],
  );
  //TODO done useCallback
  const handleSubmitWithBraintree = useCallback(
    async (data: Model & { nonce: string }) => {
      await subscribeExistingUser(
        {
          candidateNumber: data.candidateNumber,
          candidateToken: subscriptionPlan?.candidateToken,
          countryCode: data.countryCode,
          couponCode: subscriptionPlan?.couponCode,
          email: data.email,
          givenName: data.givenName,
          paymentNonce: data.nonce,
          subscriptionPlanId: subscriptionPlan?.subscriptionPlanId,
          surname: data.surname,
        },
        subscriptionState,
      );
    },
    [subscribeExistingUser, subscriptionPlan, subscriptionState],
  );

  return {
    activeStep,
    defaultValues: formData,
    handleNext,
    handleBack,
    handleSubmit,
    handleSubmitWithBraintree,
    isSubmitting,
  };
}

export default useSubscribeWithPaymentMethod;
