import React, { memo } from 'react';

import { BadgesCandidateIdTypes } from '../../types';
import Skeleton from './Skeleton';

import styles from './styles.module.scss';

type CandidateIdsProps = {
  ids?: BadgesCandidateIdTypes[];
  loaded: boolean;
};

const CandidateIds: React.FunctionComponent<CandidateIdsProps> = ({
  ids,
  loaded,
}) => {
  return loaded ? (
    <React.Fragment>
      {ids && ids.length > 0 && (
        <div className={styles.candidateIds}>
          <h3>Your Candidate Numbers</h3>
          <ul>
            {ids
              .slice(0)
              .reverse()
              .map((id: BadgesCandidateIdTypes, index: number) => (
                <li key={`candidate-id-${index}`}>{id}</li>
              ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default memo(CandidateIds);
