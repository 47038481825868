import React from 'react';
import clsx from 'clsx';

import badgesStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(badgesStyles.badges, styles.skeleton);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading your digital badges
      </span>
      <section className={classNames} aria-hidden="true">
        <div>
          <p></p>
          <p></p>
        </div>
        <div>
          <ul>
            <li>
              <div>
                <p></p>
              </div>
            </li>
            <li>
              <div>
                <p></p>
              </div>
            </li>
            <li>
              <div>
                <p></p>
                <div></div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Skeleton;
