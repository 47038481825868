import React, { useContext, useRef } from 'react';
import clsx from 'clsx';

import {
  AccountDataContext,
  LoginTypeContext,
  SubscriptionEndDateContext,
} from 'modules/context';
import { getDateWithOrdinal } from 'modules/utils/utils';
import { LoginTypeEnum } from 'modules/utils/types';
import { useProfilePhoto } from './hooks';
import * as Button from 'components/Button';
import avatar from 'modules/theme/ims/default-avatar.svg';
import Skeleton from './Skeleton';
import styles from './styles.module.scss';

const Summary: React.FunctionComponent = () => {
  const refForm = useRef<HTMLFormElement>(null);
  const { accountDataContext, setAccountDataContext } =
    useContext(AccountDataContext);
  const { isSubmitting, profilePhoto, handleAddPicture, handleDeletePicture } =
    useProfilePhoto(accountDataContext, refForm, setAccountDataContext);
  const { subscriptionEndDateContext } = useContext(SubscriptionEndDateContext);
  const { loginTypeContext } = useContext(LoginTypeContext);

  const label =
    profilePhoto !== '' ? 'Update profile image' : 'Add profile image';
  const classNames = clsx(styles.summary, isSubmitting && styles.loading);
  const isSubscriptionExpired =
    accountDataContext?.subscription?.cancelledAt !== '' &&
    accountDataContext?.subscription?.cancelledAt !== null;

  return (
    <div className={classNames}>
      <form ref={refForm}>
        <div>
          <input
            className={styles.hidden}
            accept="image/jpeg, image/png"
            id="fileInput"
            onMouseDown={e => e.preventDefault()}
            tabIndex={isSubmitting ? -1 : 0}
            type="file"
            title={label}
            onChange={handleAddPicture}
          />
          <label aria-label={label} htmlFor="fileInput">
            <picture>
              <img src={profilePhoto || avatar} alt="Profile" />
            </picture>
          </label>
        </div>
        <div>
          <span>{accountDataContext?.name}</span>
          {profilePhoto !== '' && (
            <Button.Generic
              disabled={isSubmitting}
              label="Remove image"
              onClick={handleDeletePicture}
            />
          )}
        </div>
      </form>
      {loginTypeContext === LoginTypeEnum.INDIVIDUAL && (
        <React.Fragment>
          {subscriptionEndDateContext ? (
            <div>
              {isSubscriptionExpired
                ? 'Subscription expires'
                : 'Subscription renews'}{' '}
              <strong>
                {getDateWithOrdinal(subscriptionEndDateContext, false)}
              </strong>
            </div>
          ) : (
            <Skeleton />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Summary;
