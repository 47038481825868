import React, { useCallback, useContext } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { RemoveScroll } from 'react-remove-scroll';
import Zoom from '@material-ui/core/Zoom';
import clsx from 'clsx';

import PlatformContext from 'modules/platform/context';
import { ModalSize } from 'modules/modals/constants';
import ModalOverlay from '../Overlay';

import modalStyles from 'modules/modals/styles.module.scss';
import styles from './styles.module.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />;
});

export type ModalEmailChangeTemplateProps = {
  size?: ModalSize;
  text?: string | JSX.Element;
  title: string;
};

export const ModalEmailChangeTemplate =
  React.memo<ModalEmailChangeTemplateProps>(
    ({ size = ModalSize.NARROW, text, title }) => {
      const classNames = clsx(modalStyles[`${size}`], styles.modalEmailChange);
      return (
        <section className={classNames} role="dialog">
          <header>
            <h2>{title}</h2>
          </header>
          <div>{typeof text === 'string' ? <p>{text}</p> : text}</div>
        </section>
      );
    },
  );

export type ModalEmailChangeProps = {
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  id: string;
  open: boolean;
  size?: ModalSize;
  text?: string | JSX.Element;
  title: string;
};

const Info: React.FunctionComponent<ModalEmailChangeProps> = ({
  destroyModal,
  disableRestoreFocus,
  open,
  text,
  title,
}) => {
  const { isMobile } = useContext(PlatformContext);

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);
  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        disableRestoreFocus={disableRestoreFocus}
        onExited={destroyModal}
        onKeyDown={handleKeyDown}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        <ModalEmailChangeTemplate text={text} title={title} />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default Info;
