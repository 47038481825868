import { useCallback, useEffect, useRef, useState } from 'react';
import { Control, RefCallBack, useController } from 'react-hook-form';

import {
  BraintreeHostedFields,
  BraintreeHostedFieldsEvent,
  BraintreeHostedFieldsState,
} from '../types';
import logger from 'modules/logger';

type UseBraintreeField = ({
  control,
  hostedFields,
  name,
}: {
  control: Control<any>;
  hostedFields: BraintreeHostedFields | null;
  name: string;
}) => {
  error: any;
  isEmpty: boolean;
  isFocused: boolean;
  isValid: boolean;
  ref: RefCallBack;
};

const useBraintreeField: UseBraintreeField = ({
  control,
  hostedFields,
  name,
}) => {
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error, invalid },
  } = useController({
    control,
    name: name,
  });
  const [isFocused, setFocued] = useState(false);
  const [isEmpty, setEmpty] = useState(!value);

  const handleChange = useCallback(value => handleChangeRef.current(value), []);
  const handleChangeRef = useRef((value: boolean) => onChange(value));
  useEffect(() => {
    handleChangeRef.current = value => onChange(value);
  }, [onChange]);

  const handleBlur = useCallback(event => handleBlurRef.current(event), []);
  const handleBlurRef = useRef((event: BraintreeHostedFieldsEvent) => {
    name === event.emittedBy && onBlur();
    name === event.emittedBy && setFocued(false);
  });
  useEffect(() => {
    handleBlurRef.current = event => {
      name === event.emittedBy && onBlur();
      name === event.emittedBy && setFocued(false);
    };
  }, [name, onBlur]);

  const handleEmpty = useCallback(
    (event: BraintreeHostedFieldsEvent) => {
      name === event.emittedBy && handleChange(false);
      name === event.emittedBy && setEmpty(true);
    },
    [handleChange, name],
  );

  const handleFocus = useCallback(
    (event: BraintreeHostedFieldsEvent) =>
      name === event.emittedBy && setFocued(true),
    [name],
  );

  const handleNotEmpty = useCallback(
    (event: BraintreeHostedFieldsEvent) =>
      name === event.emittedBy && setEmpty(false),
    [name],
  );

  const handleValidityChange = useCallback(
    (event: BraintreeHostedFieldsEvent) =>
      name === event.emittedBy && handleChange(event.fields[name].isValid),
    [handleChange, name],
  );

  const handleInit = useCallback(
    (state: BraintreeHostedFieldsState) => {
      handleChange(!!(state.fields as any)[name].isValid);
      setEmpty(!!(state.fields as any)[name].isEmpty);
    },
    [handleChange, name],
  );

  useEffect(() => {
    if (hostedFields && !hostedFields.isTearingDown)
      try {
        hostedFields.on('blur', handleBlur);
        hostedFields.on('empty', handleEmpty);
        hostedFields.on('focus', handleFocus);
        hostedFields.on('notEmpty', handleNotEmpty);
        hostedFields.on('validityChange', handleValidityChange);
        handleInit(hostedFields.getState());
      } catch (error) {
        logger.error('Braintree Field On Error', error);
      }
    return () => {
      if (hostedFields && !hostedFields.isTearingDown)
        try {
          hostedFields.off('blur', handleBlur);
          hostedFields.off('empty', handleEmpty);
          hostedFields.off('focus', handleFocus);
          hostedFields.off('notEmpty', handleNotEmpty);
          hostedFields.off('validityChange', handleValidityChange);
        } catch (error) {
          logger.error('Braintree Field Off Error', error);
        }
    };
  }, [
    handleBlur,
    handleEmpty,
    handleFocus,
    handleInit,
    handleNotEmpty,
    handleValidityChange,
    hostedFields,
    name,
  ]);

  return {
    error,
    isEmpty,
    isFocused,
    isValid: !invalid,
    ref,
  };
};

export default useBraintreeField;
