import React from 'react';
import clsx from 'clsx';
import { InputFormat, InputSize, InputStyle, InputType } from '../constants';
import { InputPrimitiveProps } from '../types';
import InputIcon from './icons';
import Label from 'components/Label';
import styles from './styles.module.scss';

const InputPrimitive: React.FunctionComponent<InputPrimitiveProps> = ({
  autoComplete,
  className,
  disabled,
  forwardedRef,
  format = InputFormat.TEXT,
  id,
  invalid,
  label,
  style = InputStyle.PRIMARY,
  size = InputSize.REGULAR,
  type = InputType.TEXT,
  value,
  ...props
}) => {
  const classNames = clsx(
    styles.input,
    className,
    format && styles[`format-${format}`],
    style && styles[`${style}`],
    disabled && styles.disabled,
    !disabled && invalid && styles.invalid,
    size === InputSize.SMALL && styles.small,
    size === InputSize.EXTRA_SMALL && styles['extra-small'],
  );
  return (
    <div className={classNames}>
      <InputIcon format={format} />
      <input
        autoComplete={autoComplete}
        disabled={disabled}
        id={id}
        ref={forwardedRef}
        type={type}
        value={value}
        {...props}
      />
      {label && style === InputStyle.PRIMARY && (
        <Label
          id={id}
          hasStickyLabel={style === InputStyle.PRIMARY}
          isSticky={value !== '' && style === InputStyle.PRIMARY}
          label={label}
          size={size}
        />
      )}
    </div>
  );
};

export default React.memo(InputPrimitive);
