import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import useModalAction from 'modules/modals/hooks/useModalAction';
import { AccountDataContext } from 'modules/context';
import { ModalType } from 'modules/modals/constants';
import { patchAccountData } from './utils';
import { api, API } from 'modules/api';
import logger from 'modules/logger';
import { Model } from './model';

export const usePersonalInfo = () => {
  const { accountDataContext, setAccountDataContext } =
    useContext(AccountDataContext);
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const active = useRef(true);
  const defaultValues: Model = useMemo(() => {
    return {
      firstName: accountDataContext?.firstName ?? '',
      lastName: accountDataContext?.lastName ?? '',
      jobTitle: accountDataContext?.jobTitle ?? '',
    };
  }, [accountDataContext]);
  //TODO done useCallback
  const handleSubmit = useCallback(
    async (data: Model) => {
      if (!isApiSubmitting) {
        try {
          setIsApiSubmitting(true);
          const result = await api(
            API.PATCH_ACCOUNT_DATA({
              FirstName: data.firstName,
              LastName: data.lastName,
              JobTitle: data.jobTitle,
            }),
          );
          active.current &&
            patchAccountData(result.data, setAccountDataContext);
        } catch (error) {
          if (active.current) {
            logger.error('Patch Account Data Error', error);
            if (!modal.current) {
              modal.current = modalShow({
                onClose: () => {
                  !!modal.current && modalHide(modal.current);
                  modal.current = null;
                },
                title: 'Error',
                text: 'There was an error trying to send your data. Please try again later.',
                type: ModalType.INFO,
              });
            }
          }
        } finally {
          active.current && setIsApiSubmitting(false);
        }
      }
    },
    [isApiSubmitting, modalHide, modalShow, setAccountDataContext],
  );

  useEffect(() => {
    return () => {
      !!modal.current && modalHide(modal.current);
      modal.current = null;
    };
  }, [modalHide]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    defaultValues,
    handleSubmit,
    isApiSubmitting,
    loading: !accountDataContext,
  };
};
