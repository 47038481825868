import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { NotificationAction } from 'pages/home/components/Notifications/constants';

type useActionPromptLocationProps = {
  actionPrompt: NotificationAction;
};

export const useActionPrompt = (subscriptions: HTMLDivElement | null) => {
  const { state: params } = useLocation<useActionPromptLocationProps>();
  const actionPrompt = params?.actionPrompt;

  let openSubscriptions = false;

  switch (actionPrompt) {
    case NotificationAction.ADD_CARD:
    case NotificationAction.VIEW_CARD:
    case NotificationAction.EDIT_CARD:
    case NotificationAction.VIEW_SUBSCRIPTIONS:
      openSubscriptions = true;
      break;
    default:
      break;
  }

  const applyActionPrompt = useCallback(() => {
    const scrollToSubscriptions = () => {
      if (subscriptions) {
        subscriptions.scrollIntoView({ behavior: 'smooth' });
        // prevent scrolling after refresh
        window.history.replaceState({}, document.title);
      }
    };

    switch (actionPrompt) {
      case NotificationAction.ADD_CARD:
      case NotificationAction.EDIT_CARD:
      case NotificationAction.VIEW_CARD:
      case NotificationAction.VIEW_SUBSCRIPTIONS:
        scrollToSubscriptions();
        break;
      default:
        break;
    }
  }, [actionPrompt, subscriptions]);

  useEffect(() => {
    applyActionPrompt();
  }, [applyActionPrompt, subscriptions]);

  return { applyActionPrompt, openSubscriptions } as const;
};
