import { ButtonColour } from 'components/Button/constants';
import { CuratedPageType } from 'pages/curated-pages/types';
import { CURATE_PAGE_URL } from 'modules/utils/constants';
import CtaButton from 'components/Cta/Button';

import styles from './styles.module.scss';

type CuratedPagesItemProp = {
  curatedPage: CuratedPageType;
};

const CuratedPagesItem: React.FC<CuratedPagesItemProp> = ({ curatedPage }) => {
  return (
    <article className={styles.curatedPagesItem}>
      {curatedPage?.image[0]?.image_with_caption.image?.url && (
        <div className={styles.image}>
          <div
            style={{
              backgroundImage: `url(${curatedPage?.image[0]?.image_with_caption.image?.url}?format=jpg&auto=webp?quality=60)`,
            }}
          >
            <span></span>
          </div>
        </div>
      )}
      <div className={styles.content}>
        <div>
          <h3>
            {curatedPage.preview_title ||
              curatedPage.page_title?.substring(0, 30)}
          </h3>
          {(curatedPage.preview_description ||
            curatedPage.page_description) && (
            <p>
              {curatedPage.preview_description ||
                curatedPage.page_description?.substring(0, 140)}
            </p>
          )}
        </div>
        <CtaButton
          ariaLabel={
            curatedPage.preview_title ||
            curatedPage.page_title?.substring(0, 30)
          }
          defaultColour={ButtonColour.BRAND_BERRY}
          page_link={`${CURATE_PAGE_URL}${curatedPage.slug}`}
          small
        />
      </div>
    </article>
  );
};

export default CuratedPagesItem;
