import {
  hostedFields as braintreeHostedFields,
  threeDSecure as braintreeThreeDSecure,
} from 'braintree-web';

import {
  Braintree,
  BraintreeHostedFields,
  BraintreeThreeDSecure,
  BraintreeThreeDSecureVerifyData,
} from './types';
import styles from './components/BraintreeQuestion/styles';
import fields, { BraintreeFields } from './fields';
import { api, API } from 'modules/api';
import logger from 'modules/logger';

const braintree: Braintree = {
  hostedFields: null,
  threeDSecure: null,
};

export const getBraintree = async function ({
  defaultValues,
}: {
  defaultValues?: Record<string, any>;
}): Promise<Braintree> {
  teardownBraintree();
  const token = await getBraintreeToken(true);
  braintree.hostedFields = await getBraintreeHostedFields(
    fields(defaultValues),
    token,
  );
  braintree.threeDSecure = await getBraintreeThreeDSecure(token);
  return { ...braintree };
};

const getBraintreeToken = async (user: boolean): Promise<string> => {
  try {
    const response = await api(API.GET_PAYMENT_TOKEN(user));
    logger.debug('Braintree Token', response.data.paymentToken);
    return response.data.paymentToken;
  } catch (error) {
    logger.error('Braintree Token Error');
    throw error;
  }
};

const getBraintreeHostedFields = async function (
  fields: BraintreeFields,
  token: string,
): Promise<BraintreeHostedFields> {
  try {
    const response = await braintreeHostedFields.create({
      authorization: token,
      fields,
      styles,
    });
    logger.debug('Braintree Hosted Fields Create', response);
    return response;
  } catch (error) {
    logger.error('Braintree Hosted Fields Create Error');
    throw error;
  }
};

const getBraintreeThreeDSecure = async function (
  token: string,
): Promise<BraintreeThreeDSecure> {
  try {
    const response: BraintreeThreeDSecure = await braintreeThreeDSecure.create({
      authorization: token,
      version: '2',
    });
    response.on(
      'lookup-complete',
      (data: BraintreeThreeDSecureVerifyData, next: () => void) => next(),
    );
    logger.debug('Braintree 3D Secure Create', response);
    return response;
  } catch (error) {
    logger.error('Braintree 3D Secure Create Error');
    throw error;
  }
};

export const teardownBraintree = async function () {
  if (braintree.hostedFields && !braintree.hostedFields.isTearingDown)
    try {
      braintree.hostedFields.isTearingDown = true;
      braintree.hostedFields?.teardown(() => {
        braintree.hostedFields = null;
        logger.debug('Braintree Hosted Fields Teardown');
      });
    } catch (error) {
      logger.error('Braintree Hosted Fields Teardown Error', error);
    }

  if (braintree.threeDSecure && !braintree.threeDSecure.isTearingDown)
    try {
      braintree.threeDSecure.isTearingDown = true;
      braintree.threeDSecure?.teardown(() => {
        braintree.threeDSecure = null;
        logger.debug('Braintree 3D Secure Teardown');
      });
    } catch (error) {
      logger.error('Braintree 3D Secure Teardown Error', error);
    }
};
