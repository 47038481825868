import React, { useState } from 'react';

import { usePrivacyPolicy } from './usePrivacyPolicy';
import Template from './Template';

export const PrivacyPolicy: React.FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const { privacyContent } = usePrivacyPolicy(setLoaded);

  return <Template privacyContent={privacyContent} loaded={loaded} />;
};
