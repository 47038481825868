import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { API, api } from 'modules/api';
import { ModalType } from 'modules/modals/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';
import logger from 'modules/logger';

export const useErrorModal = () => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const history = useHistory();
  const active = useRef(true);

  //TODO done useCallback
  const getErrorMessage404 = useCallback(async () => {
    try {
      const res = await api(API.GET_ERROR_MESSAGE('404'));
      if (active.current) {
        return res.data;
      }
    } catch (error) {
      active.current && logger.debug('Get Error Message 404: ', error);
    }
  }, []);

  useEffect(() => {
    history.listen((_, action) => {
      if (action === 'POP') {
        !!modal.current && modalHide(modal.current);
      }
    });
  }, [history, history.action, modalHide]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  const displayModal = useCallback(
    (title: string, message?: string, code?: string) => {
      modal.current = modalShow({
        onClose: () => {
          !!modal.current && modalHide(modal.current);
          modal.current = null;
        },
        type: ModalType.ERROR,
        error: {
          title: title,
          message: message,
          code: code,
        },
      });
    },
    [modalHide, modalShow],
  );

  return [displayModal, getErrorMessage404] as const;
};
