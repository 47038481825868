export enum ModalAction {
  DESTROY = 'MODAL_DESTROY',
  INSERT = 'MODEL_INSERT',
  HIDE = 'MODAL_HIDE',
}

export enum ModalType {
  CONFIRM = 'confirm',
  CPD_CLAIM = 'cpd_claim',
  EMAIL_CHANGE = 'email_change',
  ERROR = 'error',
  FORM = 'form',
  INFO = 'info',
  LAPSED_USER = 'lapsed_user',
  PCERT_PASSPORT_ERROR = 'pcert_passport_error',
  ROLES = 'roles',
}

export enum ModalSize {
  NARROW = 'narrow',
  FULLWIDTH = 'fullwidth',
}
