import { QuestionType } from 'components/Question/Template/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  candidateId: yup
    .string()
    .required('Please enter your candidate number')
    .max(25, 'Candidate number must be at most 25 characters.'),
});

export type Model = {
  candidateId: string;
};

const candidateId: QuestionType<Model> = {
  name: 'candidateId',
};

export const model = {
  candidateId,
};

export const defaultValues = { candidateId: '' };
