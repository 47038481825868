import { useCallback, useEffect, useRef, useState } from 'react';

export const useHeadingsData = () => {
  const [headings, setHeadings] = useState<any[]>([]);
  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll('span > h2'));
    const newHeadings = getHeadings(headingElements);
    setHeadings(newHeadings);
  }, []);
  return { headings };
};

export const getHeadings = (headingElements: any) => {
  const headings: any[] = [];
  headingElements.forEach((heading: any) => {
    const { innerText: title, id } = heading;
    headings.push({ id, title });
  });
  return headings;
};

export const useHeight = () => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLElement>(null);

  const handleResize = useCallback(() => {
    ref.current && setHeight(ref.current.getBoundingClientRect().height);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return { height, ref };
};
