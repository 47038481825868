import { useEffect, useState } from 'react';

import { getNavMenu } from 'modules/utils/utils';
import { LoginTypeEnum } from 'modules/utils/types';
import { NavMenuItem } from 'components/Header/Menu';

export const useNavMenu = (loginTypeContext?: LoginTypeEnum) => {
  const [navLoaded, setNavLoaded] = useState(false);
  const [navMenu, setNavMenu] = useState<NavMenuItem[]>();

  useEffect(() => {
    if (loginTypeContext) {
      const data = getNavMenu(loginTypeContext);
      setNavMenu(data);
      setNavLoaded(true);
    }
  }, [loginTypeContext]);

  return { navMenu, navLoaded };
};
