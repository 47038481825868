import React from 'react';
import { BodyContentRelatedContent } from '../../types';
import styles from './styles.module.scss';
import * as Card from 'components/Card';

type RelatedContentPropType = {
  component: BodyContentRelatedContent;
};
export const RelatedContent: React.FunctionComponent<RelatedContentPropType> =
  ({ component }) => {
    return (
      <div className={styles['related-content']}>
        <h2>
          <span>{component.title ? component.title : 'Related Content'}</span>
        </h2>
        <p className={styles.description}>{component.description}</p>
        <Card.ThreeColumn data={component.posts} />
      </div>
    );
  };
