import React from 'react';
import styles from './styles.module.scss';

type HeaderProps = {
  image: any;
};

export const Header: React.FunctionComponent<HeaderProps> = ({ image }) => {
  const vimeo =
    image.video_type === 'Vimeo' &&
    image.vimeo_id !== null &&
    `https://player.vimeo.com/video/${image.vimeo_id}`;
  const youtube =
    image.video_type === 'Youtube' &&
    image.youtube_id !== null &&
    `https://www.youtube.com/embed/${image.youtube_id}`;
  const featuredImage = image?.featured_image?.url;
  return !image.use_video ? (
    <React.Fragment>
      {featuredImage && (
        <div
          aria-hidden="true"
          className={styles['header-image']}
          style={{
            backgroundImage: `url('${featuredImage}')`,
          }}
        ></div>
      )}
    </React.Fragment>
  ) : (
    <div className={styles['header-video']}>
      <div className={styles.iframeContainer}>
        {vimeo && <iframe src={vimeo} title={image.vimeo_id} />}
        {youtube && <iframe src={youtube} title={image.youtube_id} />}
      </div>
    </div>
  );
};
