import React from 'react';
import clsx from 'clsx';

import { LoginTypeEnum } from 'modules/utils/types';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

export type SwitchAccountItemProps = {
  handleChangeLoginType: (loginType: LoginTypeEnum) => void;
  label: string;
  loginTypeContext?: LoginTypeEnum;
  value: LoginTypeEnum;
};

const SwitchAccountItem: React.FunctionComponent<SwitchAccountItemProps> = ({
  handleChangeLoginType,
  label,
  loginTypeContext,
  value,
}) => {
  const classNames = clsx(
    styles.switchAccountItem,
    loginTypeContext === value && styles.selected,
  );

  return (
    <li className={classNames}>
      <Button.Generic
        label={label}
        onClick={() => handleChangeLoginType(value)}
      />
    </li>
  );
};

export default SwitchAccountItem;
