import { useEffect, useRef, useState } from 'react';

import { CookieBannerEnum } from './constants';

export const useCookieBanner = () => {
  const [lastCookie, setLastCookie] = useState('');
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const active = useRef(true);

  const PollCookies = setInterval(() => {
    if (active.current) {
      setLastCookie(document.cookie);
      const currentCookie: any = document.cookie;
      if (currentCookie !== lastCookie) {
        setLastCookie(currentCookie);
        if (currentCookie.includes(CookieBannerEnum.ACCEPTED)) {
          setShowCookieBanner(false);
          clearInterval(PollCookies);
        } else {
          setShowCookieBanner(true);
          clearInterval(PollCookies);
        }
      }
    }
  }, 1000);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return { showCookieBanner } as const;
};
