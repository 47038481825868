import { useCallback, useState } from 'react';
import $ from 'jquery';

export const useJSUtilities = () => {
  const [dataSourceFilteredItem, setDataSourceFilteredItem] =
    useState(undefined);

  const safariversion = useCallback(() => {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  }, []);

  const msieversion = useCallback(() => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    } else {
      return false;
    }
  }, []);

  const setCookieIE = useCallback((name, value, days, secure) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const hostName = window.document.domain;
      const adomain = hostName.substring(
        hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1,
      );
      // expires = '; expires=' + date.toGMTString() + '; domain=' + adomain + ';';
      expires = '; expires=' + date.toUTCString() + '; domain=' + adomain + ';';
    }
    if (secure) expires += expires === '' ? '; secure;' : ' secure;';

    document.cookie = name + '=' + value + expires + '; path=/';
  }, []);

  const getCookieIE = useCallback(name => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }, []);

  const getCookie = useCallback(
    name => {
      if (safariversion() || msieversion()) {
        return getCookieIE(name);
      } else {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + name + '=');
        if (parts.length === 2) return parts.pop()?.split(';').shift();
      }
    },
    [getCookieIE, msieversion, safariversion],
  );

  const createGuid = useCallback(() => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }, []);

  const getCacheBuster = useCallback(() => {
    return new Date().getTime();
  }, []);

  const find_in_object = useCallback((my_object, my_criteria) => {
    return my_object.filter(function (obj: any) {
      return Object.keys(my_criteria).every(function (c) {
        return obj[c] === my_criteria[c];
      });
    });
  }, []);

  const ArraySortByKey = useCallback((array, key) => {
    return array.sort(function (a: any, b: any) {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }, []);

  const findItemInDataSource = useCallback(
    (dataSource, key, value) => {
      //var data = dataSource instanceof kendo.data.HierarchicalDataSource && dataSource.data();
      let data;
      if (dataSource.data instanceof Function) data = dataSource.data();
      else data = dataSource.data;
      let itemFound = false;
      if (data)
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          const allocID = item[key];
          itemFound = allocID === value;

          if (itemFound) {
            setDataSourceFilteredItem(item);
          } else {
            if (item.children) findItemInDataSource(item.children, key, value);
          }
        }
      return dataSourceFilteredItem;
    },
    [dataSourceFilteredItem],
  );

  const scrollOnHover = useCallback(
    (containerDiv, scrollDownDiv, scrollUpDiv) => {
      let amount = '';

      function scroll() {
        containerDiv.animate(
          {
            scrollTop: amount,
          },
          100,
          'linear',
          function () {
            if (amount !== '') {
              scroll();
            }
          },
        );
      }
      scrollDownDiv.hover(
        function () {
          amount = '+=10';
          scroll();
        },
        function () {
          amount = '';
        },
      );
      scrollUpDiv.hover(
        function () {
          amount = '-=10';
          scroll();
        },
        function () {
          amount = '';
        },
      );
    },
    [],
  );

  const findItemsInDataSource = useCallback(
    (dataSource, key, itemsInDataSource?) => {
      //var data = dataSource instanceof kendo.data.HierarchicalDataSource && dataSource.data();
      let data;
      if (dataSource.data instanceof Function) data = dataSource.data();
      else data = dataSource.data;

      let itemFound = false;
      if (data)
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          const allocID = item[key];
          if (allocID) itemFound = true;

          if (itemFound) {
            itemsInDataSource.push(item);
          } else {
            if (item.children) findItemsInDataSource(item.children, key);
          }
        }
    },
    [],
  );

  const getURLparams = useCallback(() => {
    let match;
    const pl = /\+/g, // Regex for replacing addition symbol with a space
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s: any) {
        return decodeURIComponent(s.replace(pl, ' '));
      },
      query = window.location.search.substring(1);

    const urlParams: any = {};
    while ((match = search.exec(query)))
      urlParams[decode(match[1])] = decode(match[2]);
    return urlParams;
  }, []);

  const setCookie = useCallback(
    (name, value, days) => {
      const date = new Date();
      date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * parseInt(days));
      const hostName = window.document.domain;
      const adomain = hostName.substring(
        hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1,
      );
      const secureCookie = adomain.indexOf('localhost') === -1;
      if (safariversion() || msieversion())
        setCookieIE(name, value, days, secureCookie);
      else
        $.cookie(name, value, {
          path: '/',
          expires: date,
          domain: adomain,
          secure: secureCookie,
        });
      return;
    },
    [msieversion, safariversion, setCookieIE],
  );

  const deleteCookie = useCallback(name => {
    const adomain = window.document.domain.substring(
      window.document.domain.indexOf('.'),
    );

    // eslint-disable-next-line no-restricted-globals
    ($ as any).cookie(name, null, {
      path: '/',
      domain: adomain,
      expires: -1,
    });
  }, []);

  const selectKeyInGrid = useCallback(
    (grid, key, keyvalue) => {
      const dataItem = findItemInDataSource(grid.dataSource, key, keyvalue);
      grid.items().each(() => {
        const data = grid.dataItem();
        if (data[key] === keyvalue) {
          grid.select();
        }
      });
    },
    [findItemInDataSource],
  );

  // const treeview_ExpandOnHover = useCallback(tree => {
  //   tree.find('.k-item .k-in').each(() => {
  //     $().off('hover');
  //     $().hover(function () {
  //       tree.data('kendoTreeView').expand($(this));
  //     });
  //   });
  // }, []);

  return {
    safariversion,
    msieversion,
    setCookieIE,
    getCookieIE,
    setCookie,
    getCookie,
    deleteCookie,
    createGuid,
    getCacheBuster,
    find_in_object,
    ArraySortByKey,
    selectKeyInGrid,
    // treeview_ExpandOnHover,
    scrollOnHover,
    dataSourceFilteredItem,
    findItemInDataSource,
    findItemsInDataSource,
    getURLparams,
  };
};
