import React from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { FeaturedResourcesLayout } from '../constants';
import { ReactComponent as Liked } from 'modules/theme/icons/misc/card-like.svg';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent<{
  layout?: FeaturedResourcesLayout;
  showCta?: boolean;
}> = ({ layout, showCta }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 896px) and (min-width: 768px), (max-width: 576px)',
  });
  const className = clsx(
    styles.skeleton,
    layout === FeaturedResourcesLayout.ROW && styles.rows,
    layout === FeaturedResourcesLayout.ROW && styles.grid,
  );
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading featured resources
      </span>
      <div className={className} aria-hidden="true">
        <div>
          <span></span>
          {showCta && (
            <div>
              <span></span>
            </div>
          )}
        </div>
        <ul>
          <li>
            <div className={`${styles.skeletonItem} ${styles.primary}`}>
              <span>
                <span></span>
              </span>
              <div>
                <header>
                  <p className={styles.publication}>
                    <span></span>
                    <span></span>
                  </p>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                </header>
                <div className={styles.copy}>
                  <span></span>
                  {!isMobile && <p></p>}
                </div>
                <footer>
                  {isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <span></span>
                </footer>
              </div>
            </div>
          </li>
          <li>
            <div className={styles.skeletonItem}>
              <div>
                <header>
                  <p className={styles.publication}>
                    <span></span>
                    <span></span>
                  </p>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                </header>
                <div className={styles.copy}>
                  <span></span>
                  {!isMobile && <p></p>}
                </div>
                <footer>
                  {isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <span></span>
                </footer>
              </div>
            </div>
            <div className={styles.skeletonItem}>
              <div>
                <header>
                  <p className={styles.publication}>
                    <span></span>
                    <span></span>
                  </p>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                </header>
                <div className={styles.copy}>
                  <span></span>
                  {!isMobile && <p></p>}
                </div>
                <footer>
                  {isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <span></span>
                </footer>
              </div>
            </div>
            <div className={styles.skeletonItem}>
              <div>
                <header>
                  <p className={styles.publication}>
                    <span></span>
                    <span></span>
                  </p>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                </header>
                <div className={styles.copy}>
                  <span></span>
                  {!isMobile && <p></p>}
                </div>
                <footer>
                  {isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <span></span>
                </footer>
              </div>
            </div>
          </li>
        </ul>
        <div>
          <span></span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
