import { ContentTypesSlugs } from '../constants';
import { ReactComponent as BlogIcon } from 'modules/theme/icons/key-templates/blog.svg';
import { ReactComponent as CaseStudyIcon } from 'modules/theme/icons/key-templates/case-study.svg';
import { ReactComponent as DefaultIcon } from 'modules/theme/icons/key-templates/default.svg';
import { ReactComponent as GlossaryIcon } from 'modules/theme/icons/key-templates/glossary.svg';
import { ReactComponent as PracticeIcon } from 'modules/theme/icons/key-templates/practice.svg';
import { ReactComponent as ReportIcon } from 'modules/theme/icons/key-templates/report.svg';
import { ReactComponent as SamplePaperIcon } from 'modules/theme/icons/key-templates/sample-paper.svg';
import { ReactComponent as SyllabusIcon } from 'modules/theme/icons/key-templates/syllabus.svg';
import { ReactComponent as TemplateIcon } from 'modules/theme/icons/key-templates/template.svg';
import { ReactComponent as TrainingBusinessCaseIcon } from 'modules/theme/icons/key-templates/training-business-case.svg';
import { ReactComponent as TutorialIcon } from 'modules/theme/icons/key-templates/tutorial.svg';
import { ReactComponent as WebinarIcon } from 'modules/theme/icons/key-templates/webinar.svg';
import { ReactComponent as WhitePaperIcon } from 'modules/theme/icons/key-templates/white-paper.svg';

type KeyTemplatesItemIconProp = {
  contentType: string;
};

export const KeyTemplatesItemIcon: React.FunctionComponent<KeyTemplatesItemIconProp> =
  ({ contentType }) => {
    switch (contentType) {
      case ContentTypesSlugs.BLOG:
        return <BlogIcon />;
      case ContentTypesSlugs.CASE_STUDY:
        return <CaseStudyIcon />;
      case ContentTypesSlugs.GLOSSARY:
        return <GlossaryIcon />;
      case ContentTypesSlugs.PRACTICE:
        return <PracticeIcon />;
      case ContentTypesSlugs.REPORT:
        return <ReportIcon />;
      case ContentTypesSlugs.SAMPLE_PAPER:
        return <SamplePaperIcon />;
      case ContentTypesSlugs.SYLLABUS:
        return <SyllabusIcon />;
      case ContentTypesSlugs.TEMPLATE:
        return <TemplateIcon />;
      case ContentTypesSlugs.TRAINING_BUSINESS_CASE:
        return <TrainingBusinessCaseIcon />;
      case ContentTypesSlugs.TUTORIAL:
        return <TutorialIcon />;
      case ContentTypesSlugs.WEBINAR:
        return <WebinarIcon />;
      case ContentTypesSlugs.WHITE_PAPER:
        return <WhitePaperIcon />;
      default:
        return <DefaultIcon />;
    }
  };
