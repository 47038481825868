import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { useLoginToken } from 'modules/hooks';
import { PAGE_NO_PERMISSION_URL } from 'modules/utils/constants';
import { LoginTypeEnum } from 'modules/utils/types';
import { Badges } from 'pages';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { ProtectedRoutePropsType } from './types';

export const ProtectedRouteBadges: React.FunctionComponent<ProtectedRoutePropsType> =
  ({ path }) => {
    const { loginTypeContext } = useContext(LoginTypeContext);
    const { isLoggedIn } = useLoginToken();
    const { accountDataContext } = useContext(AccountDataContext);

    return (
      <Route path={path}>
        {loginTypeContext && accountDataContext && isLoggedIn() && (
          <React.Fragment>
            {loginTypeContext === LoginTypeEnum.INDIVIDUAL ? (
              <Badges />
            ) : (
              <Redirect to={PAGE_NO_PERMISSION_URL} />
            )}
          </React.Fragment>
        )}
      </Route>
    );
  };
