import React from 'react';
import clsx from 'clsx';

import {
  htmlSafe,
  hasStartAndEndTag,
  wrapTableTags,
  removeLeadingTrailingBrTag,
} from 'modules/utils/utils';
import { RichTextFont } from './constants';
import styles from './styles.module.scss';
import Skeleton from './Skeleton';

export type RichTextProps = {
  className?: string;
  content?: string;
  font?: RichTextFont;
  loaded?: boolean;
};

const RichText: React.FC<RichTextProps> = ({
  className,
  content = '',
  font = RichTextFont.PRIMARY,
  loaded = true,
}) => {
  const classNames = clsx(
    styles.richText,
    font === RichTextFont.SECONDARY && styles.secondary,
    className,
  );
  const tableSupport = wrapTableTags(removeLeadingTrailingBrTag(content));
  const renderContent = htmlSafe(tableSupport);
  const stripBrTags = removeLeadingTrailingBrTag(content);
  const safeContent = hasStartAndEndTag(stripBrTags) ? (
    renderContent
  ) : (
    <p>{htmlSafe(stripBrTags)}</p>
  );
  return loaded ? (
    <div className={classNames}>{safeContent}</div>
  ) : (
    <Skeleton />
  );
};

export default RichText;
