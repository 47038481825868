import React, { useContext } from 'react';
import clsx from 'clsx';

import { AccountDataType, LoginTypeEnum } from 'modules/utils/types';
import { BOOKMARKS_URL } from 'modules/utils/constants';
import { LoginTypeContext } from 'modules/context';
import { NavLink } from 'react-router-dom';
import { useProfile } from '../Account/hooks/useProfile';
import AccountMenu from '../Account/AccountMenuItems';

import accountStyles from '../Account/styles.module.scss';
import styles from './styles.module.scss';

export type NavMenuItem = {
  label: string;
  link: string;
};

type MenuProps = {
  accountDataContext?: AccountDataType;
  forwardedRef: React.RefObject<any>;
  handleCloseMenu: () => void;
  isLoggedIn: () => boolean;
  isMobile: boolean;
  menuAnimating?: boolean;
  navLinks?: NavMenuItem[];
  toggleMobileMenu: boolean;
};

const Menu: React.FunctionComponent<MenuProps> = ({
  accountDataContext,
  forwardedRef,
  handleCloseMenu,
  isLoggedIn,
  isMobile,
  menuAnimating,
  navLinks,
  toggleMobileMenu,
}) => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const { handleImageError, photo } = useProfile(accountDataContext);
  const className = clsx(
    styles.menu,
    toggleMobileMenu && styles.open,
    !toggleMobileMenu && styles.closed,
    menuAnimating && styles.animating,
  );
  return (
    <nav
      aria-label="Main"
      className={className}
      id="main-menu"
      ref={forwardedRef}
      role="navigation"
    >
      <ul>
        {navLinks?.map((item: NavMenuItem, index: number) => {
          return (
            <li key={`nav-menu-item-${index}`}>
              <NavLink
                exact
                onClick={handleCloseMenu}
                onMouseDown={e => e.preventDefault()}
                to={item.link}
              >
                <span aria-hidden="true" data-name={item.label}>
                  {item.label}
                </span>
                <span className="visuallyHidden">{item.label}</span>
              </NavLink>
            </li>
          );
        })}
        {isMobile && (
          <li>
            <NavLink
              exact
              onClick={handleCloseMenu}
              onMouseDown={e => e.preventDefault()}
              to={BOOKMARKS_URL}
            >
              <span aria-hidden="true" data-name="Bookmarks">
                Bookmarks
              </span>
              <span className="visuallyHidden">Bookmarks</span>
            </NavLink>
          </li>
        )}
        {isMobile && isLoggedIn() && (
          <React.Fragment>
            <li className={accountStyles.accountInfo}>
              <img alt="Profile" onError={handleImageError} src={photo} />
              <span>{accountDataContext?.name}</span>
              {accountDataContext?.company &&
                loginTypeContext === LoginTypeEnum.CONSULTANT && (
                  <span>{accountDataContext?.company}</span>
                )}
            </li>
            <AccountMenu
              accountDataContext={accountDataContext}
              handleCloseMenu={handleCloseMenu}
            />
          </React.Fragment>
        )}
      </ul>
    </nav>
  );
};

export default Menu;
