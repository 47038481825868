import yup, { ValdationMessages } from 'modules/validation';

const regex = new RegExp(
  "^[ a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ'`'-]+$",
);

export const validateName = (value: string): boolean => {
  return regex.test(value) ? true : false;
};

function validator(this: yup.StringSchema) {
  return this.test('name', ValdationMessages.NAME, function (value) {
    return value ? validateName(value) : true;
  });
}

export default validator;
