import { forIn, startsWith } from 'lodash';

import { PAGES_VIEWED_COOKIE_NAME } from './constants';

export const getTodayCookieName = (userId: number): string => {
  const todayD = new Date();
  const todayS = todayD.toISOString().split('T')[0];

  return `${PAGES_VIEWED_COOKIE_NAME}-${userId}-${todayS}`;
};

export const removeOldPagesViewedCookies = (userId: number): void => {
  const todayCookieName = getTodayCookieName(userId);

  forIn(window.localStorage, (_, objKey: string) => {
    if (
      objKey !== todayCookieName &&
      startsWith(objKey, `${PAGES_VIEWED_COOKIE_NAME}-${userId}`)
    ) {
      window.localStorage.removeItem(objKey);
    }
  });
};

export const contentIsNew = (
  lastLoginDate: string,
  publicationDate: string,
  pageUid: string,
  userId: number,
): boolean => {
  const lastLoginD = new Date(new Date(lastLoginDate).toDateString());
  const publicationD = new Date(new Date(publicationDate).toDateString());

  const todayCookieName = getTodayCookieName(userId);
  const pagesViewedStorage = localStorage.getItem(todayCookieName);
  let pagesViewed = [] as Array<string>;

  if (pagesViewedStorage) {
    pagesViewed = JSON.parse(pagesViewedStorage) as Array<string>;
  }
  return !pagesViewed.includes(pageUid) && publicationD >= lastLoginD;
};
