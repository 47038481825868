import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import _uniqueId from 'lodash/uniqueId';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { LoginTypeEnum } from 'modules/utils/types';
import logger from 'modules/logger';
import { API, api } from 'modules/api';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TriggerGTMEvent } from 'modules/gtm';
import { AccordionItemProps } from 'components/Accordion';
import { defaultValues, model, schema } from './model';
import { enquiryOptions } from './enquiryOptions';
import { enquiryOptionsConsultant } from './enquiryOptionsConsultant';
import { FAQListType, IFaqs } from './types';
import { useScrollToForm } from './hooks/useScrollToForm';
import Template from './Template';

function Faq() {
  const formRef = useRef<HTMLDivElement>(null);
  const { accountDataContext } = useContext(AccountDataContext);

  defaultValues.fullName = accountDataContext?.name || '';
  defaultValues.email = accountDataContext?.email || '';
  defaultValues.organizationName = accountDataContext?.company || '';
  defaultValues.candidateNumber =
    accountDataContext?.candidateNumbers
      ?.map(candidateNumber => ` ${candidateNumber}`)
      .toString()
      .substring(1) || '';
  defaultValues.jobTitle = accountDataContext?.jobTitle || '';

  const formProps = {
    defaultValues,
    formType: 'FAQMyAxelos',
    id: _uniqueId(),
    model,
    schema,
    title: 'MyAxelos FAQ Form',
  };
  const [faqsEntries, setFaqEntries] = useState<IFaqs>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  /* eslint-disable-next-line */
  const [recaptchaResponse, setRecaptchaResponse] = useState<object | null>(
    null,
  );
  const [enquiryOptionsArray, setEnquiryOptionsArray] =
    useState(enquiryOptions);

  const { loginTypeContext } = useContext(LoginTypeContext);

  const formType = formProps.formType;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const title = formProps.title;
  const id = formProps.id;

  useEffect(() => {
    //TODO done useEffect
    if (loginTypeContext) {
      let active = true;
      (async () => {
        try {
          const res = await api(
            API.GET_CONTENT_FROM_CONTENT_STACK(
              `get-faqs/${
                loginTypeContext !== undefined ? loginTypeContext : ''
              }`,
            ),
          );
          if (active) {
            setFaqEntries(res.data);
            setLoaded(true);

            let label = '';
            let action = '';
            if (loginTypeContext === LoginTypeEnum.CONSULTANT) {
              label = 'Help page visits (Consultants)';
              action = EventsActions.HELP_PAGE_VISITS_CONSULTANTS;
              setEnquiryOptionsArray(enquiryOptionsConsultant);
            } else if (loginTypeContext === LoginTypeEnum.INDIVIDUAL) {
              label = 'Help page visits (Individuals)';
              action = EventsActions.HELP_PAGE_VISITS_INDIVIDUALS;
            }

            TriggerGTMEvent({
              action: action,
              category: EventsCategories.PAGE_VIEWS,
              label: label,
            });
          }
        } catch (error) {
          active && logger.debug('Get FAQs Error: ', error);
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [loginTypeContext]);

  const getAccordionList = useMemo(() => {
    const faqsArray: AccordionItemProps[] = [];

    faqsEntries?.entries.forEach((entry: FAQListType) => {
      entry.faq_list.accordion_list.forEach(
        (accordionItem: AccordionItemProps) => {
          faqsArray.push(accordionItem);
        },
      );
    });

    return faqsArray;
  }, [faqsEntries]);

  const recaptchaLoaded = (value: string | null) => {
    if (value) {
      const obj = {
        'g-recaptcha-response': value,
      };
      setRecaptchaResponse(obj);
    }
  };

  const executeRecaptcha = () => {
    setIsSubmitting(true);
    recaptchaRef?.current?.reset();
    recaptchaRef?.current?.execute();
  };

  useScrollToForm(formRef?.current);

  return (
    <Template
      accountDataContext={accountDataContext}
      defaultValues={defaultValues}
      enquiryOptions={enquiryOptionsArray}
      executeRecaptcha={executeRecaptcha}
      faqsEntries={faqsEntries}
      formProps={formProps}
      formRef={formRef}
      formType={formType}
      getAccordionList={getAccordionList}
      id={id}
      isSubmitting={isSubmitting}
      loaded={loaded}
      model={model}
      recaptchaLoaded={recaptchaLoaded}
      recaptchaRef={recaptchaRef}
      recaptchaResponse={recaptchaResponse}
      setIsSubmitting={setIsSubmitting}
      title={title}
    />
  );
}

export default React.memo(Faq);
