import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { LoginTypeEnum } from 'modules/utils/types';
import { CONGRATULATIONS_PAGE_URL, SIGN_UP_URL } from 'modules/utils/constants';
import LogoIcons from './LogoIcons';

import styles from './styles.module.scss';

type LogoProps = {
  isLoggedIn: () => boolean;
  pathname: string;
  role?: LoginTypeEnum;
};

const Logo: React.FunctionComponent<LogoProps> = ({
  isLoggedIn,
  pathname,
  role,
}) => {
  const classNames = clsx(
    styles.logo,
    role === LoginTypeEnum.EMPLOYEE && styles.organization,
    role === LoginTypeEnum.CONSULTANT && styles.consultant,
  );
  return !isLoggedIn() &&
    (pathname === SIGN_UP_URL || pathname === CONGRATULATIONS_PAGE_URL) ? (
    <div aria-label="MyAxelos Logo" className={styles.logo}>
      <LogoIcons role={role} />
    </div>
  ) : (
    <NavLink
      aria-label="MyAxelos Dashboard"
      className={classNames}
      onMouseDown={e => e.preventDefault()}
      to="/"
      title="MyAxelos Dashboard"
    >
      <LogoIcons role={role} />
    </NavLink>
  );
};

export default React.memo(Logo);
