import React from 'react';

import { Publication } from './types';
import PublicationsItem from './PublicationsItem';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';

type PublicationsProp = {
  loaded: boolean;
  publications: Publication[];
  title: string;
};

const Publications: React.FunctionComponent<PublicationsProp> = ({
  loaded,
  publications,
  title,
}) => {
  return loaded ? (
    <section className={styles.publications}>
      <h3>{title || 'PUBLICATIONS'}</h3>
      <ul>
        {publications.map((publication: Publication, index: number) => (
          <li key={`publication-card-${index}`}>
            <PublicationsItem publication={publication} />
          </li>
        ))}
      </ul>
    </section>
  ) : (
    <Skeleton />
  );
};

export default Publications;
