import { useEffect, useRef, useState } from 'react';

import { API, api } from 'modules/api';
import { APIExceptions } from 'modules/api/exceptions';
import { ModalType } from 'modules/modals/constants';
import { SignUpStateType, SignUpStateGetResponse } from './types';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { useErrorModal } from 'modules/modals/hooks/useErrorModal';
import logger from 'modules/logger';
import useModalAction from 'modules/modals/hooks/useModalAction';

export const useSignUpState = (isLoggedIn: boolean, candidateToken: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionState, setSubscriptionState] = useState<SignUpStateType>();
  const [displayModal] = useErrorModal();
  const { modalShow } = useModalAction();
  const modal = useRef<string | null>(null);
  //TODO done useEffect
  useEffect(() => {
    const loggedOutState = {
      paymentMethods: [],
      subscriptionState: SubscriptionStateEnum.New_User,
      peopleCertPlan: null,
      isMyAxelosExistingUser: false,
    };
    const peopleCertPassport = candidateToken ?? '';
    if (!isLoggedIn && !candidateToken) {
      setSubscriptionState(loggedOutState);
      return;
    }
    let active = true;
    (async function () {
      try {
        setIsLoading(true);
        logger.debug('SignUp State - Init');
        const response: SignUpStateGetResponse = await api(
          API.POST_SUBSCRIPTION_STATE(peopleCertPassport),
        );
        if (active) {
          logger.debug('SignUp State - Success:', response);
          setSubscriptionState(response.data);
        }
      } catch (error: any) {
        if (active) {
          logger.error('SignUp State - Error');
          setSubscriptionState(loggedOutState);
          switch (error.response?.data?.type) {
            case APIExceptions.PeopleCertAuthenticationFailedException:
              return displayModal(
                `Oh my... There's a problem!`,
                `${error.response.data.message}. Please try again or contact us if the problem persists.`,
              );
            case APIExceptions.PeopleCertCandidateDemographicException:
              return (modal.current = modalShow({
                type: ModalType.PCERT_PASSPORT_ERROR,
                error: {
                  title: 'Oops, something went wrong',
                  message: 'Please contact our helpdesk: ',
                  email: 'membership@axelos.com',
                },
              }));
            default:
              return displayModal(
                `Oh my... There's a problem!`,
                `Please try again or contact us if the problem persists.`,
              );
          }
        }
      } finally {
        if (active) {
          setIsLoading(false);
          logger.debug('SignUp State - Finished');
        }
      }
    })();
    return () => {
      active = false;
    };
  }, [candidateToken, isLoggedIn, modalShow, displayModal]);

  return { isLoading, subscriptionState } as const;
};
