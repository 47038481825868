import React from 'react';

import { InputStringFormat } from '../constants';
import { InputStringProps } from '../types';
import InputPrimitive from '../Primitive';

const InputString: React.FunctionComponent<InputStringProps> = ({
  autoComplete,
  autoFocus,
  className,
  disabled,
  forwardedRef,
  format = InputStringFormat.TEXT,
  id,
  invalid,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  readOnly,
  style,
  size,
  tabIndex,
  type,
  value,
}) => {
  const getTabIndex = tabIndex ? tabIndex : 0;
  return (
    <InputPrimitive
      autoComplete={autoComplete}
      autoFocus={!disabled && autoFocus}
      className={className}
      disabled={disabled}
      format={format}
      forwardedRef={forwardedRef}
      id={id}
      invalid={invalid}
      label={label}
      maxLength={maxLength}
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onInput={onInput}
      onKeyDown={onKeyDown}
      readOnly={readOnly}
      style={style}
      size={size}
      tabIndex={disabled ? -1 : getTabIndex}
      value={value || ''}
      type={type}
    />
  );
};

export default React.memo(InputString);
