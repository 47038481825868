import React, { useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { AccountDataContext } from 'modules/context';
import { ButtonColour } from 'components/Button/constants';
import { contentIsNew } from 'modules/utils/newContentUtils';
import { getDateFormatted } from 'modules/utils/utils';
import { ReactComponent as Chevron } from 'modules/theme/icons/chevrons/chevron-right.svg';
import * as Hyperlink from 'components/Hyperlink';
import Social from 'components/Social';

import styles from './styles.module.scss';

export type ResourceHubItemProps = {
  bestPractices?: string[];
  bookmarked: boolean;
  buttonLabel: string;
  contentType?: string;
  handleBookmarkClick?: (contentTypeUid: string) => void;
  description?: string;
  image?: string;
  liked: boolean;
  likes: number;
  publicationDate: string;
  slug: string;
  readTimeText: string;
  timeToRead: string | number;
  title?: string;
  topics?: string[];
  uid: string;
};

const ResourceHubItem: React.FunctionComponent<ResourceHubItemProps> = ({
  bookmarked,
  buttonLabel,
  description,
  handleBookmarkClick,
  image,
  liked,
  likes,
  publicationDate,
  readTimeText,
  slug,
  timeToRead,
  title,
  uid,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 896px) and (min-width: 768px), (max-width: 576px)',
  });
  const { accountDataContext } = useContext(AccountDataContext);
  const contentNew = useMemo(() => {
    if (accountDataContext) {
      return contentIsNew(
        accountDataContext?.lastLogin,
        publicationDate,
        uid,
        accountDataContext.userId,
      );
    }
  }, [accountDataContext, publicationDate, uid]);

  const className = clsx(
    styles.contentTypeItem,
    contentNew && styles.isNewArticle,
  );

  return (
    <article className={className}>
      {image ? (
        <span
          style={{
            backgroundImage: `url('${image}?format=jpg&auto=webp?quality=60')`,
          }}
        >
          <Hyperlink.PageLink slug={slug}>
            <span></span>
          </Hyperlink.PageLink>
        </span>
      ) : (
        <Hyperlink.PageLink slug={slug}>
          <span className={styles.placeholderImage}></span>
        </Hyperlink.PageLink>
      )}
      <div>
        <header>
          {accountDataContext && contentNew && (
            <span className={styles.newIcon}>NEW</span>
          )}
          <p className={styles.publication}>
            <span>{getDateFormatted(publicationDate)}</span>
            <span>
              {timeToRead} min {readTimeText}
            </span>
          </p>
          {!isMobile && (
            <Social
              bookmarked={bookmarked}
              className={styles.social}
              contentTypeUid={uid}
              handleBookmarkClick={handleBookmarkClick}
              liked={liked}
              likes={likes}
              pageSlug={slug.split('/')?.slice(-1)[0]}
            />
          )}
        </header>
        {(title || description) && (
          <div className={styles.copy}>
            {!!title && (
              <h3>
                <Hyperlink.PageLink slug={slug}>{title}</Hyperlink.PageLink>
              </h3>
            )}
            {!!description && !isMobile && (
              <p>
                <Hyperlink.PageLink slug={slug}>
                  {description}
                </Hyperlink.PageLink>
              </p>
            )}
          </div>
        )}
        <footer>
          {isMobile && (
            <Social
              bookmarked={bookmarked}
              className={styles.social}
              contentTypeUid={uid}
              handleBookmarkClick={handleBookmarkClick}
              liked={liked}
              likes={likes}
              pageSlug={slug.split('/')?.slice(-1)[0]}
            />
          )}
          <Hyperlink.PageLink
            className={styles.hyperlink}
            label={buttonLabel}
            slug={slug}
            style={ButtonColour.BRAND_BERRY}
          >
            <Chevron />
          </Hyperlink.PageLink>
        </footer>
      </div>
    </article>
  );
};

export default ResourceHubItem;
