import React from 'react';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-down.svg';
import * as Button from 'components/Button';

type HeaderTypes = {
  handleClick: () => void;
  hasCandidateId?: boolean;
  open: boolean;
};

const Header: React.FunctionComponent<HeaderTypes> = ({
  handleClick,
  hasCandidateId,
  open,
}) => {
  return (
    <header>
      {hasCandidateId ? (
        <Button.Generic
          ariaControls="digital-badges"
          ariaExpanded={open}
          ariaLabel="Digital badges section"
          onClick={handleClick}
        >
          <h3>
            Your digital badges{' '}
            <span>
              <ChevronSVG />
            </span>
          </h3>
          <p>Let's claim your first digital badge!</p>
        </Button.Generic>
      ) : (
        <div>
          <h3>Start your path</h3>
          <p>Add a Candidate Number to claim your first digital badge.</p>
        </div>
      )}
    </header>
  );
};

export default React.memo(Header);
