import React, { useRef } from 'react';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { ModalType } from 'modules/modals/constants';
import {
  USER_ROLE_CONSULTANT,
  USER_ROLE_INDIVIDUAL,
} from 'modules/utils/constants';
import * as Button from 'components/Button';
import EmailForm from 'pages/account-management/components/AccountSettings/EmailForm';
import Fieldset from 'components/Fieldset';
import useModalAction from 'modules/modals/hooks/useModalAction';

import styles from './styles.module.scss';

const Modals: React.FunctionComponent = () => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);

  const showErrorModal = () => {
    modal.current = modalShow({
      onClose: () => {
        !!modal.current && modalHide(modal.current);
        modal.current = null;
      },
      type: ModalType.ERROR,
      error: {
        title: 'I am a title',
        message: 'I am a message',
        code: '404',
      },
    });
  };

  const showFormModal = () => {
    modal.current = modalShow({
      onCancel: () => {
        !!modal.current && modalHide(modal.current);
        modal.current = null;
      },
      renderContent: onCancel => (
        <EmailForm
          onCancel={onCancel}
          onSubmit={onCancel}
          changeEmailText={''}
        />
      ),
      title: 'I Am Title',
      type: ModalType.FORM,
    });
  };

  const showInfoModal = () => {
    modal.current = modalShow({
      onClose: () => {
        !!modal.current && modalHide(modal.current);
        modal.current = null;
      },
      title: `Error: 404`,
      text: 'There was an error trying to send your message. Please try again later.',
      type: ModalType.INFO,
    });
  };

  const showRolesModal = () => {
    modal.current = modalShow({
      onClick: () => {
        !!modal.current && modalHide(modal.current);
        modal.current = null;
      },
      type: ModalType.ROLES,
      userRoles: [
        {
          key: 1,
          label: 'Individual',
        },
        {
          key: 2,
          label: 'Consultant',
        },
      ],
      rolesDescription: [
        {
          descriptive_text:
            'Lorem ipsum dolor sit amet, eam esse bonorum sensibus ex',
          user_roles: [
            {
              role_name: USER_ROLE_INDIVIDUAL,
            },
          ],
        },
        {
          descriptive_text:
            'Lorem ipsum dolor sit amet, eam esse bonorum sensibus ex',
          user_roles: [
            {
              role_name: USER_ROLE_CONSULTANT,
            },
          ],
        },
      ],
    });
  };

  const showErrorModalTitleOnly = () => {
    modal.current = modalShow({
      onClose: () => {
        !!modal.current && modalHide(modal.current);
        modal.current = null;
      },
      type: ModalType.ERROR,
      error: {
        title: 'You do not have permission to view this page',
      },
    });
  };
  return (
    <Fieldset className={styles.modals} title="Modals">
      <div>
        <Button.Generic
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          label="Show Error Modal"
          onClick={showErrorModal}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          label="Show Form Modal"
          onClick={showFormModal}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          label="Show Info Modal"
          onClick={showInfoModal}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          label="Show Roles Modal"
          onClick={showRolesModal}
          small
          style={ButtonStyle.SECONDARY}
        />
        <Button.Generic
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          label="Show Error Modal Message Only"
          onClick={showErrorModalTitleOnly}
          small
          style={ButtonStyle.SECONDARY}
        />
      </div>
    </Fieldset>
  );
};

export default Modals;
