import React, { useCallback, useContext, useState } from 'react';
import { Control } from 'react-hook-form';
import clsx from 'clsx';

import { BraintreeFieldType } from './constants';
import { BraintreeHostedFields } from '../../types';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-down.svg';
import { ReactComponent as HelpSVG } from 'modules/theme/icons/form/help.svg';
import { QuestionType } from 'components/Question/Template/types';
import useBraintreeField from '../../hooks/useBraintreeField';
import PlatformContext from 'modules/platform/context';

import questionStyles from 'components/Question/Template/styles.module.scss';
import styles from './styles.module.scss';

export type BraintreeQuestionProps = {
  control: Control<any, any>;
  children?: React.ReactNode;
  help?: string | React.ReactNode;
  hostedFields: BraintreeHostedFields | null;
  id: string;
  isSubmitting?: boolean;
  label: string;
  question: QuestionType<any>;
  type?: BraintreeFieldType;
};

function BraintreeQuestion({
  control,
  children,
  help,
  hostedFields,
  id,
  isSubmitting,
  label,
  question,
  type = BraintreeFieldType.INPUT,
}: BraintreeQuestionProps) {
  const [showHelp, setShowHelp] = useState(false);
  const { isMobile } = useContext(PlatformContext);
  const { error, isEmpty, isFocused, isValid, ref } = useBraintreeField({
    control,
    hostedFields,
    name: question.name,
  });
  const classNames = clsx(
    styles.braintreeField,
    !isEmpty && styles.notEmpty,
    isFocused && styles.focused,
    !isValid && styles.invalid,
    isSubmitting && styles.isSubmitting,
    questionStyles.question,
    isMobile && showHelp && styles.showHelp,
    type === BraintreeFieldType.SELECT && styles.selectField,
  );
  const handleFocus = useCallback(
    () =>
      setTimeout(
        () =>
          !!hostedFields &&
          !hostedFields.isTearingDown &&
          hostedFields.focus(question.name as any),
      ),
    [hostedFields, question.name],
  );
  return (
    <div tabIndex={0} className={classNames} ref={ref} onFocus={handleFocus}>
      <div className={styles.field}>
        {help && (
          <>
            <HelpSVG onClick={() => setShowHelp(!showHelp)} />
            <div role="tooltip" aria-hidden="true">
              {help}
            </div>
          </>
        )}
        <div id={id}></div>
        {type === BraintreeFieldType.SELECT && <ChevronSVG />}
      </div>
      <span>{label}</span>
      {!isValid && <p aria-live="polite">{(error as any)?.message}</p>}
      {children}
    </div>
  );
}

export default React.memo(BraintreeQuestion) as typeof BraintreeQuestion;
