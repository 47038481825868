import React from 'react';
import { BodyContentHeading } from '../../types';
import styles from './styles.module.scss';

type ContentsHeadingPropType = {
  component: BodyContentHeading;
  id: number;
};

export const ContentsHeading: React.FC<ContentsHeadingPropType> = ({
  component,
  id,
}) => {
  const uid = `${id}-${component.title.replace(/\s/g, '-').toLowerCase()}`;
  return (
    <span className={styles['contents-heading']}>
      <h2 id={uid}>
        {id}.&nbsp;<span>{component.title}</span>
      </h2>
    </span>
  );
};
