import { useEffect, useState } from 'react';

import { API, api } from 'modules/api';
import { CongratulationsPageType } from 'modules/api/endpoints/congrats-page';
import logger from 'modules/logger';

export const useContentstack = () => {
  const [content, setContent] = useState<CongratulationsPageType>();

  useEffect(() => {
    //TODO done useEffect
    let active = true;
    (async () => {
      try {
        const res = await api(API.GET_CONGRATS_PAGE());
        active && setContent(res.data);
      } catch (error) {
        active && logger.error('Error getting congrats page', error);
      }
    })();
    return () => {
      active = false;
    };
  }, []);

  return { content } as const;
};
