import { useCallback, useEffect, useRef, useState } from 'react';

import {
  BraintreeHostedFields,
  BraintreeHostedFieldsTokenizePayload,
} from '../types';
import { BraintreeErrorType } from '../constants';
import { getBillingAddress } from '../utils';
import logger from 'modules/logger';

function useBraintreeHostedFields<T>(
  hostedFields: BraintreeHostedFields | null,
): {
  handleHostedFieldsSubmit: (
    data: T,
  ) => Promise<BraintreeHostedFieldsTokenizePayload>;
  isHostedFieldsSubmitting: boolean;
} {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const active = useRef(true);

  //TODO done useCallback

  const handleSubmit = useCallback(
    async data => {
      try {
        setIsSubmitting(true);
        const result = await hostedFields?.tokenize({
          billingAddress: getBillingAddress(data),
        });
        if (active.current && result) {
          return result;
        } else {
          throw new Error(BraintreeErrorType.HOSTED_FIELDS_FAILED_TOKENIZATION);
        }
      } catch (error) {
        active.current &&
          logger.error('Braintree Hosted Fields Submit Error', error);

        throw error;
      } finally {
        active.current && setIsSubmitting(false);
      }
    },
    [hostedFields],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    handleHostedFieldsSubmit: handleSubmit,
    isHostedFieldsSubmitting: isSubmitting,
  };
}

export default useBraintreeHostedFields;
