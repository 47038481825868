import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as Liked } from 'modules/theme/icons/misc/card-like.svg';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 896px) and (min-width: 768px), (max-width: 576px)',
  });
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading Resource Hub
      </span>
      <div className={styles.skeleton} aria-hidden="true">
        <ul>
          <li>
            <div className={styles.skeletonItem}>
              <span></span>
              <div>
                <header>
                  <p className={styles.publication}>
                    <span></span>
                    <span></span>
                  </p>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                </header>
                <div className={styles.copy}>
                  <span className={styles.headingThree}></span>
                  {!isMobile && <p></p>}
                </div>
                <footer>
                  {isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <span className={styles.hyperlink}></span>
                </footer>
              </div>
            </div>
            <div className={styles.skeletonItem}>
              <span></span>
              <div>
                <header>
                  <p className={styles.publication}>
                    <span></span>
                    <span></span>
                  </p>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                </header>
                <div className={styles.copy}>
                  <span className={styles.headingThree}></span>
                  {!isMobile && <p></p>}
                </div>
                <footer>
                  {isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <span className={styles.hyperlink}></span>
                </footer>
              </div>
            </div>
            <div className={styles.skeletonItem}>
              <span></span>
              <div>
                <header>
                  <p className={styles.publication}>
                    <span></span>
                    <span></span>
                  </p>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                </header>
                <div className={styles.copy}>
                  <span className={styles.headingThree}></span>
                  {!isMobile && <p></p>}
                </div>
                <footer>
                  {isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <span className={styles.hyperlink}></span>
                </footer>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
