import React, { useContext, useState } from 'react';

import { AccountLoadedType } from './types';
import { LoginTypeContext } from 'modules/context';
import { useNewsletterPreferences } from './components/EmailSettings/EmailPreferenceToggle/hooks/useNewsletterPreferences';
import { usePaymentHistory } from './components/PaymentHistory/usePaymentHistory';
import { useSubscriptions } from './components/Subscriptions/Subscription/useSubscriptions';
import Template from './Template';
import usePaymentInformation from './components/Subscriptions/PaymentInformation/hooks';

export const AccountManagement: React.FunctionComponent = () => {
  const { loginTypeContext } = useContext(LoginTypeContext);
  const [skeletonLoaded, setSkeletonLoaded] = useState<AccountLoadedType>({
    newsletter: false,
    payments: false,
    paymentHistory: false,
    subscriptions: false,
  });
  const { paymentHistoryList, paymentYears } = usePaymentHistory(
    setSkeletonLoaded,
    loginTypeContext,
  );
  const { subscriptions } = useSubscriptions(
    setSkeletonLoaded,
    loginTypeContext,
  );
  const { form } = useNewsletterPreferences(
    setSkeletonLoaded,
    loginTypeContext,
  );

  const {
    handleAddPaymentMethod,
    handleEditPaymentMethod,
    handleRemovePaymentMethod,
    paymentMethods,
  } = usePaymentInformation(setSkeletonLoaded);

  return (
    <Template
      form={form}
      handleAddPaymentMethod={handleAddPaymentMethod}
      handleEditPaymentMethod={handleEditPaymentMethod}
      handleRemovePaymentMethod={handleRemovePaymentMethod}
      loaded={skeletonLoaded}
      loginTypeContext={loginTypeContext}
      paymentHistoryList={paymentHistoryList}
      paymentMethods={paymentMethods}
      paymentYears={paymentYears}
      subscriptions={subscriptions}
    />
  );
};
