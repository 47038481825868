import React from 'react';

import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-right.svg';
import * as Hyperlink from 'components/Hyperlink';

import styles from './styles.module.scss';

type BackType = {
  href: string;
};

const Back: React.FC<BackType> = ({ href }) => {
  return (
    <Hyperlink.CustomLink
      className={styles.back}
      label="Back to plan page"
      href={href}
    >
      <ChevronSVG />
    </Hyperlink.CustomLink>
  );
};
export default React.memo(Back);
