import { useCallback, useEffect, useRef } from 'react';

type UseHandleMouseDownProps = () => (
  event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
) => void;

const useHandleMouseDown: UseHandleMouseDownProps = () => {
  const handleMouseDownRef = useRef(
    (_event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      // Do something
    },
  );
  const handleMouseDown = useCallback(
    event => handleMouseDownRef.current(event),
    [],
  );

  useEffect(() => {
    handleMouseDownRef.current = event => {
      event.preventDefault();
    };
  }, []);

  return handleMouseDown;
};

export default useHandleMouseDown;
