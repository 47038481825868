import React from 'react';
import clsx from 'clsx';

import { useMediaQuery } from 'react-responsive';
import purchaseSumamry from '../PurchaseSummary/styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });
  const classNames = clsx(styles.skeleton);
  const classTotal = clsx(purchaseSumamry.total, styles.total);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading signup page
      </span>
      <div className={classNames} aria-hidden="true">
        {isMobileBreakpoint && <p className={styles.back}></p>}
        <h2 className={styles.title}>
          <p></p>
        </h2>
        <div className={styles.form}>
          <div className={styles.input}></div>
          <div className={styles.input}></div>
          <div className={styles.input}></div>
          <div className={styles.input}></div>
          <div className={styles.input}></div>
          <div className={styles.input}></div>
          <small className={styles.recaptcha}></small>
        </div>
        {isMobileBreakpoint && (
          <div className={purchaseSumamry.purchaseSummary}>
            <h3>
              <span></span>
            </h3>
            <span className={styles.productName}></span>
            <span className={styles.bundle}></span>
            <span className={styles.tax}></span>
            <span className={classTotal}></span>
          </div>
        )}
        <div className={styles.cta}>
          <p></p>
          <div></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Skeleton);
