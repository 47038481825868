import { SetStateAction, useEffect, useState } from 'react';

import { AccountDataType } from 'modules/utils/types';
import { CuratedLoadedType, CuratedPageMMType } from '../types';
import { MaturityModelCtaType } from 'components/MaturityModels/types';

export const useMaturityModelsCuratedPage = ({
  accountDataContext,
  mm_section,
  setLoaded,
}: {
  accountDataContext?: AccountDataType;
  mm_section?: CuratedPageMMType;
  setLoaded: React.Dispatch<SetStateAction<CuratedLoadedType>>;
}) => {
  const [mmCta, setMmCta] = useState<MaturityModelCtaType>({
    itil: {
      label: '',
      link: '',
      title: '',
      description: '',
    },
    p3m3: {
      label: '',
      link: '',
      title: '',
      description: '',
    },
  });

  useEffect(() => {
    if (accountDataContext) {
      setLoaded((prevState: CuratedLoadedType) => ({
        ...prevState,
        maturityModels: true,
      }));

      if (mm_section?.p3m3?.display_card) {
        setMmCta(prevState => ({
          ...prevState,
          p3m3: {
            ...prevState.p3m3,
            label: 'Access the tool',
            link: mm_section?.p3m3?.custom_link,
            title: mm_section?.p3m3?.title,
            description: mm_section?.p3m3?.description,
          },
        }));
      }

      if (mm_section?.itil?.display_card) {
        setMmCta(prevState => ({
          ...prevState,
          itil: {
            ...prevState.itil,
            label: 'Access the tool',
            link: mm_section?.itil?.custom_link,
            title: mm_section?.itil?.title,
            description: mm_section?.itil?.description,
          },
        }));
      }
    }
  }, [accountDataContext, mm_section, setLoaded]);

  return { mmCta } as const;
};
