import { useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { HELP_URL } from 'modules/utils/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { api, API } from 'modules/api';
import { ModalType } from 'modules/modals/constants';

export const useCancelSubscriptionHelp = () => {
  const history = useHistory();
  const scrollTo = () => {
    history.push({
      pathname: HELP_URL,
      state: {
        scrollToForm: true,
      },
    });
  };

  return { scrollTo } as const;
};

export const useCancelSubscription = () => {
  const successModal = useRef<string | null>(null);
  const errorModal = useRef<string | null>(null);
  const { modalShow, modalHide } = useModalAction();

  const cancelSubscription = useCallback(
    async (userId: string) => {
      try {
        if (userId === '') {
          throw new Error();
        }
        await api(API.POST_CANCEL_SUBSCRIPTION(`userid=${userId}`)).then(
          res => {
            if (res.status === 200) {
              successModal.current = modalShow({
                onClose: () => {
                  !!successModal.current && modalHide(successModal.current);
                  successModal.current = null;
                },
                title: 'Subscription cancelled',
                text: 'Your subscription has been cancelled successfully!',
                type: ModalType.INFO,
              });
            }
          },
        );
      } catch (error: any) {
        if (!errorModal.current) {
          errorModal.current = modalShow({
            onClose: () => {
              !!errorModal.current && modalHide(errorModal.current);
              errorModal.current = null;
            },
            title: `Error: ${error.response?.statusText}`,
            text: 'There was an error while trying to cancel your subscription. Please try again later.',
            type: ModalType.INFO,
          });
        }
      }
    },
    [modalHide, modalShow],
  );
  return { cancelSubscription } as const;
};
