import React from 'react';

import { useRecaptcha } from 'modules/recaptcha/hooks';

type RecaptchaType = {
  id: string;
  setGrecaptchaLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setGrecaptchaWidget: React.Dispatch<any>;
  successCallback: (response: string) => any;
};

const Recaptcha: React.FunctionComponent<RecaptchaType> = ({
  id,
  setGrecaptchaLoaded,
  setGrecaptchaWidget,
  successCallback,
}) => {
  const { recaptchaLoaded, recaptchaWidget }: any = useRecaptcha({
    containerId: id,
    successCallback,
    sitekey: process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY,
    size: 'invisible',
  });

  setGrecaptchaLoaded(recaptchaLoaded);
  setGrecaptchaWidget(recaptchaWidget);

  return <React.Fragment></React.Fragment>;
};

export default Recaptcha;
