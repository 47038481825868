import { useContext } from 'react';

import { AccountDataContext } from 'modules/context';

import styles from './styles.module.scss';

const Organisation: React.FunctionComponent = () => {
  const { accountDataContext } = useContext(AccountDataContext);

  return (
    <div className={styles.organisation}>{accountDataContext?.company}</div>
  );
};

export default Organisation;
