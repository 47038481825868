import React from 'react';
import clsx from 'clsx';

import * as Button from 'components/Button';

import styles from './styles.module.scss';

type BurgerMenuButtonProps = {
  handleMenuToggle: () => void;
  toggleMobileMenu: boolean;
};

const BurgerMenuButton: React.FC<BurgerMenuButtonProps> = ({
  handleMenuToggle,
  toggleMobileMenu,
}) => {
  const className = clsx(
    styles.burgerMenuButton,
    toggleMobileMenu && styles.activeMobileMenu,
  );

  return (
    <Button.Generic
      ariaControls="main-menu"
      ariaExpanded={toggleMobileMenu}
      ariaLabel="Main Menu"
      className={className}
      onClick={handleMenuToggle}
      title="Main Menu"
    >
      <span></span>
    </Button.Generic>
  );
};

export default BurgerMenuButton;
