import clsx from 'clsx';
import React from 'react';

import richTextStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const className = clsx(styles.skeleton, richTextStyles.richText);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading content
      </span>
      <div className={className} aria-hidden="true">
        <span></span>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
