import React, { useContext } from 'react';
import clsx from 'clsx';

import { AccountDataContext } from 'modules/context';
import { ArrowSpinnerColour } from 'components/Spinner/constants';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { isP3M3 } from 'modules/utils/utils';
import { ReactComponent as P3M3Svg } from 'modules/theme/ims/P3M3-logo.svg';
import { TriggerGTMEvent } from 'modules/gtm';
import { useMaturityModelsAccess } from './useMaturityModelsAccess';
import * as Button from 'components/Button';
import CtaButton from 'components/Cta/Button';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

type P3M3Props = {
  CTALabel?: string;
  CTALink?: string;
  description?: string;
  isDashboard: boolean;
  title?: string;
};

const P3M3: React.FunctionComponent<P3M3Props> = ({
  CTALabel,
  CTALink,
  description,
  isDashboard,
  title,
}) => {
  const { redirectToMMPlatform, gettingMMAccess } = useMaturityModelsAccess();
  const classNames = clsx(
    styles.maturityModelItem,
    styles.p3m3,
    gettingMMAccess && styles.loading,
  );
  const { accountDataContext } = useContext(AccountDataContext);

  const handleClick = () => {
    TriggerGTMEvent({
      action: EventsActions.CLICK_VISIT_MM_P3M3,
      category: EventsCategories.MATURITY_MODEL,
      label: '',
    });
    if (!isP3M3(accountDataContext)) {
      redirectToMMPlatform();
    }
  };
  return (
    <section className={classNames}>
      <h3>{title || 'Axelos maturity model application'}</h3>
      <p>
        {description ||
          "Carry out assessments easily, view your consultancy's activity, request certifications and view reports."}
      </p>
      <div>
        <P3M3Svg />
        <div>
          {!isP3M3(accountDataContext) && isDashboard ? (
            <Button.Generic
              ariaLabel={CTALabel}
              colour={ButtonColour.BRAND_BLACK_GRAPE}
              disabled={gettingMMAccess}
              label={CTALabel}
              onClick={handleClick}
              small
              style={ButtonStyle.PRIMARY}
            >
              {gettingMMAccess && (
                <Spinner
                  background={false}
                  className={styles.spinner}
                  colour={ArrowSpinnerColour.WHITE}
                />
              )}
            </Button.Generic>
          ) : (
            <CtaButton
              custom_url={CTALink}
              defaultColour={ButtonColour.BRAND_BLACK_GRAPE}
              label={CTALabel}
              onClick={handleClick}
              style={ButtonStyle.PRIMARY}
              small
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default P3M3;
