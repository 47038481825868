import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ACCOUNT_MANAGEMENT_URL } from 'modules/utils/constants';
import { NotificationAction } from 'pages/home/components/Notifications/constants';

export const useOpenSubscriptions = () => {
  const history = useHistory();

  const openSubscriptions = useCallback(() => {
    history.push({
      pathname: ACCOUNT_MANAGEMENT_URL,
      state: {
        actionPrompt: NotificationAction.VIEW_SUBSCRIPTIONS,
      },
    });
  }, [history]);

  return { openSubscriptions } as const;
};
