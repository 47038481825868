import React from 'react';
import clsx from 'clsx';
import { RefCallBack } from 'react-hook-form';

import { InputSize } from 'components/Inputs/Input/constants';
import { htmlSafe } from 'modules/utils/utils';

import styles from './styles.module.scss';

type LabelProps = {
  children?: React.ReactNode;
  id?: string;
  hasStickyLabel?: boolean;
  isSticky?: boolean;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLLabelElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLLabelElement>) => void;
  ref?: RefCallBack | React.MutableRefObject<HTMLLabelElement | null>;
  size?: InputSize;
};

const Label: React.FC<LabelProps> = ({
  children,
  id,
  hasStickyLabel,
  isSticky,
  label,
  onClick,
  onKeyDown,
  ref,
  size = InputSize.REGULAR,
}) => {
  const className = clsx(
    styles.label,
    hasStickyLabel && styles.stickyLabel,
    isSticky && styles.isSticky,
    size === InputSize.SMALL && styles.small,
    size === InputSize.EXTRA_SMALL && styles['extra-small'],
  );
  return (
    <label
      htmlFor={id}
      className={className}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseDown={onKeyDown ? event => event.preventDefault() : undefined}
      ref={ref}
      tabIndex={onKeyDown ? 0 : -1}
    >
      {htmlSafe(label)}
      {children}
    </label>
  );
};

export default React.memo(Label);
