import React from 'react';

import { ButtonTarget } from 'components/Button/constants';
import {
  AXELOS_CONTACT_URL,
  AXELOS_HOMEPAGE_URL,
  HELP_URL,
  LOGIN_URL,
} from 'modules/utils/constants';
import { useLoginToken } from 'modules/hooks';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

const Footer: React.FunctionComponent = () => {
  const { isLoggedIn } = useLoginToken();
  return (
    <footer className={styles.footer}>
      <nav>
        <ul>
          {!isLoggedIn() && (
            <li>
              <Button.CustomLink href={LOGIN_URL} label="Login" />
            </li>
          )}
          <li>
            <Button.CustomLink
              href={AXELOS_HOMEPAGE_URL}
              label="Axelos.com"
              target={ButtonTarget.BLANK}
            />
          </li>
          <li>
            <Button.CustomLink
              href={isLoggedIn() ? HELP_URL : AXELOS_CONTACT_URL}
              label="Contact us"
              target={ButtonTarget.BLANK}
            />
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default React.memo(Footer);
