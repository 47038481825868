//import { useLayoutEffect } from 'react';
//import { useLocation } from 'react-router-dom';

// export default function ScrollToTop() {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);
//   return null;
// }

import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function ScrollToTop() {
  const history = useHistory();
  useLayoutEffect(() => {
    const unlisten = history.listen(
      (_, action) => action !== 'POP' && window.scrollTo(0, 0),
    );
    return () => unlisten();
  }, [history]);
  return null;
}

//useEffect: render components -> paint to screen -> scroll to top (run effect)
//useLayoutEffect: render components -> scroll to top (run effect) -> paint to screen
