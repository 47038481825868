import React from 'react';
import clsx from 'clsx';

import { BraintreeCardType } from 'modules/braintree/constants';
import { getCardTitle } from 'modules/braintree/utils';
import BraintreeIcon from 'modules/braintree/icon';

import styles from './styles.module.scss';

type PaymentMethodItemProps = {
  cardNumber: string;
  cardType: BraintreeCardType;
  defaultCard: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onClick: () => void;
  selected: boolean;
};

const PaymentMethodItem: React.FunctionComponent<PaymentMethodItemProps> = ({
  cardNumber,
  cardType,
  defaultCard,
  disabled,
  invalid,
  onClick,
  selected,
}) => {
  const className = clsx(
    styles.questionPaymentMethodItem,
    disabled && styles.disabled,
    !disabled && invalid && styles.invalid,
    selected && styles.selected,
  );
  return (
    <div className={className} onClick={onClick}>
      <BraintreeIcon type={cardType} />
      <h4>
        {getCardTitle(cardType)} <strong>{cardNumber}</strong>
        {defaultCard && <span> - Primary</span>}
      </h4>
    </div>
  );
};

export default React.memo(PaymentMethodItem);
