import React, { useCallback, useRef } from 'react';

import { api, API } from 'modules/api';
import { getBillingAddress } from 'modules/braintree/utils';
import { ModalType } from 'modules/modals/constants';
import { setPaymentMethodEdited } from './utils';
import { Model } from '../EditCardForm/model';
import { PaymentInfoType } from '../types';
import EditCardForm from '../EditCardForm';
import logger from 'modules/logger';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { useEffect } from 'react';

type UseHandleEditPaymentMethod = {
  modal: React.MutableRefObject<string | null>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentMethods: React.Dispatch<React.SetStateAction<PaymentInfoType[]>>;
};

function useEditPaymentMethod({
  modal,
  setLoading,
  setPaymentMethods,
}: UseHandleEditPaymentMethod) {
  const { modalShow, modalHide } = useModalAction();
  const active = useRef(true);

  const handleCancel = useCallback(() => {
    !!modal.current && modalHide(modal.current);
    modal.current = null;
  }, [modal, modalHide]);
  //TODO done useCallback
  const handleSubmit = useCallback(
    async (data: Model, paymentMethodId) => {
      try {
        const response = await api(
          API.EDIT_PAYMENT_METHOD({
            ...getBillingAddress(data),
            makeDefault: data.makeDefault,
            paymentMethodId: paymentMethodId,
          }),
        );
        if (active.current) {
          setPaymentMethodEdited(
            response.data.paymentMethod,
            setPaymentMethods,
          );
          !!modal.current && modalHide(modal.current);
          modal.current = null;
          setLoading(true);
        }
      } catch (error) {
        active.current && logger.debug('Handle Cancel Error: ', error);
      }
    },
    [modal, modalHide, setLoading, setPaymentMethods],
  );

  const handleEditPaymentMethod = useCallback(
    (paymentMethod: PaymentInfoType) => {
      if (!modal.current) {
        modal.current = modalShow({
          onCancel: handleCancel,
          renderContent: onCancel => (
            <EditCardForm
              onCancel={onCancel}
              onSubmit={(data: Model) =>
                handleSubmit(data, paymentMethod.paymentMethodId)
              }
              paymentMethod={paymentMethod}
            />
          ),
          title: 'Edit Credit / Debit card',
          type: ModalType.FORM,
        });
      }
    },
    [handleCancel, handleSubmit, modal, modalShow],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return handleEditPaymentMethod;
}

export default useEditPaymentMethod;
