import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { LayoutVerticalPadding } from 'components/Layout/constants';
import { SubscribeFormSteps } from '../SubscribeWithPaymentMethod/hooks';
import { SubscribeType } from '../types';
import * as FormControl from '../FormControl';
import * as Layout from 'components/Layout';
import CountryOfResidenceQuestions from './CountryOfResidenceQuestions';
import Footer from '../Footer';
import PageHeading from '../PageHeading';
import PaymentQuestions from './PaymentQuestions';
import PurchaseSummary from '../PurchaseSummary';
import useSubscribe from './hooks';
import VoucherCode from '../VoucherCode';
import Skeleton from '../Skeleton';
import styles from 'pages/sign-up/components/styles.module.scss';

const Subscribe: React.FunctionComponent<SubscribeType> = ({
  countryCode,
  loading,
  pageInfo,
  productInfo,
  requiresCountry,
  subscriptionPlan,
  subscriptionState,
  voucherCode,
}) => {
  const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });
  const {
    activeStep,
    defaultValues,
    handleBack,
    handleNext,
    handleSubmitStep2,
    isSubmitting,
  } = useSubscribe({
    requiresCountry,
    subscriptionPlan,
    subscriptionState,
  });

  return (
    <Layout.Container className={styles.signUpPage}>
      <Layout.Content
        className={styles.signUpContent}
        verticalPadding={LayoutVerticalPadding.LARGE}
      >
        {!loading ? (
          <React.Fragment>
            <PageHeading {...pageInfo} />
            {activeStep === SubscribeFormSteps.Country && (
              <CountryOfResidenceQuestions
                countryCode={countryCode}
                defaultValues={defaultValues}
                handleSubmit={handleNext}
                id="Residence"
                isSubmitting={isSubmitting}
                productInfo={productInfo}
                voucherCode={voucherCode}
              />
            )}
            {activeStep !== SubscribeFormSteps.Country && (
              <PaymentQuestions
                defaultValues={defaultValues}
                handleSubmit={handleSubmitStep2}
                handleBack={requiresCountry ? handleBack : undefined}
                isSubmitting={isSubmitting}
                productInfo={productInfo}
                voucherCode={voucherCode}
                formControl={
                  requiresCountry
                    ? FormControl.PaymentDetails
                    : FormControl.Subscribe
                }
              />
            )}
          </React.Fragment>
        ) : (
          <Skeleton />
        )}
        <Footer />
      </Layout.Content>
      {!isMobileBreakpoint && (
        <Layout.Sidebar
          className={styles.signUpSidebar}
          verticalPadding={LayoutVerticalPadding.LARGE}
        >
          <PurchaseSummary
            showBackLink={false}
            {...productInfo}
            loading={loading}
          >
            <VoucherCode {...voucherCode} isSubmitting={isSubmitting} />
          </PurchaseSummary>
        </Layout.Sidebar>
      )}
    </Layout.Container>
  );
};

export default React.memo(Subscribe);
