import React from 'react';
import clsx from 'clsx';

import publicationItemStyles from '../PublicationsItem/styles.module.scss';
import publicationStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(styles.skeleton, publicationStyles.publications);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading publications
      </span>
      <section className={classNames} aria-hidden="true">
        <p></p>
        <ul>
          <li>
            <div className={publicationItemStyles.publicationsItem}>
              <div>
                <div className={styles.image}></div>
                <p></p>
                <span></span>
              </div>
              <div>
                <div>
                  <p></p>
                  <span></span>
                </div>
                <span></span>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </React.Fragment>
  );
};

export default Skeleton;
