import React from 'react';
import styles from './styles.module.scss';
import { htmlSafe } from 'modules/utils/utils';

export type excerptProps = {
  excerpt: string;
  safe?: boolean;
};

const Excerpt: React.FunctionComponent<excerptProps> = ({ excerpt, safe }) => {
  const intro = htmlSafe(excerpt);
  return safe ? <>{intro}</> : <p className={styles.excerpt}>{excerpt}</p>;
};

export default Excerpt;
