import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { AccountDataContext, LoginTypeContext } from 'modules/context';
import { ChangeEmailVerify } from 'pages';
import { ProtectedRoutePropsType } from './types';
import { useLoginToken } from 'modules/hooks';

export const ProtectedRouteChangeEmailVerify: React.FunctionComponent<ProtectedRoutePropsType> =
  ({ path }) => {
    const { loginTypeContext } = useContext(LoginTypeContext);
    const { isLoggedIn } = useLoginToken();
    const { accountDataContext } = useContext(AccountDataContext);

    return (
      <Route path={path}>
        {loginTypeContext && accountDataContext && isLoggedIn() && (
          <ChangeEmailVerify />
        )}
      </Route>
    );
  };
