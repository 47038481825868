import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { ALL_FILTERS, ALL_FILTERS_ELEMENT } from 'modules/utils/constants';
import { filterObjectType } from 'pages/resource-hub/types';

export const useSelectedFiltersURL = (slug: string) => {
  const [refreshResults, setRefreshResults] = useState(false);

  const getItemURL = useCallback((selectedItems: filterObjectType[]) => {
    let newURL = '';
    newURL = selectedItems
      .map((item: filterObjectType) => {
        return item.slug;
      })
      .toString();
    return newURL;
  }, []);

  const addDeleteFilterItem = useCallback(
    (
      item: filterObjectType,
      setState: Dispatch<SetStateAction<filterObjectType[]>>,
      itemsSelected: filterObjectType[],
      setItemURL: (url: string) => void,
    ) => {
      const indexOfItem = itemsSelected.findIndex(
        (element: filterObjectType) => element.title === item.title,
      );
      if (indexOfItem === -1) {
        //if the item wasn't present, we add it

        if (item.title === ALL_FILTERS) {
          //if the user clicks All, we delete the rest of the items
          setState([item]);
          setItemURL('');
        } else {
          //we add the item selected, but also we remove All if it was present

          const newItemsSelected = [
            ...itemsSelected.filter(
              (itemSelected: filterObjectType) =>
                itemSelected.title !== ALL_FILTERS,
            ),
            item,
          ];
          setState(newItemsSelected);

          const contentTypeURL = getItemURL(newItemsSelected);
          setItemURL(contentTypeURL);
        }
        setRefreshResults(true);
      } else if (itemsSelected.length > 1) {
        //if the item was already in the selection and it's not the last one, we remove it
        const newList = itemsSelected.filter(
          (_: any, index: number) => index !== indexOfItem,
        );
        setState(newList);
        const contentTypeURL = getItemURL(newList);

        setItemURL(contentTypeURL);
        setRefreshResults(true);
      } else if (itemsSelected.length === 1 && item.title !== ALL_FILTERS) {
        //if the item was already in the selection and it is the last one,  we select All by default
        setState([ALL_FILTERS_ELEMENT]);
        const contentTypeURL = getItemURL([ALL_FILTERS_ELEMENT]);

        setItemURL(contentTypeURL);
        setRefreshResults(true);
      }
    },
    [getItemURL],
  );

  const selectItemsFromSlug = useCallback(
    (
      items: filterObjectType[],
      setItemsSelected: Dispatch<SetStateAction<filterObjectType[]>>,
      setItemURL: (url: string) => void,
    ): void => {
      if (slug !== '') {
        const slugArray = slug.replace(/\//g, ',').split(',');
        const itemsSelected: filterObjectType[] = [];

        slugArray.forEach((slugItem: string) => {
          const index = items?.findIndex(
            element => element.slug === slugItem && slugItem !== '',
          );
          if (index > -1) {
            itemsSelected.push(items[index]);
          }
        });
        if (itemsSelected.length > 0) {
          setItemsSelected(itemsSelected);
          const contentTypeURL = getItemURL(itemsSelected);
          setItemURL(contentTypeURL);
        }
      }
    },
    [getItemURL, slug],
  );

  return {
    getItemURL,
    addDeleteFilterItem,
    refreshResults,
    setRefreshResults,
    selectItemsFromSlug,
  };
};
