import { ChargebeeSubscriptionPlanId } from 'modules/chargebee/constants';

export const getPlanDuration = (params: string) => {
  const searchParams = new URLSearchParams(params);
  const plan = searchParams.get('plan') as ChargebeeSubscriptionPlanId;

  switch (plan) {
    case ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_MONTHLY:
    case ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_MONTHLY_MIGRATION:
    case ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_MONTHLY_3MF:
      return 'Monthly';
    case ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_YEARLY:
    case ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_YEARLY_MIGRATION:
    case ChargebeeSubscriptionPlanId.STANDARD_INDIVIDUAL_YEARLY_3MF:
      return 'Annual';
    default:
      return null;
  }
};
