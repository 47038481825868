import React from 'react';
import clsx from 'clsx';

import { HyperlinkCustomLinkProps } from './types';

import styles from './styles.module.scss';

const CustomLink: React.FunctionComponent<HyperlinkCustomLinkProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  href,
  label,
  onClick,
  rel,
  style,
  target,
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };
  const hyperlinkClassName = clsx(
    style && styles.hyperlink,
    styles[`${style?.replace(/\s/g, '')}`],
    disabled && styles.disabled,
    className,
  );

  return (
    <a
      aria-label={ariaLabel ? ariaLabel : label}
      className={hyperlinkClassName}
      href={href}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      rel={rel}
      tabIndex={disabled ? -1 : 0}
      target={target}
    >
      {label && <span>{label}</span>}
      {children}
    </a>
  );
};

export default CustomLink;
