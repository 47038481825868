import React from 'react';
import clsx from 'clsx';

import { LayoutSize } from '../constants';

import styles from './styles.module.scss';

export type ContainerProps = {
  className?: string;
  children: React.ReactNode;
  size?: LayoutSize;
};

const Container: React.FunctionComponent<ContainerProps> = ({
  className,
  children,
  size = LayoutSize.REGULAR,
}) => {
  const classNames = clsx(className, styles.main);
  const containerClassName = clsx(
    'container',
    size === LayoutSize.SMALL && 'small',
  );
  return (
    <main className={classNames}>
      <div className={containerClassName}>{children}</div>
    </main>
  );
};

export default Container;
