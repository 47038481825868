import React from 'react';

import { PaymentMethod } from 'components/Question';
import { PaymentMethodQuestionSetType } from '../types';

const PaymentMethods: React.FC<PaymentMethodQuestionSetType> = ({
  control,
  isSubmitting,
  model,
  paymentMethods,
}) => {
  return (
    <PaymentMethod
      control={control}
      isSubmitting={isSubmitting}
      paymentMethods={paymentMethods}
      question={model.paymentMethodId}
    />
  );
};

export default React.memo(PaymentMethods);
