import { appInsights, SeverityLevel } from 'modules/appInsights';

//const showErrors = process.env.NODE_ENV === 'development'; //TODO
const showErrors = true;

/* eslint-disable no-console */
const logger = {
  debug: (...messages: any) => showErrors && console.log(...messages),

  error: (message: string, error?: any) => {
    showErrors && console.error(message);
    showErrors && error && console.error(error);

    process.env.NODE_ENV !== 'development' &&
      error &&
      appInsights.trackException({
        error: error,
        severityLevel: SeverityLevel.Error,
      });
  },

  warn: (...messages: any) => showErrors && console.warn(...messages),
};
/* eslint-enable no-console */

export default logger;
