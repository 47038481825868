import React, { useContext, useEffect, useState } from 'react';

import { AccountDataContext } from 'modules/context';
import { CuratedLoadedType } from './types';
import { isFreeTrialUser } from 'modules/utils/utils';
import { SIGN_UP_REMINDER } from 'modules/utils/constants';
import { useCuratedPage } from './hooks/useCuratedPage';
import { useMaturityModelsCuratedPage } from './hooks/useMaturityModelsCuratedPage';
import { useSignupReminder } from 'components/SignupReminder/hooks';
import Template from './Template';

export const CuratedPage: React.FC = () => {
  const [loaded, setLoaded] = useState<CuratedLoadedType>({
    curatedPage: false,
    maturityModels: false,
  });

  const { curatedPage } = useCuratedPage(setLoaded);
  const { accountDataContext } = useContext(AccountDataContext);
  const { itemsViewed, increaseItemsViewed } = useSignupReminder();

  const { mmCta } = useMaturityModelsCuratedPage({
    accountDataContext,
    mm_section: curatedPage?.mm_section,
    setLoaded,
  });

  useEffect(() => {
    if (accountDataContext && isFreeTrialUser(accountDataContext)) {
      increaseItemsViewed(accountDataContext.userId);
    }
  }, [accountDataContext, increaseItemsViewed]);

  return accountDataContext ? (
    <Template
      curatedPage={curatedPage}
      displaySignupReminder={
        isFreeTrialUser(accountDataContext) && itemsViewed >= SIGN_UP_REMINDER
      }
      loaded={loaded}
      mmCta={mmCta}
    />
  ) : null;
};
