import React from 'react';

import {
  BadgeLinksTypes,
  BadgesTypes,
  CertificateTitleTypes,
  DesignationsType,
} from 'pages/badges/types';
import { BadgesType } from './constants';
import { ProgrammeType } from '../Programme/constants';
import BadgesList from './BadgesList';

import styles from './styles.module.scss';
import Skeleton from './Skeleton';

type DesignationsProps = {
  badgeLinks?: BadgeLinksTypes;
  badges?: BadgesTypes[];
  designations: DesignationsType[];
  loaded: boolean;
  type: ProgrammeType;
};

const Designations: React.FunctionComponent<DesignationsProps> = ({
  badgeLinks,
  badges,
  designations,
  loaded,
  type,
}) => {
  return loaded ? (
    <section className={styles.badges}>
      <h3>Designations</h3>
      <ul>
        {designations.map((item: CertificateTitleTypes, index: number) => {
          return (
            <BadgesList
              badgeLinks={badgeLinks}
              badges={badges}
              badgesType={BadgesType.DESIGNATION}
              isDesignation
              item={item}
              key={`designation-${type}-${index}`}
              type={type}
            />
          );
        })}
      </ul>
    </section>
  ) : (
    <Skeleton />
  );
};

export default Designations;
