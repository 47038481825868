import { QuestionType } from 'components/Question/Template/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  email: yup
    .string()
    .required()
    .email()
    .userExists()
    .max(255, 'Email must be at most 255 characters.'),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref('email')], "Your email address doesn't match")
    .required('Please confirm your email'),
});

export type Model = {
  email: string;
  confirmEmail: string;
};

const email: QuestionType<Model> = {
  name: 'email',
};
const confirmEmail: QuestionType<Model> = {
  name: 'confirmEmail',
};

export const model = {
  email,
  confirmEmail,
};

export const defaultValues = { email: '', confirmEmail: '' };
