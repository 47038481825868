import React from 'react';
import clsx from 'clsx';
import badgesItemStyles from '../BadgesItem/styles.module.scss';
import badgesStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const className = clsx(styles.skeleton, badgesStyles.badges);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading badges
      </span>
      <div className={className} aria-hidden="true">
        <div className={badgesStyles.badges}>
          <p></p>
          <p></p>
          <div></div>
          <ul>
            <li>
              <div className={badgesItemStyles.badge}>
                <h4>
                  <span></span>
                  <span className={styles.icon}></span>
                </h4>
                <div>
                  <p></p>
                </div>
              </div>
            </li>
            <li>
              <div className={badgesItemStyles.badge}>
                <h4>
                  <span></span>
                  <span className={styles.icon}></span>
                </h4>
                <div>
                  <p></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className={badgesStyles.badges}>
          <p></p>
          <div></div>
          <ul>
            <li>
              <div className={badgesItemStyles.badge}>
                <h4>
                  <span></span>
                  <span className={styles.icon}></span>
                </h4>
                <div>
                  <p></p>
                </div>
              </div>
            </li>
            <li>
              <div className={badgesItemStyles.badge}>
                <h4>
                  <span></span>
                  <span className={styles.icon}></span>
                </h4>
                <div>
                  <p></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className={badgesStyles.badges}>
          <p></p>
          <div></div>
          <ul>
            <li>
              <div className={badgesItemStyles.badge}>
                <h4>
                  <span></span>
                  <span className={styles.icon}></span>
                </h4>
                <div>
                  <p></p>
                </div>
              </div>
            </li>
            <li>
              <div className={badgesItemStyles.badge}>
                <h4>
                  <span></span>
                  <span className={styles.icon}></span>
                </h4>
                <div>
                  <p></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
