import React, { useContext } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { TransitionProps } from '@material-ui/core/transitions';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';

import { ButtonColour } from 'components/Button/constants';
import { ModalSize } from 'modules/modals/constants';
import { ReactComponent as MyAxelosSVG } from 'modules/theme/ims/MyAxelosBlue.svg';
import * as Hyperlink from 'components/Hyperlink';
import ModalOverlay from '../Overlay';
import PlatformContext from 'modules/platform/context';

import styles from './styles.module.scss';
import modalStyles from 'modules/modals/styles.module.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});

export type ModalPCertPassportErrorTemplateProps = {
  error: {
    title: string;
    message?: string;
    email?: string;
  };
  size?: ModalSize;
};

export const ModalPCertPassportErrorTemplate =
  React.memo<ModalPCertPassportErrorTemplateProps>(
    ({ error, size = ModalSize.FULLWIDTH }) => {
      const classNames = clsx(
        modalStyles[`${size}`],
        styles.modalPCertPassportError,
      );

      return (
        <section className={classNames} role="dialog">
          <div></div>
          <div>
            <div>
              {error.title && <h1>{error.title}</h1>}
              {error.message && (
                <div>
                  {error.message}
                  <Hyperlink.CustomLink
                    href={`mailto:${error.email}`}
                    style={ButtonColour.BRAND_BLACK_GRAPE}
                    label={error.email}
                  ></Hyperlink.CustomLink>
                </div>
              )}
            </div>
          </div>
          <div>
            <MyAxelosSVG />
          </div>
        </section>
      );
    },
  );

export type ModalPCertPassportErrorProps = {
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  id: string;
  open: boolean;
  error: {
    title: string;
    message?: string;
    email?: string;
  };
};

const PCertPassportError: React.FunctionComponent<ModalPCertPassportErrorProps> =
  ({ destroyModal, disableRestoreFocus, error, open }) => {
    const { isMobile } = useContext(PlatformContext);

    return (
      <RemoveScroll enabled={isMobile}>
        <ModalOverlay
          disableRestoreFocus={disableRestoreFocus}
          onExited={destroyModal}
          open={open}
          scroll="body"
          TransitionComponent={Transition}
        >
          <ModalPCertPassportErrorTemplate error={error} />
        </ModalOverlay>
      </RemoveScroll>
    );
  };

export default PCertPassportError;
