import React from 'react';
import clsx from 'clsx';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { defaultValues, schema } from './model';
import Questions from 'components/Questions';
import * as Button from 'components/Button';
import RecaptchaMessage from 'components/RecaptchaMessage';

import styles from './styles.module.scss';
import modalStyles from 'modules/modals/styles.module.scss';
import formModalStyles from 'components/Modal/Form/styles.module.scss';

type PasswordFormProps = {
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

const PasswordForm: React.FunctionComponent<PasswordFormProps> = ({
  isSubmitting,
  onCancel,
  onSubmit,
}) => {
  const isApiSubmitting = false;
  const buttonClassNames = clsx(modalStyles.buttons, formModalStyles.buttons);

  const submittingListeners = isSubmitting || isApiSubmitting;
  return (
    <div className={styles.passwordForm}>
      <Questions
        defaultValues={defaultValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
        schema={schema}
      >
        {({ isFormSubmitting }) => (
          <>
            <p>
              By proceeding, you will be logged out of the current session.
              Follow the instructions on the next screens to complete the
              password change.
            </p>
            <RecaptchaMessage />
            <div className={buttonClassNames}>
              {(submittingListeners || isFormSubmitting) && (
                <div className={styles.spinner}></div>
              )}
              <Button.Generic
                colour={ButtonColour.BRAND_MONO_ONE}
                disabled={submittingListeners || isFormSubmitting}
                label="Cancel"
                onClick={onCancel}
                style={ButtonStyle.SECONDARY}
              />
              <Button.Submit
                colour={ButtonColour.BRAND_BLACK_GRAPE}
                disabled={submittingListeners || isFormSubmitting}
                label="Proceed"
                showSpinner={false}
                style={ButtonStyle.SECONDARY}
              />
            </div>
          </>
        )}
      </Questions>
    </div>
  );
};

export default PasswordForm;
