import { useCallback, useEffect, useRef, useState } from 'react';

import { API, api } from 'modules/api';
import { SignupReminderMessageGetResponse } from 'modules/api/endpoints/signup-reminder-message';
import { SignupReminderMessageType } from './types';
import { SIGN_UP_REMINDER } from 'modules/utils/constants';
import logger from 'modules/logger';

export const useSignupReminder = () => {
  const [itemsViewed, setItemsViewed] = useState(0);
  const [signupReminderMessage, setSignupReminderMessage] =
    useState<SignupReminderMessageType[]>();
  const ITEMS_VIEWED_COOKIE = 'signup-reminder';
  const active = useRef(true);

  const getItemsViewedCookieName = useCallback((userId: number): string => {
    return `${ITEMS_VIEWED_COOKIE}-${userId}`;
  }, []);

  const getSignupReminderMessage = useCallback(async () => {
    try {
      const response: SignupReminderMessageGetResponse = await api(
        API.GET_SIGNUP_REMINDER_MESSAGE(),
      );

      active.current && setSignupReminderMessage(response.data.entries);
    } catch (error) {
      active.current && logger.debug('Signup Reminder Error: ', error);
    }
  }, []);

  const increaseItemsViewed = useCallback(
    (userId: number) => {
      const cookieName = getItemsViewedCookieName(userId);
      let currentItemsViewed =
        parseInt(localStorage.getItem(cookieName) || '0') + 1;
      setItemsViewed(currentItemsViewed);
      if (currentItemsViewed >= SIGN_UP_REMINDER) {
        currentItemsViewed = 0;
      }

      localStorage.setItem(cookieName, currentItemsViewed.toString());
    },
    [getItemsViewedCookieName],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    itemsViewed,
    increaseItemsViewed,
    getSignupReminderMessage,
    signupReminderMessage,
  } as const;
};
