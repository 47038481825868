import React from 'react';
import clsx from 'clsx';

import { BraintreeWrapperProps } from '../../types';
import useBraintree from '../../hooks/useBraintree';
import Questions from 'components/Questions';
import BraintreeForm from '../BraintreeForm';

import styles from './styles.module.scss';

function BraintreeWrapper<T>({
  children,
  className,
  defaultValues,
  onSubmit,
  title,
  schema,
}: BraintreeWrapperProps<T>) {
  const {
    handleSubmit,
    hostedFields,
    isBraintreeLoading,
    isBraintreeSubmitting,
    mergedDefaultValues,
    mergedSchema,
  } = useBraintree<T>({ defaultValues, onSubmit, schema });
  const classNames = clsx(styles.braintreeWrapper, className);
  return (
    <React.Fragment>
      <Questions<T>
        className={classNames}
        defaultValues={mergedDefaultValues}
        onSubmit={handleSubmit}
        schema={mergedSchema}
      >
        {({ control, isFormSubmitting }) => (
          <React.Fragment>
            <BraintreeForm
              control={control}
              hostedFields={hostedFields}
              isLoading={isBraintreeLoading}
              isSubmitting={isBraintreeSubmitting || isFormSubmitting}
              title={title}
            />
            {children &&
              children({
                control,
                isFormLoading: isBraintreeLoading,
                isFormSubmitting: isBraintreeSubmitting || isFormSubmitting,
              })}
          </React.Fragment>
        )}
      </Questions>
    </React.Fragment>
  );
}

export default React.memo(BraintreeWrapper) as typeof BraintreeWrapper;
