import React, { memo } from 'react';
import clsx from 'clsx';

import { AchievementsTypes, BadgeQualifications } from '../../../types';
import { ReactComponent as AchievedTrophySvg } from 'modules/theme/icons/misc/dashboard-achieved-trophy.svg';
import { ReactComponent as BadgeSvg } from 'modules/theme/icons/misc/dashboard-badge.svg';
import { ReactComponent as TrophySvg } from 'modules/theme/icons/misc/trophy.svg';

import styles from './styles.module.scss';

type AchievementsItemTypes = {
  achievement: AchievementsTypes;
};

const AchievementsItem: React.FunctionComponent<AchievementsItemTypes> = ({
  achievement,
}) => {
  const hasAchieved = achievement?.qualifications.filter(
    (a: BadgeQualifications) => a.achieved,
  ).length;
  const complete = hasAchieved === achievement.qualifications.length;
  const finishCount = `${hasAchieved} of ${achievement.qualifications.length} completed`;

  const classNames = clsx(
    styles.achievementsItem,
    complete && styles.complete,
    complete && achievement.family === 'ProPath' && styles.proPath,
  );
  return !achievement.isCertificate ? (
    <li className={classNames}>
      <div>
        <BadgeSvg />
        <p>{achievement.designation}</p>
      </div>
      <hr />
      <div>
        <span>{finishCount}</span>
        {complete ? (
          <React.Fragment>
            <em>Finished</em>
            <AchievedTrophySvg />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <em>In Progress</em>
            <TrophySvg />
          </React.Fragment>
        )}
      </div>
    </li>
  ) : null;
};

export default memo(AchievementsItem);
