import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { ButtonPageLinkProps } from './types';
import { ButtonStyle } from './constants';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { ReactComponent as LargeChevronSVG } from 'modules/theme/icons/chevrons/large-chevron-down.svg';
import { ReactComponent as SliceSVG } from 'modules/theme/ims/button-slice.svg';

import styles from './styles.module.scss';

const PageLink: React.FunctionComponent<ButtonPageLinkProps> = ({
  ariaLabel,
  children,
  className,
  colour,
  disabled,
  label,
  onClick,
  slug,
  style = ButtonStyle.PRIMARY,
  small,
  target,
  title,
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };

  const buttonClassName = clsx(
    style && styles.button,
    styles[`${style}`],
    styles[`${colour?.replace(/\s/g, '')}`],
    disabled && styles.disabled,
    small && styles.small,
    className,
  );
  return (
    <NavLink
      aria-label={ariaLabel ? ariaLabel : label}
      className={buttonClassName}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      tabIndex={disabled ? -1 : 0}
      target={target}
      to={slug}
      title={title}
    >
      {label && <span>{label}</span>}
      {children}
      {style === ButtonStyle.PRIMARY && (
        <>
          {small ? (
            <ChevronSVG className={styles['chevron']} />
          ) : (
            <LargeChevronSVG className={styles['chevron']} />
          )}
          <SliceSVG className={styles['slice']} />
        </>
      )}
    </NavLink>
  );
};

export default PageLink;
