import { useCallback, useEffect, useState } from 'react';

import { BraintreeHostedFields, BraintreeHostedFieldsEvent } from '../types';
import logger from 'modules/logger';

type UseBraintreeCardType = (
  hostedFields: BraintreeHostedFields | null,
) => string | null;

const useBraintreeCardType: UseBraintreeCardType = hostedFields => {
  const [cardType, setCardType] = useState<string | null>(null);

  const handleCardType = useCallback(
    (event: BraintreeHostedFieldsEvent) =>
      setCardType(event.cards.length === 1 ? event.cards[0].type : null),
    [],
  );

  useEffect(() => {
    if (hostedFields && !hostedFields.isTearingDown)
      try {
        hostedFields.on('cardTypeChange', handleCardType);
      } catch (error) {
        logger.error('Braintree CardTypeChange On Error', error);
      }
    return () => {
      if (hostedFields && !hostedFields.isTearingDown)
        try {
          hostedFields.off('cardTypeChange', handleCardType);
        } catch (error) {
          logger.error('Braintree CardTypeChange Off Error', error);
        }
    };
  }, [handleCardType, hostedFields]);

  return cardType;
};

export default useBraintreeCardType;
