import React, { useCallback, useContext } from 'react';
import clsx from 'clsx';
import { TransitionProps } from '@material-ui/core/transitions';
import { RemoveScroll } from 'react-remove-scroll';
import Fade from '@material-ui/core/Fade';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { ReactComponent as MyAxelosSVG } from 'modules/theme/ims/MyAxelosBlue.svg';
import { ModalSize } from 'modules/modals/constants';
import * as Button from 'components/Button';
import ModalOverlay from '../Overlay';
import PlatformContext from 'modules/platform/context';

import styles from './styles.module.scss';
import modalStyles from 'modules/modals/styles.module.scss';
import RichText from 'components/RichText';

const NewlineText = (text: string) => {
  return text.split('\n').map((str, index) => <p key={index}>{str}</p>);
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});

export type ModalErrorTemplateProps = {
  error: {
    title: string;
    message?: string;
    code?: string;
  };
  onClose: () => void;
  size?: ModalSize;
};

export const ModalErrorTemplate = React.memo<ModalErrorTemplateProps>(
  ({ error, onClose, size = ModalSize.FULLWIDTH }) => {
    const classNames = clsx(modalStyles[`${size}`], styles.modalError);
    return (
      <section className={classNames} role="dialog">
        <div></div>
        <div>
          <div>
            {error.code && <span>{error.code}</span>}
            {error.title && <h1>{error.title}</h1>}
            {error.message &&
              (error.code === '404' ? (
                <RichText content={error.message} />
              ) : (
                <p>{NewlineText(error.message)}</p>
              ))}
          </div>
          <Button.PageLink
            colour={ButtonColour.BRAND_BLACK_GRAPE}
            label="Take me back to MyAxelos"
            onClick={onClose}
            style={ButtonStyle.SECONDARY}
            slug="/"
          />
        </div>
        <div>
          <MyAxelosSVG />
        </div>
      </section>
    );
  },
);

export type ModalErrorProps = {
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  id: string;
  onClose: () => void;
  open: boolean;
  error: {
    title: string;
    message?: string;
    code?: string;
  };
};

const Error: React.FunctionComponent<ModalErrorProps> = ({
  destroyModal,
  disableRestoreFocus,
  error,
  hideModal,
  onClose,
  open,
}) => {
  const { isMobile } = useContext(PlatformContext);

  const handleClose = useCallback(() => {
    onClose ? open && onClose() : open && hideModal();
  }, [hideModal, onClose, open]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        handleClose();
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [handleClose],
  );
  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        disableRestoreFocus={disableRestoreFocus}
        onExited={destroyModal}
        onKeyDown={handleKeyDown}
        open={open}
        scroll="body"
        TransitionComponent={Transition}
      >
        <ModalErrorTemplate error={error} onClose={handleClose} />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default Error;
