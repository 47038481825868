import React, { useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { AccountDataContext } from 'modules/context';
import { ButtonColour } from 'components/Button/constants';
import { contentIsNew } from 'modules/utils/newContentUtils';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { getDateFormatted } from 'modules/utils/utils';
import { ReactComponent as Chevron } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { TriggerGTMEvent } from 'modules/gtm';
import CtaButton from 'components/Cta/Button';
import Social from 'components/Social';
import * as Hyperlink from 'components/Hyperlink';

import styles from './styles.module.scss';

export type BookmarkItemProps = {
  bestPractices?: string[];
  bookmarked: boolean;
  buttonLabel: string;
  contentType?: string;
  handleBookmarkClick?: (contentTypeUid: string) => void;
  description?: string;
  image?: string;
  liked: boolean;
  likes: number;
  publicationDate: string;
  slug: string;
  threeColumn?: boolean;
  title?: string;
  topics?: string[];
  uid: string;
};

const BookmarkItem: React.FunctionComponent<BookmarkItemProps> = ({
  bookmarked,
  buttonLabel,
  description,
  handleBookmarkClick,
  image,
  liked,
  likes,
  publicationDate,
  slug,
  threeColumn,
  title,
  uid,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 576px)',
  });
  const isTablet = useMediaQuery({
    query: '(max-width: 896px)',
  });
  const handleGTMTrigger = (contentSlug: string) => {
    TriggerGTMEvent({
      action: EventsActions.CLICK_READ_NOW,
      category: EventsCategories.CONTENT,
      label: contentSlug,
    });
  };
  const { accountDataContext } = useContext(AccountDataContext);
  const contentNew = useMemo(() => {
    if (accountDataContext) {
      return contentIsNew(
        accountDataContext?.lastLogin,
        publicationDate,
        uid,
        accountDataContext.userId,
      );
    }
  }, [accountDataContext, publicationDate, uid]);

  const className = clsx(
    styles.bookmarkItem,
    contentNew && styles.isNewArticle,
  );

  return (
    <article className={className}>
      {image ? (
        <span
          style={{
            backgroundImage: `url('${image}?format=jpg&auto=webp?quality=60')`,
          }}
        >
          <span></span>
        </span>
      ) : (
        <span className={styles.placeholderImage}></span>
      )}
      <div>
        <header>
          {accountDataContext && contentNew && (
            <span className={styles.newIcon}>NEW</span>
          )}
          <time className={styles.publication} dateTime={publicationDate}>
            <span>{getDateFormatted(publicationDate)}</span>
          </time>
          {((!threeColumn && !isMobile) ||
            (threeColumn && isTablet && !isMobile)) && (
            <Social
              bookmarked={bookmarked}
              className={styles.social}
              contentTypeUid={uid}
              handleBookmarkClick={handleBookmarkClick}
              liked={liked}
              likes={likes}
              pageSlug={slug.split('/')?.slice(-1)[0]}
            />
          )}
        </header>
        <div className={styles.copy}>
          {!!title && <h3>{title}</h3>}
          {threeColumn && !isTablet && (
            <Social
              bookmarked={bookmarked}
              className={styles.social}
              contentTypeUid={uid}
              handleBookmarkClick={handleBookmarkClick}
              liked={liked}
              likes={likes}
              pageSlug={slug.split('/')?.slice(-1)[0]}
            />
          )}
          {!!description && !isMobile && (
            <div className={styles.copy}>
              <p>{description}</p>
            </div>
          )}
        </div>
        <footer>
          {isMobile && (
            <Social
              bookmarked={bookmarked}
              className={styles.social}
              contentTypeUid={uid}
              handleBookmarkClick={handleBookmarkClick}
              liked={liked}
              likes={likes}
              pageSlug={slug.split('/')?.slice(-1)[0]}
            />
          )}
          {(!threeColumn || (threeColumn && isTablet)) && (
            <Hyperlink.PageLink
              className={styles.hyperlink}
              label={buttonLabel}
              onClick={() => handleGTMTrigger(slug)}
              slug={slug}
              style={ButtonColour.BRAND_BERRY}
            >
              <Chevron />
            </Hyperlink.PageLink>
          )}
          {threeColumn && !isMobile && !isTablet && (
            <CtaButton
              defaultColour={ButtonColour.BRAND_BERRY}
              label={buttonLabel}
              onClick={() => handleGTMTrigger(slug)}
              page_link={slug}
              small
            />
          )}
        </footer>
      </div>
    </article>
  );
};

export default BookmarkItem;
