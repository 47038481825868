import { useCallback } from 'react';
import { BadgesTypes } from '../types';

export const useSortBadges = () => {
  const sortBadges = useCallback((badges?: BadgesTypes[]) => {
    if (badges) {
      let arrSort: BadgesTypes[] = [];
      arrSort = arrSort.concat(badges);
      return arrSort.sort((a: BadgesTypes, b: BadgesTypes) =>
        a.displayPriority < b.displayPriority ? -1 : 1,
      );
    }
  }, []);

  return { sortBadges } as const;
};
