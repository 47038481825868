type TriggerGTMEventProps = {
  action: string;
  category: string;
  label: string;
};

export const TriggerGTMEvent = ({
  action,
  category,
  label,
}: TriggerGTMEventProps) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  return (window as any).dataLayer.push({
    event: 'customEvent',
    customEventCategory: category,
    customEventAction: action,
    customEventLabel: label,
  });
};
