import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type useScrollToFormProps = {
  scrollToForm: boolean;
};

export const useScrollToForm = (formContainer: HTMLDivElement | null) => {
  const { state: params } = useLocation<useScrollToFormProps>();
  const scrollToForm = params?.scrollToForm;

  const applyScrollToForm = useCallback(() => {
    if (formContainer && scrollToForm) {
      formContainer.scrollIntoView({ behavior: 'smooth' });
      // prevent scrolling after refresh
      window.history.replaceState({}, document.title);
    }
  }, [formContainer, scrollToForm]);

  useEffect(() => {
    applyScrollToForm();
  }, [applyScrollToForm, formContainer]);
};
