import { useErrorModal } from 'modules/modals/hooks/useErrorModal';
import React, { useCallback, useEffect, useState } from 'react';
import * as Loading from 'components/Loading';

export const PageContentNotAllowed = () => {
  const [displayModal] = useErrorModal();
  const [loading, setLoading] = useState(true);
  //TODO remove async
  const displayError = useCallback(() => {
    const errorMessage = 'You do not have permission to view this content';
    displayModal(errorMessage);
    setLoading(false);
  }, [displayModal]);

  useEffect(() => {
    displayError();
  }, [displayError]);

  return <React.Fragment>{loading && <Loading.Modal />}</React.Fragment>;
};
