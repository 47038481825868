import React from 'react';
import clsx from 'clsx';

import {
  getDateDayFormatted,
  getDateTimeFormatted,
  isSameDate,
} from 'modules/utils/utils';

import styles from './styles.module.scss';

export type EventDateTimeProps = {
  className?: string;
  endDate: string;
  startDate: string;
};

const EventDateTime: React.FunctionComponent<EventDateTimeProps> = ({
  className,
  endDate,
  startDate,
}) => {
  const classNames = clsx(styles.eventDateTime, className);
  return (
    <div className={classNames}>
      <h4>Date & Time:</h4>
      <span>
        <time dateTime={startDate}>{getDateDayFormatted(startDate)}</time>
        <span>{getDateTimeFormatted(startDate, endDate)}</span>
      </span>
      {!isSameDate(startDate, endDate) && endDate && (
        <span>
          <time dateTime={endDate}>{getDateDayFormatted(endDate)}</time>
          <span>{getDateTimeFormatted(endDate)}</span>
        </span>
      )}
    </div>
  );
};

export default EventDateTime;
