import { ReactComponent as Logo } from 'modules/theme/ims/MyAxelosBlue.svg';
import styles from './styles.module.scss';

const AuthLanding = () => {
  return (
    <div className={styles.authLanding}>
      <Logo />
    </div>
  );
};

export default AuthLanding;
