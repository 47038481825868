import React from 'react';
import clsx from 'clsx';

import { LayoutVerticalPadding } from '../constants';

import styles from './styles.module.scss';

export type ContentProps = {
  className?: string;
  children: React.ReactNode;
  verticalPadding?: LayoutVerticalPadding;
};

const Content: React.FunctionComponent<ContentProps> = ({
  className,
  children,
  verticalPadding = LayoutVerticalPadding.SMALL,
}) => {
  const classNames = clsx(
    className,
    styles.content,
    verticalPadding === LayoutVerticalPadding.LARGE &&
      styles.verticalPaddingLarge,
  );
  return <div className={classNames}>{children}</div>;
};

export default Content;
