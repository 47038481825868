import { QuestionType } from 'components/Question/Template/types';
import yup from 'modules/validation';

export const schema = yup.object().shape({
  billingFirstName: yup
    .string()
    .required()
    .name()
    .max(255, 'First name must be at most 255 characters.'),
  billingLastName: yup
    .string()
    .required()
    .name()
    .max(255, 'Last name must be at most 255 characters.'),
  billingStreetAddress: yup
    .string()
    .required()
    .max(255, 'Address line one must be at most 255 characters.'),
  billingExtendedAddress: yup
    .string()
    .max(255, 'Address line two must be at most 255 characters.'),
  billingLocality: yup
    .string()
    .required()
    .max(255, 'City must be at most 255 characters.'),
  billingPostalCode: yup
    .string()
    .required()
    .max(255, 'Zip or Postcode must be at most 255 characters.'),
  billingCountryCodeAlpha2: yup.string().required(),

  makeDefault: yup.boolean(),
});

export type Model = {
  billingFirstName: string;
  billingLastName: string;
  billingStreetAddress: string;
  billingExtendedAddress?: string;
  billingLocality: string;
  billingPostalCode: string;
  billingCountryCodeAlpha2: string;

  makeDefault: boolean;
};

export const defaultValues: Model = {
  billingFirstName: '',
  billingLastName: '',
  billingStreetAddress: '',
  billingExtendedAddress: '',
  billingLocality: '',
  billingPostalCode: '',
  billingCountryCodeAlpha2: '',

  makeDefault: false,
};

const billingFirstName: QuestionType<Model> = {
  name: 'billingFirstName',
};

const billingLastName: QuestionType<Model> = {
  name: 'billingLastName',
};

const billingStreetAddress: QuestionType<Model> = {
  name: 'billingStreetAddress',
};

const billingExtendedAddress: QuestionType<Model> = {
  name: 'billingExtendedAddress',
};

const billingLocality: QuestionType<Model> = {
  name: 'billingLocality',
};

const billingPostalCode: QuestionType<Model> = {
  name: 'billingPostalCode',
};

const billingCountryCodeAlpha2: QuestionType<Model> = {
  name: 'billingCountryCodeAlpha2',
};

const makeDefault: QuestionType<Model> = {
  name: 'makeDefault',
};

export const model = {
  billingFirstName,
  billingLastName,
  billingStreetAddress,
  billingExtendedAddress,
  billingLocality,
  billingPostalCode,
  billingCountryCodeAlpha2,

  makeDefault,
};
