import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { FormControlProps } from '../FormControl/Default';
import { model, Model, schema } from '../QuestionSet/model';
import { PaymentInfoType } from 'pages/account-management/components/Subscriptions/PaymentInformation/types';
import { ProductInfoProps, VoucherCodeProps } from '../types';
import * as Button from 'components/Button';
import * as QuestionSet from '../QuestionSet';
import Fieldset from 'components/Fieldset';
import PurchaseSummary from '../PurchaseSummary';
import Questions from 'components/Questions';
import VoucherCode from '../VoucherCode';

import styles from './styles.module.scss';

type PaymentQuestionsType = {
  defaultValues: Model;
  formControl: React.FC<FormControlProps>;
  handleAddCard: () => void;
  handleSubmit: (data: any) => Promise<void>;
  id: string;
  isSubmitting: boolean;
  paymentMethods: PaymentInfoType[];
  productInfo: ProductInfoProps;
  voucherCode: VoucherCodeProps;
};

const StoredPaymentQuestions: React.FunctionComponent<PaymentQuestionsType> = ({
  defaultValues,
  formControl,
  handleAddCard,
  handleSubmit,
  id,
  isSubmitting,
  paymentMethods,
  productInfo,
  voucherCode,
}) => {
  const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });
  const FormControl = useMemo(() => formControl, [formControl]);
  return (
    <Questions
      className={styles.signUpForm}
      defaultValues={defaultValues}
      id={id}
      onSubmit={handleSubmit}
      schema={schema[3]}
    >
      {({ control, isFormSubmitting }) => (
        <React.Fragment>
          <Fieldset title="Payment methods">
            <QuestionSet.PaymentMethods
              control={control}
              isSubmitting={isFormSubmitting || isSubmitting}
              model={model}
              paymentMethods={paymentMethods}
            />
          </Fieldset>
          <div className={styles.addNewCard}>
            <Button.Generic
              colour={ButtonColour.BRAND_BLACK_GRAPE}
              label="Add a new card"
              disabled={isSubmitting}
              onClick={handleAddCard}
              small
              style={ButtonStyle.PRIMARY}
            />
          </div>
          <Fieldset title="User agreement">
            <QuestionSet.Consent
              control={control}
              isSubmitting={isFormSubmitting || isSubmitting}
              model={model}
            />
          </Fieldset>

          {isMobileBreakpoint && (
            <PurchaseSummary showBackLink={false} {...productInfo}>
              <VoucherCode {...voucherCode} isSubmitting={isSubmitting} />
            </PurchaseSummary>
          )}
          <FormControl isSubmitting={isFormSubmitting || isSubmitting} />
        </React.Fragment>
      )}
    </Questions>
  );
};

export default React.memo(StoredPaymentQuestions);
