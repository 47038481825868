import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  StoreEnhancer,
  createStore as reduxCreateStore,
} from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { createSelectorHook } from 'react-redux';
import middleware from './middleware';
import reducers from './reducers';

const rootReducer = combineReducers({
  ...reducers,
});

const storeEnhancers: StoreEnhancer[] = [];

const createStore = () =>
  reduxCreateStore(
    rootReducer,
    compose(applyMiddleware(thunk, ...middleware()), ...storeEnhancers),
  );

export { shallowEqual } from 'react-redux';

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector = createSelectorHook<RootState>();

export type Dispatch = ThunkDispatch<RootState, null, AnyAction>;

export default createStore;
