import {
  CongratulationsPagePlanText,
  CongratulationsPageType,
} from 'modules/api/endpoints/congrats-page';
import { SubscriptionStateEnum } from 'modules/utils/types';

export const useContent = (
  content?: CongratulationsPageType,
  subscriptionState?: SubscriptionStateEnum,
) => {
  if (!content) {
    return content;
  }

  switch (subscriptionState) {
    case SubscriptionStateEnum.Consultant_User:
      return content.consultant.plan_text;
    case SubscriptionStateEnum.Migrated_User:
      return content.migrated.plan_text;
    case SubscriptionStateEnum.New_User:
    case SubscriptionStateEnum.Organisation_User:
      return content.new.plan_text;
    case SubscriptionStateEnum.PeopleCert_User:
      return content.peoplecert.plan_text;
    case SubscriptionStateEnum.Subscribed:
      return content.upgrading.plan_text;
    case SubscriptionStateEnum.Subscription_Lapsed:
    case SubscriptionStateEnum.Subscription_Non_Renewing:
      return content.lapsed_resubscribing.plan_text;
    default:
      return {} as CongratulationsPagePlanText;
  }
};
