import React from 'react';

import { ButtonColour } from 'components/Button/constants';
import { Publication } from '../types';
import { stripTags } from 'modules/utils/utils';
import CtaButton from 'components/Cta/Button';

import styles from './styles.module.scss';

type PublicationsItemProp = {
  publication: Publication;
};

export const PublicationsItem: React.FunctionComponent<PublicationsItemProp> =
  ({ publication }) => {
    return (
      <article className={styles.publicationsItem}>
        <div>
          <img
            alt={stripTags(publication.preview_title)}
            src={`${publication.image.url}?format=jpg&auto=webp?quality=60`}
          ></img>
          <h4>{publication.short_title || publication.title}</h4>
          {publication.short_description && (
            <p>{publication.short_description}</p>
          )}
        </div>
        <div>
          <div>
            <h5>PRICE:</h5>
            <span>{publication.price}</span>
          </div>
          <CtaButton
            custom_url={publication.cta?.custom_url}
            defaultColour={ButtonColour.BRAND_BLACK_GRAPE}
            label={publication.cta?.label || 'Shop now'}
            page_link={publication.custom_url}
            small
          />
        </div>
      </article>
    );
  };

export default PublicationsItem;
