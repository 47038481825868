import React from 'react';
import styles from './styles.module.scss';

export type headingProps = {
  heading?: string;
};

const Heading: React.FunctionComponent<headingProps> = ({ heading }) => {
  return <h3 className={styles.heading}>{heading}</h3>;
};

export default Heading;
