import { useCallback, useEffect, useRef, useState } from 'react';

import { useCloseOnEscapeKey, useOutsideAlerter } from 'modules/utils/utils';

export const useAccountMenu = (styles: any) => {
  const [toggleAccountMenu, setToggleAccountMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navRef = useRef<HTMLElement>(null);

  const handleTransition = useCallback(() => setShowMenu(true), []);

  const handleTransitionEnd = useCallback(
    (event: AnimationEvent) => {
      if (
        event.target === navRef.current &&
        (event.animationName === styles.fadeOutScaleDown ||
          event.animationName === styles.fadeOut)
      )
        setShowMenu(false);
    },
    [styles],
  );

  useEffect(() => {
    if (toggleAccountMenu) {
      handleTransition();
    }
  }, [handleTransition, toggleAccountMenu]);

  useEffect(() => {
    const element = navRef.current;
    element?.addEventListener('animationend', handleTransitionEnd);
    return () =>
      element?.removeEventListener('animationend', handleTransitionEnd);
  }, [handleTransitionEnd]);

  useOutsideAlerter({
    buttonRef: buttonRef,
    containerRef: navRef,
    setState: setToggleAccountMenu,
  });

  useCloseOnEscapeKey({ setState: setToggleAccountMenu });

  return {
    buttonRef,
    navRef,
    setToggleAccountMenu,
    showMenu,
    toggleAccountMenu,
  } as const;
};
