import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { AccountDataType } from 'modules/utils/types';
import { API, api } from 'modules/api';
import logger from 'modules/logger';
import { getAccountDataObject } from 'modules/utils/utils';

export const useProfilePhoto = (
  accountDataContext: AccountDataType | undefined,
  refForm?: RefObject<HTMLFormElement>,
  setAccountDataContext?: React.Dispatch<any>,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState('');
  const active = useRef(true);
  const maxSizeFileKB = 10240;
  const maxWidthFilePx = 4096;
  const maxHeightFilePx = 4096;

  useEffect(() => {
    if (accountDataContext) {
      setProfilePhoto(accountDataContext?.profilePhoto);
    }
  }, [accountDataContext]);

  const imageSize = (
    url: string,
  ): Promise<{ width: number; height: number }> => {
    const img = new Image();

    const promise = new Promise<{ width: number; height: number }>(
      (resolve, reject) => {
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          resolve({ width, height });
        };

        img.onerror = reject;
      },
    );

    img.src = url;

    return promise;
  };
  //TODO done useCallback
  const fileValidation = useCallback(async (file: File): Promise<boolean> => {
    try {
      let result = true;
      const objectUrl = URL.createObjectURL(file);
      const { width, height } = await imageSize(objectUrl);
      if (active.current) {
        if (file.size / 1024 > maxSizeFileKB) {
          logger.error(
            `Profile Picture - Validation - Max File Size Exceeded, Max Size = ${maxSizeFileKB}, Current File Size = ${
              file.size / 1024
            }`,
          );
          result = false;
        }

        if (result && width > maxWidthFilePx) {
          logger.error(
            'Profile Picture - Validation - Max File Width Exceeded',
          );
          result = false;
        }

        if (result && height > maxHeightFilePx) {
          logger.error(
            'Profile Picture - Validation - Max File Height Exceeded',
          );
          result = false;
        }
      }
      return result;
    } catch (error) {
      active.current && logger.debug('File Validation Error: ', error);
      return false;
    }
  }, []);

  //TODO done useCallback
  const postProfilePhoto = useCallback(
    async (file: File) => {
      try {
        setIsSubmitting(true);
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);
        const res = await api(API.POST_CHANGE_PROFILE_PICTURE(bodyFormData));
        if (active.current) {
          const accountData = getAccountDataObject(res.data);
          setAccountDataContext && setAccountDataContext(accountData);
          setProfilePhoto(res.data.appUser.profileImageUrl);
          logger.debug('Profile Picture - Post - Successful');
        }
      } catch (error: any) {
        active.current &&
          logger.error(
            `Profile Picture - Post - Error: ${error.response.statusText}`,
          );
      } finally {
        active.current && setIsSubmitting(false);
      }
    },
    [setAccountDataContext],
  );

  //TODO done useCallback
  const handleDeletePicture = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const res = await api(API.DELETE_PROFILE_PICTURE());
      if (active.current) {
        logger.debug('Profile Picture - Delete - Successful');
        const accountData = getAccountDataObject(res.data);
        setAccountDataContext && setAccountDataContext(accountData);
        setProfilePhoto('');
        refForm && refForm.current?.reset();
      }
    } catch (error: any) {
      active.current &&
        logger.error(
          `Profile Picture - Delete - Error: ${error.response.statusText}`,
        );
    } finally {
      active.current && setIsSubmitting(false);
    }
  }, [refForm, setAccountDataContext]);
  //TODO done useCallback
  const handleAddPicture = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event?.target?.files?.length && event?.target?.files?.length > 0) {
        try {
          logger.debug('Profile Picture - Add - Init');
          const fileIsValid = await fileValidation(event.target?.files[0]);
          if (active.current) {
            if (fileIsValid) {
              logger.debug(
                `Profile Picture - Input file =`,
                event.target?.files[0],
                `Profile Picture - Response file = ${profilePhoto}`,
              );
              postProfilePhoto(event.target?.files[0]);
            }
          }
        } catch (error) {
          active.current && logger.debug('Handle Add Picture Error', error);
        }
      }
    },
    [fileValidation, postProfilePhoto, profilePhoto],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    isSubmitting,
    profilePhoto,
    handleAddPicture,
    handleDeletePicture,
  } as const;
};
