import React, { createContext } from 'react';
import { AccountDataType, LoginTypeEnum } from 'modules/utils/types';

interface IAccountDataContext {
  accountDataContext?: AccountDataType;
  setAccountDataContext: React.Dispatch<any>;
}
export const AccountDataContext = createContext<IAccountDataContext>({
  setAccountDataContext: () => {
    return;
  },
});
interface ILoginTypeContext {
  loginTypeContext?: LoginTypeEnum;
  setLoginTypeContext: React.Dispatch<LoginTypeEnum>;
}
export const LoginTypeContext = createContext<ILoginTypeContext>({
  setLoginTypeContext: () => {
    return;
  },
});

interface ISubscriptionEndDateContext {
  subscriptionEndDateContext?: string;
  setSubscriptionEndDateContext: React.Dispatch<string>;
}
export const SubscriptionEndDateContext =
  createContext<ISubscriptionEndDateContext>({
    setSubscriptionEndDateContext: () => {
      return;
    },
  });
