import React from 'react';
import clsx from 'clsx';

import * as Hyperlink from 'components/Hyperlink';
import Slice from 'modules/theme/ims/post-slice.svg';
import styles from './styles.module.scss';

export type itemProps = {
  children?: React.ReactNode;
  contentFull?: boolean;
  event?: boolean;
  featuredEvent?: boolean;
  image?: string;
  imageSize?: string;
  itemDetailLink?: string;
  resource?: boolean;
};

const Item: React.FunctionComponent<itemProps> = ({
  children,
  contentFull,
  event,
  featuredEvent,
  image,
  imageSize,
  itemDetailLink,
  resource,
}) => {
  const cardClass = clsx(
    styles.card,
    event && styles.eventCard,
    featuredEvent && styles.featuredEventCard,
  );
  const imageClass = clsx(
    styles.cardImage,
    imageSize && styles[imageSize],
    event && styles.eventCardImage,
    featuredEvent && styles.featuredEventImage,
  );

  const imagePlaceholderClass = clsx(styles.cardImage, styles.placeholderImage);

  const contentClass = clsx(
    styles.cardContent,
    contentFull && styles.fullHeight,
    resource && styles.resourceCard,
    event && styles.eventCardContent,
    featuredEvent && styles.featuredEventContent,
  );
  return (
    <article className={cardClass}>
      {image ? (
        <div
          style={{
            backgroundImage: `url(${image}?format=jpg&auto=webp?quality=60)`,
          }}
          className={imageClass}
        >
          {itemDetailLink ? (
            <Hyperlink.CustomLink href={itemDetailLink}>
              <span
                aria-hidden="true"
                style={{
                  backgroundImage: `url(${Slice}`,
                }}
              ></span>
            </Hyperlink.CustomLink>
          ) : (
            <span
              aria-hidden="true"
              style={{
                backgroundImage: `url(${Slice}`,
              }}
            ></span>
          )}
        </div>
      ) : (
        <div className={imagePlaceholderClass}></div>
      )}
      <div className={contentClass}>{children}</div>
    </article>
  );
};

export default Item;
