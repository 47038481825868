import React from 'react';
import clsx from 'clsx';

import { HyperlinkPageLinkProps } from './types';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

const PageLink: React.FunctionComponent<HyperlinkPageLinkProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  label,
  onClick,
  rel,
  slug,
  style,
  target,
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };

  const hyperlinkClassName = clsx(
    style && styles.hyperlink,
    styles[`${style?.replace(/\s/g, '')}`],
    disabled && styles.disabled,
    className,
  );

  return (
    <NavLink
      aria-label={ariaLabel ? ariaLabel : label}
      className={hyperlinkClassName}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      rel={rel}
      tabIndex={disabled ? -1 : 0}
      target={target}
      to={slug}
    >
      {label && <span>{label}</span>}
      {children}
    </NavLink>
  );
};

export default PageLink;
