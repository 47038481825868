import React from 'react';

import styles from './styles.module.scss';

const MaturityModels: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading maturity models
      </span>
      <div className={styles.skeleton} aria-hidden="true">
        <section className={`${styles.skeletonItem} ${styles.p3m3}`}>
          <span></span>
          <p></p>
          <div>
            <span></span>
            <div>
              <span></span>
            </div>
          </div>
        </section>
        <section className={`${styles.skeletonItem} ${styles.itil}`}>
          <span></span>
          <p></p>
          <div>
            <span></span>
            <div>
              <span></span>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default MaturityModels;
