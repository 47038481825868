import React from 'react';

import contentTypeStyles from '../styles.module.scss';
import topPannel from '../components/TopPanel/styles.module.scss';
import styles from './styles.module.scss';
import clsx from 'clsx';

const Skeleton: React.FunctionComponent = () => {
  const className = clsx(contentTypeStyles['content-type'], styles.skeleton);
  const publicationClass = clsx(topPannel.publication, styles.publication);
  const tagsClass = clsx(topPannel.tags, styles.tags);

  return (
    <React.Fragment>
      <main>
        <span data-nosnippet className="visuallyHidden">
          Loading Content Types
        </span>
        <div className={className} aria-hidden="true">
          <div className={topPannel.topPanel}>
            <div className={publicationClass}>
              <p></p>
              <p></p>
            </div>
            <ul className={tagsClass}>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <p className={styles.title}>
              <span></span>
            </p>
            <div className={topPannel.social}>
              <div className={topPannel.likes}>
                <div className={topPannel.roundel}>
                  <span></span>
                </div>
                <span className={styles.likesText}></span>
              </div>
              <div className={topPannel.roundel}></div>
            </div>
          </div>
          <div className="container small">
            <p className={styles.introParagraph}></p>
            <p className={styles.introParagraph}></p>
            <p className={styles.introParagraph}></p>
            <p className={styles.introParagraph}></p>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default Skeleton;
