import { AccountDataType } from 'modules/utils/types';
import { getAcpMaturityModels, getAcpProducts, getAcpTiers } from './utils';

export const useACPs = (accountDataContext?: AccountDataType) => {
  const { userMaturityModels } = getAcpMaturityModels(
    accountDataContext?.userRoles,
  );
  const { userProducts } = getAcpProducts(accountDataContext?.userRoles);
  const { userTiers } = getAcpTiers(accountDataContext?.userRoles);

  const maturityModels = userMaturityModels;
  const products = userProducts;
  const tierNames = userTiers;

  return { maturityModels, products, tierNames } as const;
};
