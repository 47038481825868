import React from 'react';

import { countries } from 'modules/countries';
import { SignUpQuestionSetType } from '../types';
import * as Question from 'components/Question';

const CountryOfResidence: React.FC<SignUpQuestionSetType> = ({
  control,
  isSubmitting,
  model,
}) => {
  return (
    <React.Fragment>
      <Question.Text
        control={control}
        isSubmitting={true}
        label="First name"
        question={model.givenName}
      />
      <Question.Text
        control={control}
        isSubmitting={true}
        label="Surname"
        question={model.surname}
      />
      <Question.Text
        control={control}
        isSubmitting={true}
        label="Email"
        question={model.email}
      />
      <Question.Select
        control={control}
        isSubmitting={isSubmitting}
        label="Country"
        options={countries}
        question={model.countryCode}
      />
    </React.Fragment>
  );
};

export default React.memo(CountryOfResidence);
