import { useCallback, useEffect, useRef, useState } from 'react';

import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { LoginTypeEnum } from 'modules/utils/types';
import { ResourceHubLoadedType } from '../types';
import { TriggerGTMEvent } from 'modules/gtm';
import logger from 'modules/logger';

export const useSearchButtonClick = (
  setLoaded: React.Dispatch<React.SetStateAction<ResourceHubLoadedType>>,
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
  getInputProps: any,
  fetchSearchData: (
    loginTypeContext: LoginTypeEnum,
    value: string,
    resetNumberOfitemsShown: boolean,
  ) => void,
  searchClick: boolean,
  setSearchClick: React.Dispatch<React.SetStateAction<boolean>>,
  loginTypeContext?: LoginTypeEnum,
) => {
  const active = useRef(true);
  const [searchInitiated, setSearchInitiated] = useState(false);
  //TODO done useCallback
  const handleSearchButtonClick = useCallback(async () => {
    try {
      TriggerGTMEvent({
        action: EventsActions.SEARCH_BUTTON_CLICKED,
        category: EventsCategories.SEARCH,
        label: '',
      });
      setLoaded(prevState => ({
        ...prevState,
        resourceHub: false,
      }));
      const { value }: any = getInputProps();
      setSearchTerm(value);
      setSearchInitiated(true);
      await fetchSearchData(loginTypeContext!, value, true);
    } catch (error) {
      active.current && logger.debug('Search Button Click Error: ', error);
    } finally {
      active.current &&
        setLoaded(prevState => ({
          ...prevState,
          resourceHub: true,
        }));
    }
  }, [
    fetchSearchData,
    getInputProps,
    loginTypeContext,
    setLoaded,
    setSearchTerm,
  ]);

  const handleEnterKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        setSearchClick(true);
      }
    },
    [setSearchClick],
  );

  useEffect(() => {
    if (!searchClick) return;
    handleSearchButtonClick();
    setSearchClick(false);
  }, [searchClick, handleSearchButtonClick, setSearchClick]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    searchEvents: {
      handleSearchButtonClick,
      handleEnterKeyDown,
      searchInitiated,
    },
  };
};
