type TriggerEngagementSummaryPageTypeEventProps = {
  id: number;
  pageURL: string;
  contentTitle: string;
  contentType: string;
  pageviews: number;
  downloads: number;
  indivisualUser: number;
  consultantUser: number;
};

type TriggerEngagementSummaryContentTypeEventProps = {
  id: number;
  pageURL: string;
  contentType: string;
  pageviews: number;
  downloads: number;
  indivisualUser: number;
  consultantUser: number;
};

type TriggerContentTypeEngagementTimeEventProps = {
  id: number;
  userEmail: string;
  pageURL: number;
  contentType: string;
  timeSpentInMinutes: number;
  esContentTypeID: number;
};

type TriggerPageTypeEngagementTimeEventProps = {
  id: number;
  userEmail: string;
  pageURL: number;
  contentType: string;
  timeSpentInMinutes: number;
  esPageTypeID: number;
};

export const mapEngagementSummaryPageType = ({
  id,
  pageURL,
  contentTitle,
  contentType,
  pageviews,
  downloads,
  indivisualUser,
  consultantUser,
}: TriggerEngagementSummaryPageTypeEventProps) => {
  return {
    Id: id,
    PageURL: pageURL,
    ContentTitle: contentTitle,
    ContentType: contentType,
    Pageviews: pageviews,
    Downloads: downloads,
    IndivisualUser: indivisualUser,
    ConsultantUser: consultantUser,
  };
};

export const mapEngagementSummaryContentType = ({
  id,
  pageURL,
  contentType,
  pageviews,
  downloads,
  indivisualUser,
  consultantUser,
}: TriggerEngagementSummaryContentTypeEventProps) => {
  return {
    Id: id,
    PageURL: pageURL,
    ContentType: contentType,
    Pageviews: pageviews,
    Downloads: downloads,
    IndivisualUser: indivisualUser,
    ConsultantUser: consultantUser,
  };
};

export const mapContentTypeEngagementTime = ({
  id,
  userEmail,
  pageURL,
  contentType,
  timeSpentInMinutes,
  esContentTypeID,
}: TriggerContentTypeEngagementTimeEventProps) => {
  return {
    ID: id,
    UserEmail: userEmail,
    PageURL: pageURL,
    ContentType: contentType,
    TimeSpentInMinutes: timeSpentInMinutes,
    ESContentTypeID: esContentTypeID,
  };
};

export const mapPageTypeEngagementTime = ({
  id,
  userEmail,
  pageURL,
  contentType,
  timeSpentInMinutes,
  esPageTypeID,
}: TriggerPageTypeEngagementTimeEventProps) => {
  return {
    ID: id,
    UserEmail: userEmail,
    PageURL: pageURL,
    ContentType: contentType,
    TimeSpentInMinutes: timeSpentInMinutes,
    ESPageTypeID: esPageTypeID,
  };
};
