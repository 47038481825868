import { useCallback } from 'react';
import { LoginTypeEnum, UserRolesNames } from 'modules/utils/types';
import { userRoleDescription } from 'pages/auth/types';

export const useRoleSelection = () => {
  const getRoleDescription = useCallback(
    (
      roleNum: LoginTypeEnum,
      roleSelectScreenContent: userRoleDescription[],
    ) => {
      return roleSelectScreenContent?.filter(
        (element: userRoleDescription) =>
          element.user_roles[0].role_name === UserRolesNames[roleNum],
      )[0]?.descriptive_text;
    },
    [],
  );

  return [getRoleDescription];
};
