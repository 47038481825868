import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { defaultValues, Model, model, schema } from './model';
import { ModalType } from 'modules/modals/constants';
import * as Question from 'components/Question';
import { Braintree } from 'modules/braintree';
import * as Button from 'components/Button';
import logger from 'modules/logger';

import styles from './styles.module.scss';

type AddCardFormProps = {
  onCancel: () => void;
  onSubmit: (data: any) => Promise<void>;
};

function AddCardForm({ onCancel, onSubmit }: AddCardFormProps) {
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const active = useRef(true);
  //TODO done useCallback
  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setIsApiSubmitting(true);
        await onSubmit(data);
      } catch (error) {
        if (active.current) {
          logger.error('Add Payment Method Error', error);
          modal.current = modalShow({
            onClose: () => {
              !!modal.current && modalHide(modal.current);
              modal.current = null;
            },
            title: "Oh my... There's a problem!",
            text: 'There was an error trying to add the payment method.',
            type: ModalType.INFO,
          });
        }
      } finally {
        active.current && setIsApiSubmitting(false);
      }
    },
    [onSubmit, modalHide, modalShow],
  );

  useEffect(() => {
    return () => {
      !!modal.current && modalHide(modal.current);
      modal.current = null;
    };
  }, [modalHide]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return (
    <div>
      <Braintree<Model>
        className={styles.addCardForm}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        title="Card information"
        schema={schema}
      >
        {({ control, isFormLoading, isFormSubmitting }) => (
          <React.Fragment>
            <div className={styles.makeDefault}>
              <Question.Checkbox
                control={control}
                isSubmitting={
                  isApiSubmitting || isFormLoading || isFormSubmitting
                }
                label="Set as primary payment method"
                question={model.makeDefault}
              />
            </div>

            <div className={styles.buttons}>
              {(isApiSubmitting || isFormSubmitting) && (
                <div className={styles.spinner}></div>
              )}

              <Button.Generic
                colour={ButtonColour.BRAND_MONO_ONE}
                disabled={isApiSubmitting || isFormLoading || isFormSubmitting}
                label="Cancel"
                onClick={onCancel}
                style={ButtonStyle.SECONDARY}
              />

              <Button.Submit
                colour={ButtonColour.BRAND_BLACK_GRAPE}
                disabled={isApiSubmitting || isFormLoading || isFormSubmitting}
                label="Save"
                showSpinner={false}
                style={ButtonStyle.SECONDARY}
              />
            </div>
          </React.Fragment>
        )}
      </Braintree>
    </div>
  );
}

export default React.memo(AddCardForm) as typeof AddCardForm;
