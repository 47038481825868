import { useCallback, useEffect, useState } from 'react';

import { API, api } from 'modules/api';

import { ChargebeeSubscriptionPlanId } from '../constants';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TriggerGTMEvent } from 'modules/gtm';
import { useErrorModal } from 'modules/modals/hooks/useErrorModal';
import {
  UserSubscriptionPlanGetResponse,
  UserSubscriptionPlanType,
} from 'modules/api/endpoints/user-subscription-plan';
import logger from 'modules/logger';
import { APIExceptions } from 'modules/api/exceptions';

export const useSubscriptionPlan = (
  country: string,
  couponCodeApplied: boolean,
  planId: ChargebeeSubscriptionPlanId,
  setCouponCodeApplied: React.Dispatch<React.SetStateAction<boolean>>,
  setCouponCodePreviouslyApplied: React.Dispatch<React.SetStateAction<boolean>>,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  value: string,
) => {
  const [subscriptionPlan, setSubscriptionPlan] =
    useState<UserSubscriptionPlanType | null>(null);
  const [couponCode, setCouponCode] = useState('');
  const [couponCodeMessage, setCouponCodeMessage] = useState('');
  const [couponCodeSubmitting, setCouponCodeSubmitting] = useState(false);
  const [displayModal] = useErrorModal();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(() => {
    if (couponCodeApplied) {
      setValue('');
      setCouponCode('');
      setCouponCodeApplied(false);
      logger.debug('Subscription Plan - Remove Voucher Code');
    } else {
      setCouponCode(value);
      setCouponCodeApplied(true);
      setCouponCodeSubmitting(true);
      logger.debug('Subscription Plan - Apply Voucher Code');
    }
  }, [couponCodeApplied, setCouponCodeApplied, setValue, value]);

  const applyCoupon = useCallback(
    (res: UserSubscriptionPlanGetResponse) => {
      if (res.data?.couponCode !== couponCode) {
        setValue(res.data?.couponCode ? res.data?.couponCode : '');
        setCouponCodePreviouslyApplied(
          res.data?.couponCodePreviouslyApplied ? true : false,
        );
        const couponCodeSuccesfullyApplied = res.data?.couponCode
          ? true
          : false;
        if (couponCodeSuccesfullyApplied) {
          setCouponCodeApplied(true);
          TriggerGTMEvent({
            action: EventsActions.VOUCHER_CODE_ADDED,
            category: EventsCategories.SIGN_UP,
            label: '',
          });
        }
      }
    },
    [
      couponCode,
      setCouponCodeApplied,
      setCouponCodePreviouslyApplied,
      setValue,
    ],
  );

  const couponIsExpired = useCallback(
    (error: any): boolean => {
      let result = false;
      if (error.response?.data?.type === APIExceptions.CouponCodeHasExpired) {
        result = true;
        setCouponCodeApplied(false);
        setCouponCodeMessage('Expired voucher code');
      }

      return result;
    },
    [setCouponCodeApplied],
  );

  //TODO done useEffect

  useEffect(() => {
    if (planId) {
      let active = true;
      (async () => {
        try {
          const params = {
            CountryCode: country,
            SubscriptionPlanId: planId,
            CouponCode: couponCode,
          };
          logger.debug('Subscription Plan - Init', params);
          setIsLoading(true);
          const res = await api(API.GET_USER_SUBSCRIPTION_PLAN(params));
          if (active) {
            setSubscriptionPlan(res.data);
            logger.debug('Subscription Plan - Success:', res);
            applyCoupon(res);
            if (res.data?.couponCode && !res.data?.couponCodeIsValid) {
              setCouponCodeApplied(false);
              setCouponCodeMessage('Invalid voucher code');
            } else {
              setCouponCodeMessage('');
            }
          }
        } catch (error: any) {
          if (active) {
            logger.error('Subscription Plan - Error');

            if (couponIsExpired(error)) {
              return;
            }

            setSubscriptionPlan({} as UserSubscriptionPlanType);
            displayModal(
              `Oh my... There's a problem!`,
              `Please try again or contact us if the problem persists.`,
            );
          }
        } finally {
          if (active) {
            setIsLoading(false);
            setCouponCodeSubmitting(false);
            logger.debug('Subscription Plan - Finished');
          }
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [
    applyCoupon,
    country,
    couponCode,
    couponIsExpired,
    displayModal,
    planId,
    setCouponCodeApplied,
  ]);

  return {
    couponCode,
    couponCodeMessage,
    couponCodeSubmitting,
    handleClick,
    isLoading,
    subscriptionPlan,
  } as const;
};
