/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'modules/redux/store';

import { modalActionDestroy, modalActionHide } from './actions';
import { ModalConfirmProps } from 'components/Modal/Confirm';
import { ModalCpdClaimProps } from 'components/Modal/CpdClaim';
import { ModalEmailChangeProps } from 'components/Modal/EmailChange';
import { ModalErrorProps } from 'components/Modal/Error';
import { ModalFormProps } from 'components/Modal/Form';
import { ModalInfoProps } from 'components/Modal/Info';
import { ModalLapsedUserProps } from 'components/Modal/LapsedUser';
import { ModalPCertPassportErrorProps } from 'components/Modal/PCertPassportError';
import { ModalRolesProps } from 'components/Modal/Roles';
import { ModalType } from './constants';
import * as Modal from 'components/Modal';

import styles from './styles.module.scss';

type RenderModalProps = {
  type: ModalType;
} & (
  | ({ type: ModalType.CONFIRM } & ModalConfirmProps)
  | ({ type: ModalType.FORM } & ModalFormProps)
  | ({ type: ModalType.INFO } & ModalInfoProps)
  | ({ type: ModalType.EMAIL_CHANGE } & ModalEmailChangeProps)
  | ({ type: ModalType.ERROR } & ModalErrorProps)
  | ({ type: ModalType.ROLES } & ModalRolesProps)
  | ({ type: ModalType.CPD_CLAIM } & ModalCpdClaimProps)
  | ({ type: ModalType.LAPSED_USER } & ModalLapsedUserProps)
  | ({ type: ModalType.PCERT_PASSPORT_ERROR } & ModalPCertPassportErrorProps)
);

const renderModal = ({ type, ...props }: RenderModalProps) => {
  switch (type) {
    case ModalType.CPD_CLAIM:
      return (
        <Modal.CpdClaim key={props.id} {...(props as ModalCpdClaimProps)} />
      );
    case ModalType.CONFIRM:
      return <Modal.Confirm key={props.id} {...(props as ModalConfirmProps)} />;
    case ModalType.EMAIL_CHANGE:
      return (
        <Modal.EmailChange
          key={props.id}
          {...(props as ModalEmailChangeProps)}
        />
      );
    case ModalType.ERROR:
      return <Modal.Error key={props.id} {...(props as ModalErrorProps)} />;
    case ModalType.FORM:
      return <Modal.Form key={props.id} {...(props as ModalFormProps)} />;
    case ModalType.INFO:
      return <Modal.Info key={props.id} {...(props as ModalInfoProps)} />;
    case ModalType.LAPSED_USER:
      return (
        <Modal.LapsedUser key={props.id} {...(props as ModalLapsedUserProps)} />
      );
    case ModalType.ROLES:
      return <Modal.Roles key={props.id} {...(props as ModalRolesProps)} />;
    case ModalType.PCERT_PASSPORT_ERROR:
      return (
        <Modal.PCertPassportError
          key={props.id}
          {...(props as ModalPCertPassportErrorProps)}
        />
      );
    default:
      return null;
  }
};

type ModalsProps = {};

const Modals: React.FunctionComponent<ModalsProps> = () => {
  const modals = useSelector((state: RootState) => state.modals);
  const dispatch: Dispatch = useDispatch();
  return (
    <div className={styles['modals']}>
      {modals.map(modal =>
        renderModal({
          destroyModal: () => dispatch(modalActionDestroy(modal.id)),
          hideModal: () => dispatch(modalActionHide(modal.id)),
          ...modal,
        }),
      )}
    </div>
  );
};

export default Modals;
