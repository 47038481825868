import clsx from 'clsx';
import React from 'react';

import curatedStyles from '../styles.module.scss';
import itemStyles from '../CuratedPagesItem/styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const className = clsx(styles.skeleton, curatedStyles.curatedPages);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading Curated Posts
      </span>
      <div className={className} aria-hidden="true">
        <span></span>
        <div>
          <div className={itemStyles.curatedPagesItem}>
            <div className={itemStyles.image}>
              <div></div>
            </div>
            <div className={itemStyles.content}>
              <div>
                <p></p>
                <span></span>
              </div>
              <div></div>
            </div>
          </div>
          <div className={itemStyles.curatedPagesItem}>
            <div className={itemStyles.image}>
              <div></div>
            </div>
            <div className={itemStyles.content}>
              <div>
                <p></p>
                <span></span>
              </div>
              <div></div>
            </div>
          </div>
          <div className={itemStyles.curatedPagesItem}>
            <div className={itemStyles.image}>
              <div></div>
            </div>
            <div className={itemStyles.content}>
              <div>
                <p></p>
                <span></span>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
