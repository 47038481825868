import { filterType } from './types';

export enum SortByTypes {
  None = '',
  Date_ASC = 'publicationDate_asc',
  Date_DESC = 'publicationDate_desc',
  Likes = 'likes_desc',
  ReadTime_ASC = 'timeToRead_asc',
  ReadTime_DESC = 'timeToRead_desc',
  Alphabetically_ASC = 'title_asc',
  Alphabetically_DESC = 'title_desc',
  Author_ASC = 'author_asc',
  Author_DESC = 'author_desc',
}

export const slugIsFilter = (
  bestPractices: filterType,
  contentTypes: filterType,
  slug: string,
  topics: filterType,
): boolean => {
  const indexBestPractices = bestPractices?.data?.findIndex(
    element => element.slug === slug,
  );
  const indexContentTypes = contentTypes?.data?.findIndex(
    element => element.slug === slug,
  );
  const indexTopics = topics?.data?.findIndex(element => element.slug === slug);

  return indexBestPractices > -1 || indexContentTypes > -1 || indexTopics > -1;
};

export const sortByType = (arr: any, sortByValue: SortByTypes) => {
  if (sortByValue === SortByTypes.None) {
    return arr;
  }

  const sortByVal = sortByValue.split('_')[0];
  const sortByType = sortByValue.split('_')[1];

  const newArr = arr?.sort((a: any, b: any) => {
    if (a[sortByVal] === b[sortByVal]) return 0;

    return a[sortByVal] > b[sortByVal] ? 1 : -1;
  });

  return sortByType === 'asc' ? newArr : newArr.reverse();
};
