export const pointsOptions = [
  {
    label: '0.5 Points',
    value: '0.5',
  },
  {
    label: '1.0 Points',
    value: '1.0',
  },
  {
    label: '1.5 Points',
    value: '1.5',
  },
  {
    label: '2.0 Points',
    value: '2.0',
  },
  {
    label: '2.5 Points',
    value: '2.5',
  },
  {
    label: '3.0 Points',
    value: '3.0',
  },
  {
    label: '3.5 Points',
    value: '3.5',
  },
  {
    label: '4.0 Points',
    value: '4.0',
  },
  {
    label: '4.5 Points',
    value: '4.5',
  },
  {
    label: '5.0 Points',
    value: '5.0',
  },
];
