/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import clsx from 'clsx';

import { ReactComponent as TickSVG } from 'modules/theme/icons/general/tick.svg';
import { CheckboxProps } from './types';
import Label from 'components/Label';

import styles from './styles.module.scss';

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  disabled,
  children,
  checked,
  forwardedRef,
  id,
  invalid,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  readOnly,
  value,
}) => {
  const className = clsx(
    styles.checkbox,
    disabled && styles.disabled,
    !disabled && invalid && styles.invalid,
    readOnly && styles.readOnly,
  );
  const handleMouseDown = () => {
    onChange(value as any);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === 'Enter') {
      onChange(value as any);
      event.stopPropagation();
    }
  };
  return (
    <div className={className} ref={forwardedRef}>
      <input
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        tabIndex={-1}
        type="checkbox"
        value={String(value)}
      />
      <TickSVG />
      {label && (
        <Label
          id={id}
          label={label}
          onClick={handleMouseDown}
          onKeyDown={handleKeyDown}
        >
          {children}
        </Label>
      )}
    </div>
  );
};

export default React.memo(Checkbox);
