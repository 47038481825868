import React from 'react';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import CtaButton from 'components/Cta/Button';
import { RESOURCE_HUB_URL } from 'modules/utils/constants';
import { ReactComponent as ErrorIcon } from 'modules/theme/ims/no-bookmarks.svg';

import styles from './styles.module.scss';

const NoBookmarksFound: React.FunctionComponent = () => {
  return (
    <div className="container small">
      <div className={styles.noResults}>
        <ErrorIcon
          className={styles.bookmarkIcon}
          aria-hidden="true"
          focusable="false"
        />
        <h2>
          You have no resources bookmarked yet!
          <br /> Click or tap the bookmark icon on the resource titles to add
          some.
        </h2>
        <CtaButton
          className={styles.link}
          label="See resources"
          page_link={RESOURCE_HUB_URL}
          colour={ButtonColour.BRAND_BLACK_GRAPE}
          style={ButtonStyle.SECONDARY}
        />
      </div>
    </div>
  );
};

export default NoBookmarksFound;
