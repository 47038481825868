import React from 'react';
import { IntroContentVideoWithCaption } from '../../types';
import styles from './styles.module.scss';

type VideoWithCaptionPropType = {
  component: IntroContentVideoWithCaption;
};
export const VideoWithCaption: React.FunctionComponent<VideoWithCaptionPropType> =
  ({ component }) => {
    const vimeo =
      component.use_video_id === 'Vimeo' &&
      component.vimeo_id !== null &&
      `https://player.vimeo.com/video/${component.vimeo_id}`;
    const youtube =
      component.use_video_id === 'Youtube' &&
      component.youtube_id !== null &&
      `https://www.youtube.com/embed/${component.youtube_id}`;
    return (
      <div className={styles['video-with-caption']}>
        <figure>
          <div className={styles.iframeContainer}>
            {vimeo && <iframe src={vimeo} title={component.vimeo_id} />}
            {youtube && <iframe src={youtube} title={component.youtube_id} />}
          </div>
          {component.video_caption && (
            <figcaption>{component.video_caption}</figcaption>
          )}
        </figure>
      </div>
    );
  };
