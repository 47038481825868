import React, { RefObject } from 'react';
import { DeepPartial, UnpackNestedValue } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import clsx from 'clsx';
import ReCAPTCHA from 'react-google-recaptcha';

import { AccountDataType } from 'modules/utils/types';
import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import { EnquiryOptionsItem, IFaqs } from './types';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { LayoutSize } from 'components/Layout/constants';
import { Model } from './model';
import { QuestionType } from 'components/Question/Template/types';
import { TriggerGTMEvent } from 'modules/gtm';
import * as Button from 'components/Button';
import * as Layout from 'components/Layout';
import * as Question from 'components/Question';
import Accordion, { AccordionItemProps } from 'components/Accordion';
import Questions from 'components/Questions';
import useSendFormData from './hooks/sendFormData';

import styles from './styles.module.scss';

type TemplateProps = {
  faqsEntries?: IFaqs;
  getAccordionList: AccordionItemProps[];
  recaptchaRef: RefObject<ReCAPTCHA>;
  recaptchaLoaded: (value: string | null) => void;
  accountDataContext?: AccountDataType;
  formProps: {
    defaultValues: Model;
    schema: ObjectSchema;
  };
  formRef: RefObject<HTMLDivElement>;
  executeRecaptcha: () => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  recaptchaResponse: object | null;
  model: Record<string, QuestionType<Model>>;
  isSubmitting: boolean;
  loaded: boolean;
  enquiryOptions: EnquiryOptionsItem[];
  id: string;
  defaultValues: UnpackNestedValue<DeepPartial<Model>>;
  formType: string;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  faqsEntries,
  getAccordionList,
  recaptchaRef,
  recaptchaLoaded,
  accountDataContext,
  formProps,
  formRef,
  executeRecaptcha,
  recaptchaResponse,
  model,
  isSubmitting,
  loaded,
  enquiryOptions,
  id,
  defaultValues,
  formType,
  setIsSubmitting,
  title,
}) => {
  const formClass = clsx(
    styles.form,
    faqsEntries && faqsEntries?.entries.length > 0 && styles['has-accordion'],
  );

  const handleQuestionExpanded = (questionTitle: string) => {
    TriggerGTMEvent({
      action: EventsActions.EXPAND_QUESTION,
      category: EventsCategories.FAQ,
      label: questionTitle,
    });
  };

  return (
    <Layout.Container className={styles.faq} size={LayoutSize.SMALL}>
      <h2>FAQs</h2>
      {getAccordionList && (
        <Accordion
          loaded={loaded}
          items={getAccordionList}
          onClick={handleQuestionExpanded}
        />
      )}
      <div ref={formRef} className={formClass}>
        <h2>Do you require further assistance?</h2>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY!}
          onChange={recaptchaLoaded}
        />

        {accountDataContext && (
          <Questions
            defaultValues={formProps.defaultValues}
            onSubmit={executeRecaptcha}
            schema={formProps.schema}
          >
            {({ control, reset, values }) => (
              <>
                <div className={styles['form-group']}>
                  <Question.Text
                    control={control}
                    label="Your full name*"
                    question={model.fullName}
                    isSubmitting={isSubmitting}
                  />
                  <Question.Text
                    control={control}
                    label="Email*"
                    question={model.email}
                    isSubmitting={isSubmitting}
                  />
                </div>
                <div className={styles['form-group']}>
                  <Question.Text
                    control={control}
                    isSubmitting={isSubmitting}
                    label="Organization"
                    question={model.organizationName}
                  />
                  <Question.Select
                    control={control}
                    isSubmitting={isSubmitting}
                    label="Reason for enquiry*"
                    options={enquiryOptions}
                    question={model.reasonForEnquiry}
                  />
                </div>

                <div
                  className={`${styles['form-row-span']} ${styles['form-group']}`}
                >
                  <div className={styles['group-span-two']}>
                    <Question.Text
                      control={control}
                      isSubmitting={isSubmitting}
                      label="Job title"
                      question={model.jobTitle}
                    />
                    <Question.Text
                      control={control}
                      isSubmitting={isSubmitting}
                      label="Candidate number (if known)"
                      question={model.candidateNumber}
                    />
                    <Question.Text
                      control={control}
                      isSubmitting={isSubmitting}
                      label="Tel. no"
                      question={model.telephoneNumber}
                    />
                  </div>
                  <Question.Textarea
                    control={control}
                    isSubmitting={isSubmitting}
                    label="Description of enquiry*"
                    question={model.descriptionOfEnquiry}
                  />
                </div>
                <p className={styles['recaptcha-pol-text']}>
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a
                    href="https://policies.google.com/privacy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {' '}
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://policies.google.com/terms"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {' '}
                    Terms of Service
                  </a>{' '}
                  apply.
                </p>
                <div>
                  <Button.Submit
                    disabled={isSubmitting}
                    label="Send"
                    onClick={() =>
                      TriggerGTMEvent({
                        action: EventsActions.CLICK_SEND_BUTTON,
                        category: EventsCategories.HELP,
                        label: '',
                      })
                    }
                    isSubmitting={isSubmitting}
                    colour={ButtonColour.BRAND_BLACK_GRAPE}
                    style={ButtonStyle.SECONDARY}
                  />
                </div>
                {/* eslint-disable-next-line */}
                {useSendFormData({
                  id,
                  defaultValues,
                  formType,
                  recaptchaResponse,
                  reset,
                  setIsSubmitting,
                  title,
                  values,
                })}
              </>
            )}
          </Questions>
        )}
      </div>
    </Layout.Container>
  );
};

export default Template;
