import { useCallback, useContext, useMemo, useState } from 'react';

import { AccountDataContext } from 'modules/context';
import { defaultValues, Model } from '../QuestionSet/model';
import { SubscriptionStateEnum } from 'modules/utils/types';
import { UserSubscriptionPlanType } from 'modules/api/endpoints/user-subscription-plan';
import useSubscribeUser from 'pages/sign-up/hooks/useSubscribeUser';

type useSubscribeProps = {
  requiresCountry: boolean;
  subscriptionPlan: UserSubscriptionPlanType | null;
  subscriptionState: SubscriptionStateEnum;
};

function useSubscribe({
  requiresCountry,
  subscriptionPlan,
  subscriptionState,
}: useSubscribeProps) {
  const { subscribeExistingUser, isSubmitting } = useSubscribeUser();
  const [activeStep, setActiveStep] = useState(requiresCountry ? 1 : 2);

  const { accountDataContext } = useContext(AccountDataContext);

  const accountDataValues = useMemo(() => {
    return {
      email: accountDataContext?.email ?? '',
      givenName: accountDataContext?.firstName ?? '',
      surname: accountDataContext?.lastName ?? '',
    };
  }, [accountDataContext]);

  const [formData, setFormData] = useState<Model>({
    ...defaultValues,
    ...accountDataValues,
  });

  const handleBack = useCallback(() => {
    window.scrollTo(0, 0);
    setActiveStep(1);
  }, []);
  //TODO removed async
  const handleNext = useCallback((data: any) => {
    setFormData(data);
    window.scrollTo(0, 0);
    setActiveStep(2);
  }, []);
  //TODO done useCallback
  const handleSubmitStep2 = useCallback(
    async (data: Model & { nonce: string }) => {
      await subscribeExistingUser(
        {
          candidateNumber: data.candidateNumber,
          candidateToken: subscriptionPlan?.candidateToken,
          countryCode: requiresCountry
            ? subscriptionPlan?.countryCode
            : data.countryCode,
          couponCode: subscriptionPlan?.couponCode,
          email: data.email,
          givenName: data.givenName,
          paymentNonce: data.nonce,
          subscriptionPlanId: subscriptionPlan?.subscriptionPlanId,
          surname: data.surname,
        },
        subscriptionState,
      );
    },
    [
      requiresCountry,
      subscribeExistingUser,
      subscriptionPlan,
      subscriptionState,
    ],
  );

  return {
    activeStep,
    defaultValues: formData,
    handleBack,
    handleNext,
    handleSubmitStep2,
    isSubmitting,
  };
}

export default useSubscribe;
