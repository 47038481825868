import { AccountDataType } from 'modules/utils/types';
import { useProfilePhoto } from 'pages/account-management/components/Summary/hooks';
import placeholderPhoto from 'modules/theme/ims/default-avatar.svg';

export const useProfile = (accountDataContext?: AccountDataType) => {
  const { profilePhoto } = useProfilePhoto(accountDataContext);
  const companyName = accountDataContext?.company
    ? ` from ${accountDataContext?.company}`
    : ``;
  const accessibleTitle = `Logged in as ${accountDataContext?.name}${companyName}`;

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) =>
    (e.currentTarget.src = placeholderPhoto);

  const photo = profilePhoto || placeholderPhoto;

  return {
    accessibleTitle,
    companyName,
    handleImageError,
    photo,
  } as const;
};
