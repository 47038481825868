import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

import { Carousel } from 'react-responsive-carousel';
import { CuratedPageType } from 'pages/curated-pages/types';
import { CURATED_FOR_YOU } from 'modules/utils/constants';
import { PageReferenceProps } from 'components/Cta/types';
import CuratedPagesItem from './CuratedPagesItem';
import Skeleton from './Skeleton';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './styles.module.scss';

export type Publication = {
  cta: { label: string; custom_url: string; page_link: PageReferenceProps[] };
  custom_url: string;
  image: { url: string };
  preview_description: string;
  preview_title: string;
  price: string;
};

type CuratedPagesProp = {
  curatedPages?: CuratedPageType[];
  loaded: boolean;
};

const CuratedPages: React.FunctionComponent<CuratedPagesProp> = ({
  curatedPages,
  loaded,
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: 1152px) and (min-width: 768px), (max-width: 520px)`,
  });

  const getCuratedPages = useCallback(
    () =>
      curatedPages &&
      curatedPages.map((curatedPage: CuratedPageType, index: number) => (
        <CuratedPagesItem
          curatedPage={curatedPage}
          key={`curated-page-card-${index}`}
        />
      )),
    [curatedPages],
  );

  return loaded ? (
    <React.Fragment>
      {curatedPages && (
        <section className={styles.curatedPages}>
          <h2>{CURATED_FOR_YOU}</h2>
          <div>
            {isMobile && curatedPages.length > 1 ? (
              <Carousel
                emulateTouch={true}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  return (
                    <li
                      aria-label={`${label} ${index + 1}`}
                      className={clsx('dot', isSelected && 'selected')}
                      key={index}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      tabIndex={-1}
                    ></li>
                  );
                }}
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                swipeScrollTolerance={20}
              >
                {getCuratedPages()}
              </Carousel>
            ) : (
              <React.Fragment>{getCuratedPages()}</React.Fragment>
            )}
          </div>
        </section>
      )}
    </React.Fragment>
  ) : (
    <Skeleton />
  );
};

export default CuratedPages;
