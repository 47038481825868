import React from 'react';

import { LoginTypeEnum } from 'modules/utils/types';
import { ReactComponent as ConsultantLogo } from 'modules/theme/ims/logos/MyAxelosConsultantLogo.min.svg';
import { ReactComponent as EmployeeLogo } from 'modules/theme/ims/logos/MyAxelosOrganizationsLogo.min.svg';
import { ReactComponent as IndividualLogo } from 'modules/theme/ims/logos/MyAxelosLogo.min.svg';

type LogoIconsProps = {
  role?: LoginTypeEnum;
};

const LogoIcons: React.FunctionComponent<LogoIconsProps> = ({ role }) => {
  switch (role) {
    case LoginTypeEnum.CONSULTANT:
      return <ConsultantLogo />;
    case LoginTypeEnum.EMPLOYEE:
      return <EmployeeLogo />;
    default:
      return <IndividualLogo />;
  }
};

export default React.memo(LogoIcons);
