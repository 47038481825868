import React, { memo } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

type SliderSlideProps = {
  children: React.ReactNode;
  index: number;
  slidePosition?: number;
  totalSildes: number;
};

const SliderSlide: React.FunctionComponent<SliderSlideProps> = ({
  children,
  index,
  slidePosition,
  totalSildes,
}) => {
  const classNames = clsx(
    styles.sliderSlides,
    slidePosition !== index && styles.nextSlide,
    slidePosition === index &&
      slidePosition !== totalSildes - 1 &&
      styles.activeSlide,
  );

  return (
    <li className={classNames}>
      <ul>{children}</ul>
    </li>
  );
};

export default memo(SliderSlide);
