import React from 'react';
import clsx from 'clsx';

import earnedStyles from '../styles.module.scss';
import earnedItemStyles from '../EarnedBadgesItem/styles.module.scss';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(styles.skeleton, earnedStyles.earnedBadges);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading earned badges
      </span>
      <ul className={classNames} aria-hidden="true">
        <li className={earnedItemStyles.earnedBadgesItem}>
          <div></div>
          <span></span>
          <div></div>
        </li>
        <li className={earnedItemStyles.earnedBadgesItem}>
          <div></div>
          <span></span>
          <div></div>
        </li>
        <li className={earnedItemStyles.earnedBadgesItem}>
          <div></div>
          <span></span>
          <div></div>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Skeleton;
