import React from 'react';
import clsx from 'clsx';

import Buttons from './components/Buttons';
import Modals from './components/Modals';

import styles from './components/styles.module.scss';

const Template: React.FunctionComponent = () => {
  const classNames = clsx(styles.debug, 'container');
  return (
    <div className={classNames}>
      <Modals />
      <Buttons />
    </div>
  );
};
export default Template;
