export type BraintreeFields = {
  [key: string]: {
    container: string;
    placeholder?: string;
    prefill: string;
    type?: 'text' | 'password';
    select?: boolean;
  };
};

const defaultFields: BraintreeFields = {
  cardholderName: {
    container: '#cardholder-name',
    prefill: '',
    type: 'text',
  },
  number: {
    container: '#card-number',
    prefill: '',
    type: 'text',
  },
  cvv: {
    container: '#cvv',
    prefill: '',
    type: 'password',
  },
  expirationMonth: {
    container: '#expiration-month',
    placeholder: '',
    prefill: '',
    select: true,
  },
  expirationYear: {
    container: '#expiration-year',
    placeholder: '',
    prefill: '',
    select: true,
  },
};

const fields = (defaultValues?: Record<string, any>): BraintreeFields => {
  const fields = Object.assign({}, defaultFields);
  Object.entries(fields).forEach(
    ([key]) =>
      (fields[key].prefill =
        defaultValues && defaultValues[key] ? defaultValues[key] : ''),
  );
  return fields;
};

export default fields;
