import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as Liked } from 'modules/theme/icons/misc/card-like.svg';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 576px)',
  });
  const isTablet = useMediaQuery({
    query: '(max-width: 896px)',
  });
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading your bookmarks
      </span>
      <div className={styles.skeleton} aria-hidden="true">
        <span></span>
        {/* Threecol */}
        {!isTablet && (
          <div className={styles.threeColumn}>
            <ul>
              <li className={styles.skeletonItem}>
                <span></span>
                <div>
                  <header>
                    <span></span>
                    <div>
                      <span></span>
                    </div>
                  </header>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <div className={styles.copy}>
                    <p></p>
                  </div>
                  <footer>
                    {isMobile && (
                      <div className={styles.skeletonSocial}>
                        <div className={styles.bookmark}>
                          <span></span>
                        </div>
                        <div className={styles.likes}>
                          <span>
                            <Liked />
                          </span>
                          <span></span>
                        </div>
                      </div>
                    )}
                    {isMobile ? (
                      <span className={styles.hyperlink}></span>
                    ) : (
                      <span className={styles.button}></span>
                    )}
                  </footer>
                </div>
              </li>
              <li className={styles.skeletonItem}>
                <span></span>
                <div>
                  <header>
                    <span></span>
                    <div>
                      <span></span>
                    </div>
                  </header>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <div className={styles.copy}>
                    <p></p>
                  </div>
                  <footer>
                    {isMobile && (
                      <div className={styles.skeletonSocial}>
                        <div className={styles.bookmark}>
                          <span></span>
                        </div>
                        <div className={styles.likes}>
                          <span>
                            <Liked />
                          </span>
                          <span></span>
                        </div>
                      </div>
                    )}
                    {isMobile ? (
                      <span className={styles.hyperlink}></span>
                    ) : (
                      <span className={styles.button}></span>
                    )}
                  </footer>
                </div>
              </li>
              <li className={styles.skeletonItem}>
                <span></span>
                <div>
                  <header>
                    <span></span>
                    <div>
                      <span></span>
                    </div>
                  </header>
                  {!isMobile && (
                    <div className={styles.skeletonSocial}>
                      <div className={styles.bookmark}>
                        <span></span>
                      </div>
                      <div className={styles.likes}>
                        <span>
                          <Liked />
                        </span>
                        <span></span>
                      </div>
                    </div>
                  )}
                  <div className={styles.copy}>
                    <p></p>
                  </div>
                  <footer>
                    {isMobile && (
                      <div className={styles.skeletonSocial}>
                        <div className={styles.bookmark}>
                          <span></span>
                        </div>
                        <div className={styles.likes}>
                          <span>
                            <Liked />
                          </span>
                          <span></span>
                        </div>
                      </div>
                    )}
                    {isMobile ? (
                      <span className={styles.hyperlink}></span>
                    ) : (
                      <span className={styles.button}></span>
                    )}
                  </footer>
                </div>
              </li>
            </ul>
          </div>
        )}
        {/* Full */}
        <div className={styles.fullWidth}>
          <ul>
            {!isTablet ? (
              <React.Fragment>
                <li className={styles.skeletonItem}>
                  <span></span>
                  <div>
                    <header>
                      <p className={styles.publication}>
                        <span></span>
                      </p>
                      {!isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </header>
                    <div className={styles.copy}>
                      <span className={styles.headingThree}></span>
                      {!isMobile && <p></p>}
                    </div>
                    <footer>
                      <span className={styles.hyperlink}></span>
                    </footer>
                  </div>
                </li>
                <li className={styles.skeletonItem}>
                  <span></span>
                  <div>
                    <header>
                      <p className={styles.publication}>
                        <span></span>
                      </p>
                      {!isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </header>
                    <div className={styles.copy}>
                      <span className={styles.headingThree}></span>
                      {!isMobile && <p></p>}
                    </div>
                    <footer>
                      <span className={styles.hyperlink}></span>
                    </footer>
                  </div>
                </li>
                <li className={styles.skeletonItem}>
                  <span></span>
                  <div>
                    <header>
                      <p className={styles.publication}>
                        <span></span>
                      </p>
                      {!isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </header>
                    <div className={styles.copy}>
                      <span className={styles.headingThree}></span>
                      {!isMobile && <p></p>}
                    </div>
                    <footer>
                      <span className={styles.hyperlink}></span>
                    </footer>
                  </div>
                </li>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li className={styles.skeletonItem}>
                  <span></span>
                  <div>
                    <header>
                      <p className={styles.publication}>
                        <span></span>
                      </p>
                      {!isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </header>
                    <div className={styles.copy}>
                      <span className={styles.headingThree}></span>
                      {!isMobile && <p></p>}
                    </div>
                    <footer>
                      {isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                      <span className={styles.hyperlink}></span>
                    </footer>
                  </div>
                </li>
                <li className={styles.skeletonItem}>
                  <span></span>
                  <div>
                    <header>
                      <p className={styles.publication}>
                        <span></span>
                      </p>
                      {!isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </header>
                    <div className={styles.copy}>
                      <span className={styles.headingThree}></span>
                      {!isMobile && <p></p>}
                    </div>
                    <footer>
                      {isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                      <span className={styles.hyperlink}></span>
                    </footer>
                  </div>
                </li>
                <li className={styles.skeletonItem}>
                  <span></span>
                  <div>
                    <header>
                      <p className={styles.publication}>
                        <span></span>
                      </p>
                      {!isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </header>
                    <div className={styles.copy}>
                      <span className={styles.headingThree}></span>
                      {!isMobile && <p></p>}
                    </div>
                    <footer>
                      {isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                      <span className={styles.hyperlink}></span>
                    </footer>
                  </div>
                </li>
                <li className={styles.skeletonItem}>
                  <span></span>
                  <div>
                    <header>
                      <p className={styles.publication}>
                        <span></span>
                      </p>
                      {!isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </header>
                    <div className={styles.copy}>
                      <span className={styles.headingThree}></span>
                      {!isMobile && <p></p>}
                    </div>
                    <footer>
                      {isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                      <span className={styles.hyperlink}></span>
                    </footer>
                  </div>
                </li>
                <li className={styles.skeletonItem}>
                  <span></span>
                  <div>
                    <header>
                      <p className={styles.publication}>
                        <span></span>
                      </p>
                      {!isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </header>
                    <div className={styles.copy}>
                      <span className={styles.headingThree}></span>
                      {!isMobile && <p></p>}
                    </div>
                    <footer>
                      {isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                      <span className={styles.hyperlink}></span>
                    </footer>
                  </div>
                </li>
                <li className={styles.skeletonItem}>
                  <span></span>
                  <div>
                    <header>
                      <p className={styles.publication}>
                        <span></span>
                      </p>
                      {!isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                    </header>
                    <div className={styles.copy}>
                      <span className={styles.headingThree}></span>
                      {!isMobile && <p></p>}
                    </div>
                    <footer>
                      {isMobile && (
                        <div className={styles.skeletonSocial}>
                          <div className={styles.bookmark}>
                            <span></span>
                          </div>
                          <div className={styles.likes}>
                            <span>
                              <Liked />
                            </span>
                            <span></span>
                          </div>
                        </div>
                      )}
                      <span className={styles.hyperlink}></span>
                    </footer>
                  </div>
                </li>
              </React.Fragment>
            )}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
