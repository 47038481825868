import { PaymentInfoType } from '../types';
import { Model } from './model';

export function mergeDefaultValues(
  defaultValues: Model,
  paymentMethod: PaymentInfoType,
): Model {
  return {
    ...defaultValues,

    billingFirstName: paymentMethod.paymentMethodAddress.firstName,
    billingLastName: paymentMethod.paymentMethodAddress.lastName,
    billingStreetAddress: paymentMethod.paymentMethodAddress.streetAddress,
    billingExtendedAddress: paymentMethod.paymentMethodAddress.extendedAddress,
    billingLocality: paymentMethod.paymentMethodAddress.locality,
    billingPostalCode: paymentMethod.paymentMethodAddress.postalCode,
    billingCountryCodeAlpha2:
      paymentMethod.paymentMethodAddress.countryCodeAlpha2,

    makeDefault: paymentMethod.paymentMethodDefault === 'True' ? true : false,
  };
}
