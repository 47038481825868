export enum APIExceptions {
  CandidateIdValidationException = 'CandidateIdValidationException',
  CandidateIdNotFoundException = 'CandidateIdNotFoundException',
  CandidateIdAlreadyExistsException = 'CandidateIdAlreadyExistsException',
  CandidateIdClaimCheckNoExamException = 'CandidateIdClaimCheckNoExamException',
  CandidateIdClaimCheckValidationException = 'CandidateIdClaimCheckValidationException',
  CouponCodeHasExpired = 'CouponCodeHasExpiredException',
  PeopleCertAuthenticationFailedException = 'PeopleCertAuthenticationFailedException',
  PeopleCertCandidateDemographicException = 'PeopleCertCandidateDemographicException',
  NoBadgeException = 'BadgeClaimNoPendingBadgesException',
  NoExamPassedException = 'BadgeClaimNoPassedExamException',
}
