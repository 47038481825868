import React from 'react';
import { useId } from 'react-id-generator';
import clsx from 'clsx';

import { EmailPreferenceToggleProps } from './types';

import styles from './styles.module.scss';

function EmailPreferenceToggle<T>({
  form,
  name,
  text,
  title,
}: EmailPreferenceToggleProps<T>) {
  const [id] = useId(1, 'toggle-');

  const updateMessageClassName = clsx({
    [styles.statusMessage]: true,
    [styles.statusIsError]: form.updateMessage.isError,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === 'Enter') {
      form.onChange();
      event.stopPropagation();
    }
  };

  return (
    <>
      <label
        className={styles.emailSettingsItem}
        htmlFor={id}
        onKeyDown={handleKeyDown}
        onMouseDown={e => e.preventDefault()}
        tabIndex={0}
      >
        <span>
          <strong>{title}</strong>
          <span>{text}</span>
        </span>
        <span>
          <input
            type="checkbox"
            id={id}
            checked={form.value}
            name={name}
            disabled={form.isSending}
            onChange={form.onChange}
            tabIndex={-1}
          />
          <span />
        </span>
      </label>
      {form.updateMessage.message && (
        <span role="status" className={updateMessageClassName}>
          {form.updateMessage.message}
        </span>
      )}
    </>
  );
}

export default React.memo(
  EmailPreferenceToggle,
) as typeof EmailPreferenceToggle;
