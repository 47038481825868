import React from 'react';
import { Control, useController } from 'react-hook-form';
import clsx from 'clsx';

import { PaymentInfoType } from 'pages/account-management/components/Subscriptions/PaymentInformation/types';
import { QuestionType } from 'components/Question/Template/types';
import QuestionPaymentMethodItem from './PaymentMethodItem';

import styles from './styles.module.scss';

type QuestionPaymentMethodProps<T> = {
  control: Control<T>;
  disabled?: boolean;
  isSubmitting?: boolean;
  paymentMethods: PaymentInfoType[];
  question: QuestionType<T>;
};

function QuestionPaymentMethod<T>({
  control,
  disabled,
  isSubmitting,
  paymentMethods,
  question,
}: QuestionPaymentMethodProps<T>) {
  const {
    field: { onChange, ref, value },
    fieldState: { error, invalid },
  } = useController({
    control,
    name: question.name,
  });
  const errorMessage = (error as any)?.message;
  const className = clsx(styles.questionPaymentMethod);
  return (
    <div className={className} ref={ref}>
      {paymentMethods.map(item => {
        return item.paymentMethodValid !== 'True' ? null : (
          <QuestionPaymentMethodItem
            cardNumber={item.paymentMethodIdentifier}
            cardType={item.paymentMethodType}
            defaultCard={item.paymentMethodDefault === 'True'}
            disabled={disabled || isSubmitting}
            invalid={invalid}
            key={item.paymentMethodId}
            onClick={() =>
              !disabled && !isSubmitting && onChange(item.paymentMethodId)
            }
            selected={value === item.paymentMethodId}
          />
        );
      })}
      {invalid && <p aria-live="polite">{errorMessage}</p>}
    </div>
  );
}

export default React.memo(
  QuestionPaymentMethod,
) as typeof QuestionPaymentMethod;
