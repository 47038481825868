import React, { useContext, useEffect } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { RemoveScroll } from 'react-remove-scroll';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';

import { ReactComponent as RoleIndividualSVG } from 'modules/theme/ims/roleIndividual.svg';
import { ReactComponent as RoleConsultantSVG } from 'modules/theme/ims/roleConsultant.svg';
import { ReactComponent as MyAxelosSVG } from 'modules/theme/ims/MyAxelosBlue.svg';

import PlatformContext from 'modules/platform/context';
import { LoginTypeEnum } from 'modules/utils/types';
import { ModalSize } from 'modules/modals/constants';
import { userRoleDescription, userRoleElement } from 'pages/auth/types';
import * as Button from 'components/Button';
import ModalOverlay from '../Overlay';

import styles from './styles.module.scss';
import modalStyles from 'modules/modals/styles.module.scss';
import { useRoleSelection } from './hooks';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});

export type ModalRolesTemplateProps = {
  onClick: (value: LoginTypeEnum) => void;
  size?: ModalSize;
  userRoles: userRoleElement[] | undefined;
  rolesDescription: userRoleDescription[];
};

export const ModalRolesTemplate = React.memo<ModalRolesTemplateProps>(
  ({ onClick, size = ModalSize.FULLWIDTH, userRoles, rolesDescription }) => {
    const classNames = clsx(modalStyles[`${size}`], styles.modalRoles);
    const [getRoleDescription] = useRoleSelection();
    const getIcon = (index: number) => {
      switch (index) {
        case LoginTypeEnum.INDIVIDUAL:
          return <RoleIndividualSVG />;
        case LoginTypeEnum.CONSULTANT:
          return <RoleConsultantSVG />;
      }
    };

    useEffect(() => {
      document.documentElement.classList.add('roles-modal-open');
    }, []);

    return (
      <section className={classNames} role="dialog">
        <div></div>
        <div>
          <p>Use MyAxelos as</p>
          <ul>
            {userRoles
              ?.filter((i: userRoleElement) => i.key !== LoginTypeEnum.EMPLOYEE)
              .map((i: userRoleElement) => {
                const roleDescription = getRoleDescription(
                  i.key,
                  rolesDescription,
                );
                return (
                  <li key={`user-role-${i.key}`}>
                    <Button.Generic
                      onClick={() => {
                        onClick(i.key);
                        document.documentElement.classList.remove(
                          'roles-modal-open',
                        );
                      }}
                      title={`Use MyAxelos as ${i.label}`}
                    >
                      {getIcon(i.key)}
                      <p>{i.label}</p>
                      {roleDescription && <span>{roleDescription}</span>}
                    </Button.Generic>
                  </li>
                );
              })}
          </ul>
        </div>
        <div>
          <MyAxelosSVG />
        </div>
      </section>
    );
  },
);

export type ModalRolesProps = {
  destroyModal: () => void;
  disableRestoreFocus?: boolean;
  hideModal: () => void;
  id: string;
  onClick: (value: LoginTypeEnum) => void;
  open: boolean;
  userRoles: userRoleElement[] | undefined;
  rolesDescription: any[];
};

const Roles: React.FunctionComponent<ModalRolesProps> = ({
  destroyModal,
  disableRestoreFocus,
  open,
  onClick,
  userRoles,
  rolesDescription,
}) => {
  const { isMobile } = useContext(PlatformContext);
  return (
    <RemoveScroll enabled={isMobile}>
      <ModalOverlay
        disableRestoreFocus={disableRestoreFocus}
        onExited={destroyModal}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        <ModalRolesTemplate
          onClick={onClick}
          userRoles={userRoles}
          rolesDescription={rolesDescription}
        />
      </ModalOverlay>
    </RemoveScroll>
  );
};

export default Roles;
