import React from 'react';

import { ReactComponent as ArrowSVG } from './arrow.svg';
import * as Button from 'components/Button';

import styles from './styles.module.scss';

export type AccountSettingsItemProps = {
  label: string;
  onClick: () => void;
  text: string;
  title: string;
};

const AccountSettingsItem: React.FunctionComponent<AccountSettingsItemProps> =
  ({ label, onClick, text, title }) => {
    return (
      <div className={styles.accountSettingsItem}>
        <span>{title}</span>
        <span>{text}</span>
        <span>
          <Button.Generic ariaLabel={label} onClick={onClick}>
            {label}
            <ArrowSVG />
          </Button.Generic>
        </span>
      </div>
    );
  };

export default AccountSettingsItem;
