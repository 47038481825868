import React from 'react';
import clsx from 'clsx';

import { LayoutVerticalPadding } from '../constants';

import styles from './styles.module.scss';

export type SidebarProps = {
  className?: string;
  children: React.ReactNode;
  verticalPadding?: LayoutVerticalPadding;
};

const Sidebar: React.FunctionComponent<SidebarProps> = ({
  className,
  children,
  verticalPadding = LayoutVerticalPadding.SMALL,
}) => {
  const classNames = clsx(
    className,
    styles.sidebar,
    verticalPadding === LayoutVerticalPadding.LARGE &&
      styles.verticalPaddingLarge,
  );
  return <aside className={classNames}>{children}</aside>;
};

export default Sidebar;
