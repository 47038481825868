import React, { memo } from 'react';

import { ReactComponent as SearchSVG } from 'modules/theme/icons/form/inputs/search.svg';
import { InputFormat } from '../constants';

type InputIconProps = {
  format: InputFormat;
};

const InputIcon: React.FC<InputIconProps> = ({ format }) => {
  if (format === InputFormat.SEARCH) {
    return <SearchSVG />;
  }

  return null;
};

export default memo(InputIcon);
