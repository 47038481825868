import { useContext, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { API, api } from 'modules/api';
import { CuratedPageResponse } from 'modules/api/endpoints/curated-page';
import { LoginTypeContext } from 'modules/context';
import { useErrorModal } from 'modules/modals/hooks/useErrorModal';
import logger from 'modules/logger';
import { LoginTypeEnum } from 'modules/utils/types';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TriggerGTMEvent } from 'modules/gtm';
import { CuratedLoadedType, CuratedPageType } from '../types';

export const useCuratedPage = (
  setLoaded: React.Dispatch<React.SetStateAction<CuratedLoadedType>>,
) => {
  const [displayModal] = useErrorModal();
  const [curatedPage, setCuratedPage] = useState<undefined | CuratedPageType>();
  const { loginTypeContext } = useContext(LoginTypeContext);
  const url = useRouteMatch<any>(`/curate/*`);
  const pageSlug = url?.params[0];
  //TODO done useEffect
  useEffect(() => {
    if (loginTypeContext) {
      let active = true;
      (async () => {
        try {
          const response: CuratedPageResponse = await api(
            API.GET_CURATED_PAGE({
              loginTypeContext,
              pageSlug,
            }),
          );
          if (active) {
            response
              ? setCuratedPage(response?.data.entries[0])
              : displayModal('You do not have permission to view this page');
          }
        } catch (error) {
          active && logger.error('Get Curated Page Data Error', error);
        } finally {
          active &&
            setLoaded(prevState => ({
              ...prevState,
              curatedPage: true,
            }));
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [displayModal, loginTypeContext, pageSlug, setLoaded]);

  useEffect(() => {
    let label = '';
    let action = '';
    if (loginTypeContext === LoginTypeEnum.CONSULTANT) {
      label = 'Curated content page views (Consultants)';
      action = EventsActions.CURATED_CONTENT_PAGE_CONSULTANTS;
    } else if (loginTypeContext === LoginTypeEnum.INDIVIDUAL) {
      label = 'Curated content page views (Individuals)';
      action = EventsActions.CURATED_CONTENT_PAGE_INDIVIDUALS;
    }
    TriggerGTMEvent({
      action: action,
      category: EventsCategories.PAGE_VIEWS,
      label: label,
    });
  }, [loginTypeContext]);

  return {
    curatedPage,
  };
};
