import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { LayoutVerticalPadding } from 'components/Layout/constants';
import { SubscribeWithPaymentMethodType } from '../types';
import * as FormControl from '../FormControl';
import * as Layout from 'components/Layout';
import CountryOfResidenceQuestions from '../Subscribe/CountryOfResidenceQuestions';
import Footer from '../Footer';
import PageHeading from '../PageHeading';
import PaymentQuestions from '../SignUpWithPayment/PaymentQuestions';
import PurchaseSummary from '../PurchaseSummary';
import StoredPaymentQuestions from './StoredPaymentQuestions';
import useSubscribeWithPaymentMethod, { SubscribeFormSteps } from './hooks';
import VoucherCode from '../VoucherCode';
import Skeleton from '../Skeleton';

import styles from 'pages/sign-up/components/styles.module.scss';

const SubscribeWithPaymentMethod: React.FunctionComponent<SubscribeWithPaymentMethodType> =
  ({
    countryCode,
    loading,
    pageInfo,
    paymentMethods,
    productInfo,
    requiresCountry,
    subscriptionPlan,
    subscriptionState,
    voucherCode,
  }) => {
    const isMobileBreakpoint = useMediaQuery({ query: '(max-width: 767px)' });
    const paymentMethodDefaultValue = useMemo(
      () =>
        paymentMethods.length > 0 ? paymentMethods[0].paymentMethodId : '',
      [paymentMethods],
    );
    const {
      activeStep,
      defaultValues,
      handleNext,
      handleBack,
      handleSubmit,
      handleSubmitWithBraintree,
      isSubmitting,
    } = useSubscribeWithPaymentMethod({
      requiresCountry,
      paymentMethodDefaultValue,
      subscriptionPlan,
      subscriptionState,
    });

    const formControl = useMemo(
      () =>
        requiresCountry ? FormControl.PaymentDetails : FormControl.Default,
      [requiresCountry],
    );

    return (
      <Layout.Container className={styles.signUpPage}>
        <Layout.Content
          className={styles.signUpContent}
          verticalPadding={LayoutVerticalPadding.LARGE}
        >
          {!loading ? (
            <React.Fragment>
              <PageHeading {...pageInfo} />
              {activeStep === SubscribeFormSteps.Country && (
                <CountryOfResidenceQuestions
                  countryCode={countryCode}
                  defaultValues={defaultValues}
                  handleSubmit={handleNext}
                  id="Residence"
                  isSubmitting={isSubmitting}
                  productInfo={productInfo}
                  voucherCode={voucherCode}
                />
              )}
              {activeStep === SubscribeFormSteps.PaymentMethods && (
                <StoredPaymentQuestions
                  defaultValues={defaultValues}
                  formControl={formControl}
                  handleAddCard={handleNext}
                  handleSubmit={handleSubmit}
                  id="Upgrade"
                  isSubmitting={isSubmitting}
                  paymentMethods={paymentMethods}
                  productInfo={productInfo}
                  voucherCode={voucherCode}
                />
              )}
              {activeStep === SubscribeFormSteps.NewCard && (
                <PaymentQuestions
                  defaultValues={defaultValues}
                  handleBack={handleBack}
                  handleSubmit={handleSubmitWithBraintree}
                  isSubmitting={isSubmitting}
                  productInfo={productInfo}
                  voucherCode={voucherCode}
                />
              )}
            </React.Fragment>
          ) : (
            <Skeleton />
          )}
          <Footer />
        </Layout.Content>
        {!isMobileBreakpoint && (
          <Layout.Sidebar
            className={styles.signUpSidebar}
            verticalPadding={LayoutVerticalPadding.LARGE}
          >
            <PurchaseSummary
              showBackLink={false}
              {...productInfo}
              loading={loading}
            >
              <VoucherCode {...voucherCode} isSubmitting={isSubmitting} />
            </PurchaseSummary>
          </Layout.Sidebar>
        )}
      </Layout.Container>
    );
  };

export default React.memo(SubscribeWithPaymentMethod);
