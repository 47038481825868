import React, { useEffect, useState } from 'react';

import { useMobileNavigation } from './useMobileNavigation';

type useNavigationProps = {
  isMobile: boolean;
  setToggleMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMobileMenu: boolean;
};

export const useNavigation = ({
  isMobile,
  setToggleMobileMenu,
  toggleMobileMenu,
}: useNavigationProps) => {
  const [menuAnimating, setMenuAnimating] = useState(false);

  useEffect(() => {
    const closeMobileMenuOnResize = () => {
      if (!isMobile && toggleMobileMenu) {
        setToggleMobileMenu(false);
        document.documentElement.classList.remove('lock-screen');
        document.documentElement.classList.remove('overlay');
      }
    };
    window.addEventListener('resize', closeMobileMenuOnResize);
    window.addEventListener('orientationchange', closeMobileMenuOnResize);
    return () => {
      window.removeEventListener('resize', closeMobileMenuOnResize);
      window.removeEventListener('orientationchange', closeMobileMenuOnResize);
    };
  }, [isMobile, setToggleMobileMenu, toggleMobileMenu]);

  useEffect(() => {
    if (toggleMobileMenu) {
      document.documentElement.classList.add('lock-screen');
    } else {
      document.documentElement.classList.remove('lock-screen');
    }
  }, [toggleMobileMenu]);

  const handleCloseMenu = () => {
    setMenuAnimating(true);
    setToggleMobileMenu(false);
    document.documentElement.classList.remove('overlay');
  };

  const handleMenuToggle = () => {
    setMenuAnimating(true);
    document.documentElement.classList.add('overlay');
    window.scrollTo(0, 0);
    if (!toggleMobileMenu) {
      setToggleMobileMenu(true);
    } else {
      handleCloseMenu();
    }
  };

  const { navRef } = useMobileNavigation(handleCloseMenu, setMenuAnimating);

  return {
    handleCloseMenu,
    handleMenuToggle,
    menuAnimating,
    navRef,
  } as const;
};
