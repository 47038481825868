import React from 'react';

import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading key templates
      </span>
      <div className={styles.skeleton} aria-hidden="true">
        <span></span>
        <ul>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
          <li className={styles.skeletonItem}>
            <span></span>
            <div>
              <p></p>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
