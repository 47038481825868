import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { CookieSetOptions } from './types';
import { PEOPLECERT_SPLIT_TEST_COOKIE_NAME } from 'modules/utils/constants';
import { PeopleCertSplitOptions } from '../components/types';

export const useSplitTest = () => {
  const [testOption, setTestOption] = useState<PeopleCertSplitOptions | null>(
    null,
  );
  const [cookieSplitTest, setCookieSplitTest] = useCookies<string>([
    PEOPLECERT_SPLIT_TEST_COOKIE_NAME,
  ]);

  useEffect(() => {
    const oneWeekInSeconds = 7 * 24 * 60 * 60;
    const cookieOptions: CookieSetOptions = {
      path: '/',
      maxAge: oneWeekInSeconds,
      sameSite: 'strict',
    };
    const savedOption = cookieSplitTest[PEOPLECERT_SPLIT_TEST_COOKIE_NAME];
    if (!savedOption) {
      const newValue = Math.floor(Math.random() * 2);
      setCookieSplitTest(
        PEOPLECERT_SPLIT_TEST_COOKIE_NAME,
        newValue,
        cookieOptions,
      );
      setTestOption(newValue);
      return;
    }
    setTestOption(Number(savedOption));
  }, [cookieSplitTest, setCookieSplitTest]);

  return { testOption } as const;
};
