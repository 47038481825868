import React from 'react';

import { QuestionProps } from '../Template/types';
import { ReactComponent as ChevronSVG } from 'modules/theme/icons/chevrons/chevron-right.svg';
import { useRedirectToSignUp } from './hooks';
import { ValdationMessages } from 'modules/validation';
import * as Button from 'components/Button';
import * as Input from 'components/Inputs/Input';
import * as Question from 'components/Question';

import styles from './styles.module.scss';

type QuestionTextProps<T> = QuestionProps<T> & {
  autoComplete?: string;
  readOnly?: boolean;
};

function Component<T>({
  autoComplete,
  control,
  disabled,
  help,
  isSubmitting,
  label,
  question,
  readOnly,
}: QuestionTextProps<T>) {
  const { setRedirectSignUpCookie } = useRedirectToSignUp();

  return (
    <Question.Template
      className={styles.emailUserExists}
      control={control}
      help={help}
      label={label}
      question={question}
    >
      {({ errorMessage, invalid, name, onBlur, onChange, ref, value }) => (
        <React.Fragment>
          <Input.String
            autoComplete={autoComplete}
            forwardedRef={ref}
            id={name}
            invalid={invalid}
            label={label}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            readOnly={isSubmitting || readOnly}
            tabIndex={disabled ? -1 : 0}
            value={value}
          />
          {errorMessage === ValdationMessages.USER_EXISTS && (
            <Button.Generic label="Sign in" onClick={setRedirectSignUpCookie}>
              <ChevronSVG />
            </Button.Generic>
          )}
        </React.Fragment>
      )}
    </Question.Template>
  );
}

export default React.memo(Component) as typeof Component;
