import React, { memo } from 'react';
import styles from '../../styles.module.scss';
import dayjs from 'dayjs';
import { ReactComponent as PrinceTwoLogo } from 'modules/theme/ims/logos/prince2-logo.svg';
import { ReactComponent as PrinceTwoAgileLogo } from 'modules/theme/ims/logos/prince2-agile-logo.svg';
import { ReactComponent as MspLogo } from 'modules/theme/ims/logos/msp-logo.svg';
import { ReactComponent as MorLogo } from 'modules/theme/ims/logos/mor-logo.svg';

type CertificateTypes = {
  level?: string;
  category?: string;
};

const Certificate: React.FunctionComponent<CertificateTypes> = ({
  level,
  category,
}) => {
  const currDate = dayjs().format('DD.MM.YYYY');
  const renderLogo = () => {
    switch (category?.toLowerCase()) {
      case 'prince2':
        return <PrinceTwoLogo />;
      case 'prince2 agile':
        return <PrinceTwoAgileLogo />;
      case 'msp':
        return <MspLogo />;
      case 'mor':
        return <MorLogo />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.certificate}>
      <div>
        {renderLogo()}
        <span className={styles.date}>{currDate}</span>
      </div>
      <div>
        <h3>{level}</h3>
      </div>
    </div>
  );
};

export default memo(Certificate);
