import { useState, useEffect, useRef, useCallback } from 'react';

import { API, api } from 'modules/api';
import { BadgesTypes } from '../types';
import { EventsActions, EventsCategories } from 'modules/gtm/constants';
import { TriggerGTMEvent } from 'modules/gtm';
import logger from 'modules/logger';

export const useClaimBadge = (
  cancelPolling?: () => void,
  runPolling?: (
    badges: BadgesTypes[],
    setBadges: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  ) => void,
  setBadges?: React.Dispatch<React.SetStateAction<BadgesTypes[] | undefined>>,
  badges?: BadgesTypes[],
  itemBadgeData?: BadgesTypes[],
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const active = useRef(true);
  const id = itemBadgeData ? itemBadgeData[0]?.assertionId : '';

  const handleClaimBadge = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const res = await api(API.POST_BADGES_CLAIM(id));
      if (active.current) {
        TriggerGTMEvent({
          action: EventsActions.CLICK_CTA_ON_DASHBOARD,
          category: EventsCategories.BADGING,
          label: '',
        });
        setIsSubmitting(false);
        sessionStorage.setItem(`Claiming:${id}`, id);
        window.open(res.data.acceptUrl, '_blank');
        if (badges && setBadges && cancelPolling && runPolling) {
          cancelPolling();
          runPolling(badges, setBadges);
        }
      }
    } catch (error: any) {
      logger.error('Error getting badge claim link', error);
    }
  }, [badges, cancelPolling, id, runPolling, setBadges]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return {
    handleClaimBadge,
    isSubmitting,
  };
};
