import { useCallback, useEffect, useRef } from 'react';
import sleep from 'sleep-promise';

import { LoginTypeEnum } from 'modules/utils/types';
import { ResourceHubLoadedType } from '../types';
import logger from 'modules/logger';

export const useGetSearchData = (
  setLoaded: React.Dispatch<React.SetStateAction<ResourceHubLoadedType>>,
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
  getInputProps: any,
  fetchSearchData: (
    loginTypeContext: LoginTypeEnum,
    value: string,
    resetNumberOfitemsShown: boolean,
  ) => void,
  loginTypeContext?: LoginTypeEnum,
) => {
  const active = useRef(true);
  //TODO done useCallback
  const getData = useCallback(
    async (resetNumberOfitemsShown: boolean) => {
      try {
        setLoaded(prevState => ({
          ...prevState,
          resourceHub: false,
        }));
        const { value }: any = getInputProps();
        setSearchTerm(value);
        const startTime = performance.now();
        await fetchSearchData(
          loginTypeContext!,
          value,
          resetNumberOfitemsShown,
        );
        if (active.current) {
          const endTime = performance.now();
          if (endTime - startTime < 400) {
            await sleep(250);
          }
        }
      } catch (error) {
        active.current && logger.debug('Get Data Error: ', error);
      } finally {
        active.current &&
          setLoaded(prevState => ({
            ...prevState,
            resourceHub: true,
          }));
      }
    },
    [
      fetchSearchData,
      getInputProps,
      loginTypeContext,
      setLoaded,
      setSearchTerm,
    ],
  );

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return { getData };
};
