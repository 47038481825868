import React from 'react';
import clsx from 'clsx';

import formStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const Skeleton: React.FunctionComponent = () => {
  const classNames = clsx(styles.skeleton, formStyles.addCandidate);
  return (
    <React.Fragment>
      <span data-nosnippet className="visuallyHidden">
        Loading add your candidate ID
      </span>
      <div className={classNames} aria-hidden="true">
        <p></p>
        <div className={formStyles.addCandidateForm}>
          <span></span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Skeleton;
