import React, { useCallback, useRef } from 'react';

import { api, API } from 'modules/api';
import { Model } from '../AddCardForm/model';
import { ModalType } from 'modules/modals/constants';
import { setPaymentMethodAdded } from './utils';
import { PaymentInfoType } from '../types';
import AddCardForm from '../AddCardForm';
import logger from 'modules/logger';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { useEffect } from 'react';

type UseHandleAddPaymentMethodProps = {
  modal: React.MutableRefObject<string | null>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentMethods: React.Dispatch<React.SetStateAction<PaymentInfoType[]>>;
};

function useAddPaymentMethod({
  modal,
  setLoading,
  setPaymentMethods,
}: UseHandleAddPaymentMethodProps) {
  const { modalShow, modalHide } = useModalAction();
  const active = useRef(true);

  const handleCancel = useCallback(() => {
    !!modal.current && modalHide(modal.current);
    modal.current = null;
  }, [modal, modalHide]);
  //TODO dont useCallback
  const handleSubmit = useCallback(
    async (data: Model & { nonce: string }) => {
      try {
        const response = await api(
          API.PUT_PAYMENT_METHOD(data.makeDefault, data.nonce),
        );
        if (active.current) {
          setPaymentMethodAdded(response.data.paymentMethod, setPaymentMethods);
          !!modal.current && modalHide(modal.current);
          modal.current = null;
          setLoading(true);
        }
      } catch (error) {
        active.current && logger.debug('Handle Submit Error: ', error);
      }
    },
    [modal, modalHide, setLoading, setPaymentMethods],
  );

  const handleAddPaymentMethod = useCallback(() => {
    if (!modal.current) {
      modal.current = modalShow({
        onCancel: handleCancel,
        renderContent: onCancel => (
          <AddCardForm onCancel={onCancel} onSubmit={handleSubmit} />
        ),
        preventDefaultClose: true,
        title: 'Add Credit / Debit card',
        type: ModalType.FORM,
      });
    }
  }, [handleCancel, handleSubmit, modal, modalShow]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return handleAddPaymentMethod;
}

export default useAddPaymentMethod;
