import React from 'react';

import PaymentHistoryYear from './PaymentHistoryYear';
import { PaymentHistoryYearItemProps } from './PaymentHistoryYear/PaymentHistoryYearItem';

import styles from './styles.module.scss';

type PaymentHistoryProps = {
  paymentHistoryList?: PaymentHistoryYearItemProps[];
  paymentYears?: number[];
};

const PaymentHistory: React.FunctionComponent<PaymentHistoryProps> = ({
  paymentHistoryList,
  paymentYears,
}) => {
  const noPaymentsMessage = `You'll be able to see your payment history here once your first payment has been processed`;

  return (
    <div className={styles.paymentHistory}>
      {paymentYears && paymentYears.length > 0 ? (
        paymentYears.map((paymentYear: number) => {
          const paymentHistoryListByYear = paymentHistoryList?.filter(
            (paymentHistoryItem: PaymentHistoryYearItemProps) =>
              new Date(
                paymentHistoryItem.paymentDate.split('T')[0],
              ).getFullYear() === paymentYear,
          );
          if (paymentHistoryListByYear) {
            return (
              <PaymentHistoryYear
                key={`payment-history-year-${paymentYear}`}
                closed={true}
                year={paymentYear.toString()}
                paymentHistoryList={paymentHistoryListByYear}
              />
            );
          }
          return null;
        })
      ) : (
        <span>{noPaymentsMessage}</span>
      )}
    </div>
  );
};

export default React.memo(PaymentHistory);
