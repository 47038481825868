import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ButtonColour, ButtonStyle } from 'components/Button/constants';
import useModalAction from 'modules/modals/hooks/useModalAction';
import { defaultValues, Model, model, schema } from './model';
import { getCardTitle } from 'modules/braintree/utils';
import { ModalType } from 'modules/modals/constants';
import BraintreeIcon from 'modules/braintree/icon';
import * as Question from 'components/Question';
import { countries } from 'modules/countries';
import { mergeDefaultValues } from './utils';
import Questions from 'components/Questions';
import * as Button from 'components/Button';
import { PaymentInfoType } from '../types';
import Fieldset from 'components/Fieldset';
import logger from 'modules/logger';

import styles from './styles.module.scss';

type EditCardFormProps = {
  onCancel: () => void;
  onSubmit: (data: any) => Promise<void>;
  paymentMethod: PaymentInfoType;
};

function EditCardForm({
  onCancel,
  onSubmit,
  paymentMethod,
}: EditCardFormProps) {
  const [isApiSubmitting, setIsApiSubmitting] = useState(false);
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const active = useRef(true);

  const mergedDefaultValues = useMemo(
    () => mergeDefaultValues(defaultValues, paymentMethod),
    [paymentMethod],
  );
  //TODO done useCallback
  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setIsApiSubmitting(true);
        await onSubmit(data);
      } catch (error) {
        if (active.current) {
          logger.error('Edit Payment Method Error', error);
          modal.current = modalShow({
            onClose: () => {
              !!modal.current && modalHide(modal.current);
              modal.current = null;
            },
            title: "Oh my... There's a problem!",
            text: 'There was an error trying to edit the payment method.',
            type: ModalType.INFO,
          });
        }
      } finally {
        active.current && setIsApiSubmitting(false);
      }
    },
    [onSubmit, modalHide, modalShow],
  );

  useEffect(() => {
    return () => {
      !!modal.current && modalHide(modal.current);
      modal.current = null;
    };
  }, [modalHide]);

  useEffect(() => {
    return () => {
      active.current = false;
    };
  }, []);

  return (
    <div className={styles.editCardForm}>
      <Questions<Model>
        defaultValues={mergedDefaultValues}
        onSubmit={handleSubmit}
        schema={schema}
      >
        {({ control, isFormSubmitting }) => (
          <React.Fragment>
            <Fieldset title="Card information">
              <div>
                <div>
                  <BraintreeIcon type={paymentMethod.paymentMethodType} />
                  <p>
                    {getCardTitle(paymentMethod.paymentMethodType)}{' '}
                    <strong>{paymentMethod.paymentMethodIdentifier}</strong>
                    {paymentMethod.paymentMethodDefault === 'True' && (
                      <span> - Primary</span>
                    )}
                  </p>
                </div>
              </div>
            </Fieldset>

            <Fieldset title="Billing address">
              <Question.Text
                control={control}
                isSubmitting={isApiSubmitting || isFormSubmitting}
                label="First name"
                question={model.billingFirstName}
              />

              <Question.Text
                control={control}
                isSubmitting={isApiSubmitting || isFormSubmitting}
                label="Last name"
                question={model.billingLastName}
              />

              <Question.Text
                control={control}
                isSubmitting={isApiSubmitting || isFormSubmitting}
                label="Address line one"
                question={model.billingStreetAddress}
              />

              <Question.Text
                control={control}
                isSubmitting={isApiSubmitting || isFormSubmitting}
                label="Address line two (optional)"
                question={model.billingExtendedAddress}
              />

              <Question.Text
                control={control}
                isSubmitting={isApiSubmitting || isFormSubmitting}
                label="City"
                question={model.billingLocality}
              />

              <Question.Text
                control={control}
                isSubmitting={isApiSubmitting || isFormSubmitting}
                label="Zip or Postcode"
                question={model.billingPostalCode}
              />

              <Question.Select
                control={control}
                isSubmitting={isApiSubmitting || isFormSubmitting}
                label="Country"
                options={countries}
                question={model.billingCountryCodeAlpha2}
              />

              {paymentMethod.paymentMethodDefault !== 'True' && (
                <Question.Checkbox
                  className={styles.checkbox}
                  control={control}
                  isSubmitting={isApiSubmitting || isFormSubmitting}
                  label="Set as primary payment method"
                  question={model.makeDefault}
                />
              )}
            </Fieldset>

            <div className={styles.buttons}>
              {(isApiSubmitting || isFormSubmitting) && (
                <div className={styles.spinner}></div>
              )}

              <Button.Generic
                colour={ButtonColour.BRAND_MONO_ONE}
                disabled={isApiSubmitting || isFormSubmitting}
                label="Cancel"
                onClick={onCancel}
                style={ButtonStyle.SECONDARY}
              />

              <Button.Submit
                colour={ButtonColour.BRAND_BLACK_GRAPE}
                disabled={isApiSubmitting || isFormSubmitting}
                label="Save"
                showSpinner={false}
                style={ButtonStyle.SECONDARY}
              />
            </div>
          </React.Fragment>
        )}
      </Questions>
    </div>
  );
}

export default React.memo(EditCardForm) as typeof EditCardForm;
