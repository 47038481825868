import { PaymentInfoType } from '../types';

export const setPaymentMethodDeleting = (
  deleting: boolean,
  id: string,
  setPaymentMethods: React.Dispatch<React.SetStateAction<PaymentInfoType[]>>,
) => {
  setPaymentMethods(paymentMethods =>
    paymentMethods.map(paymentMethod =>
      paymentMethod.paymentMethodId === id
        ? { ...paymentMethod, paymentMethodDeleting: deleting }
        : paymentMethod,
    ),
  );
};

export const setPaymentMethodDeleted = (
  id: string,
  setPaymentMethods: React.Dispatch<React.SetStateAction<PaymentInfoType[]>>,
) => {
  setPaymentMethods(paymentMethods =>
    paymentMethods.filter(
      paymentMethod => paymentMethod.paymentMethodId !== id,
    ),
  );
};

export const updatePaymentMethods = (
  data: PaymentInfoType[],
  setPaymentMethods: React.Dispatch<React.SetStateAction<PaymentInfoType[]>>,
) => {
  setPaymentMethods(paymentMethods => {
    return Array.isArray(data)
      ? data.map(paymentMethod => {
          return {
            ...paymentMethod,
            paymentMethodDeleting:
              paymentMethods.find(
                item => item.paymentMethodId === paymentMethod.paymentMethodId,
              )?.paymentMethodDeleting || false,
          };
        })
      : [];
  });
};

export const setPaymentMethodAdded = (
  data: PaymentInfoType,
  setPaymentMethods: React.Dispatch<React.SetStateAction<PaymentInfoType[]>>,
) => {
  setPaymentMethods(paymentMethods => {
    return [
      data,
      ...paymentMethods.map(paymentMethod => {
        return {
          ...paymentMethod,
          paymentMethodDefault:
            data.paymentMethodDefault === 'True'
              ? 'False'
              : paymentMethod.paymentMethodDefault,
        };
      }),
    ];
  });
};

export const setPaymentMethodEdited = (
  data: PaymentInfoType,
  setPaymentMethods: React.Dispatch<React.SetStateAction<PaymentInfoType[]>>,
) => {
  setPaymentMethods(paymentMethods => {
    return paymentMethods.map(paymentMethod => {
      return paymentMethod.paymentMethodId === data.paymentMethodId
        ? data
        : {
            ...paymentMethod,
            paymentMethodDefault:
              data.paymentMethodDefault === 'True'
                ? 'False'
                : paymentMethod.paymentMethodDefault,
          };
    });
  });
};
