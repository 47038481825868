import { useState } from 'react';

import { useBookmarks } from './hooks/useBookmarks';
import { Template } from './Template';

export const Bookmarks: React.FunctionComponent = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const { bookmarks, showMore } = useBookmarks(setLoaded);

  return <Template loaded={loaded} showMore={showMore} bookmarks={bookmarks} />;
};
